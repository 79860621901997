import { serializeError } from 'serialize-error'
import { captureException } from '@sentry/nextjs'

export interface ICustomError {
  name: string
  message: string
  statusCode: number
  headers?: { [key: string]: string }
  error: unknown
}

const DEFAULT_ERROR_NAME = 'UnknownError'
const DEFAULT_ERROR_MESSAGE = 'Unknown error'
const DEFAULT_STATUS_CODE = 500

// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
export class CustomError extends Error implements ICustomError {
  name = DEFAULT_ERROR_NAME
  message = DEFAULT_ERROR_MESSAGE
  statusCode = DEFAULT_STATUS_CODE
  headers?: { [key: string]: string }
  error: unknown

  constructor(
    name?: string,
    message?: string,
    statusCode?: number,
    headers?: { [key: string]: string },
    error?: unknown
  ) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain

    if (!!message || message === DEFAULT_ERROR_MESSAGE) {
      captureException(
        JSON.stringify(serializeError({ name, message, statusCode, headers, error }))
      )
    }

    if (name) this.name = name
    if (message) this.message = message
    if (statusCode) this.statusCode = statusCode
    if (headers) this.headers = headers
    this.error = error
  }
}
