import { type SendToVaultRequestBody, nfdSendToVault } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'
import type { SendModalContext } from 'components/SendModal/SendModal.types'

/**
 * Type that ensures either amount or amountStr is provided, but not both.
 */
export type StrictSendToVaultRequestBody =
  | (Omit<SendToVaultRequestBody, 'amount' | 'amountStr'> & { amount: number; amountStr?: never })
  | (Omit<SendToVaultRequestBody, 'amount' | 'amountStr'> & { amount?: never; amountStr: string })

export type SendToVaultParams = {
  name: string
  body: StrictSendToVaultRequestBody
}

type SendToVaultOptions = MutationOptions<SendToVaultParams, SendModalContext>

export function usePostSendToVault(options: SendToVaultOptions = {}) {
  return usePostTransaction<SendToVaultParams, SendModalContext>({
    mutationFn: ({ name, body }) => nfdSendToVault(name, body),
    ...options
  })
}
