import axios from 'axios'
import { TelegramData } from 'pages/api/telegram/[id]'

export default async function fetchTelegramName(id: string | undefined): Promise<string> {
  if (!id) {
    return ''
  }

  const { data } = (await axios.get('/api/telegram/' + id)) as { data: TelegramData }

  if (data.result?.username) {
    return data.result?.username
  } else if (data.result?.first_name && data.result.last_name) {
    return `${data.result?.first_name} ${data.result.last_name}`
  } else if (data.result?.first_name) {
    return data.result?.first_name
  } else if (data.result?.last_name) {
    return data.result?.last_name
  }

  return id
}
