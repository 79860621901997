import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { cleanupOptimisticUpdates, matchQuery } from './utils'
import type { AnyRecord } from 'types/utility'
import type {
  AddOptimisticUpdateParams,
  GetOptimisticUpdatesParams,
  OptimisticUpdate,
  OptimisticUpdateStore
} from './types'

export const useOptimisticUpdateStore = create<OptimisticUpdateStore>()(
  immer(
    devtools((set, get) => ({
      queries: [],

      getOptimisticUpdates: (options?: GetOptimisticUpdatesParams) => {
        set((state) => {
          cleanupOptimisticUpdates(state)
        })

        const { queryKey, exact = false, predicate } = options || {}

        if (predicate) {
          return get()
            .queries.filter((query) => predicate(query))
            .flatMap((query) => query.optimisticUpdates)
        }

        if (queryKey) {
          const matchedQueries = get().queries.filter((query) =>
            matchQuery(query.queryKey, queryKey, exact)
          )

          return matchedQueries.flatMap((query) => query.optimisticUpdates)
        }

        return []
      },

      addOptimisticUpdate: <TRecord extends AnyRecord>({
        queryKey,
        operation,
        record,
        uniqueKey,
        pageIndex
      }: AddOptimisticUpdateParams<TRecord>) => {
        const timeCreated = new Date().toISOString()

        set((state) => {
          cleanupOptimisticUpdates(state)

          const queryIndex = state.queries.findIndex((query) =>
            matchQuery(query.queryKey, queryKey, true)
          )

          const optimisticUpdate: OptimisticUpdate<TRecord> = {
            timeCreated,
            operation,
            record,
            uniqueKey,
            pageIndex
          }

          if (queryIndex === -1) {
            state.queries.push({
              queryKey: Array.from(queryKey),
              optimisticUpdates: [optimisticUpdate]
            })
          } else {
            state.queries[queryIndex].optimisticUpdates.push(optimisticUpdate)
          }
        })
      }
    }))
  )
)
