import { useEffect, useState } from 'react'
import { immer } from 'zustand/middleware/immer'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export type AssetsViewStore = {
  view: 'grid' | 'list'
  setView: (view: 'grid' | 'list') => void
}

const emptyState: AssetsViewStore = {
  view: 'grid',
  setView: () => {
    return
  }
}

const usePersistedStore = create<AssetsViewStore>()(
  immer(
    persist(
      devtools((set) => ({
        view: 'grid',
        setView: (view: 'grid' | 'list') => {
          set((state) => {
            state.view = view
          })
        }
      })),
      {
        name: 'nfd-assets-view',
        version: 0
      }
    )
  )
)

// @see https://github.com/pmndrs/zustand/issues/1145#issuecomment-1207233036
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAssetsViewStore = ((selector: any, compare: any) => {
  const store = usePersistedStore(selector, compare)
  const [hydrated, setHydrated] = useState(false)
  useEffect(() => setHydrated(true), [])

  return hydrated ? store : selector(emptyState)
}) as typeof usePersistedStore
