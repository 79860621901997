import { useQuery } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import dayjs from 'dayjs'
import Link from 'next/link'
import * as React from 'react'
import { HiXMark } from 'react-icons/hi2'
import { nfdSearchV2, NfdSearchV2Params } from 'api/api-client'

const fetchExpiringNfds = async (params: NfdSearchV2Params) => {
  const { data } = await nfdSearchV2(params)
  return data.nfds
}

const LOCAL_STORAGE_PREFIX = 'nfd-expiration-banner/'

export function ExpirationBanner() {
  const { activeAddress } = useWallet()
  const [isDismissed, setIsDismissed] = React.useState(false)

  const storageKey = `${LOCAL_STORAGE_PREFIX}${activeAddress}`

  const expireQuery = useQuery({
    queryKey: ['expiration-banner', activeAddress],
    queryFn: () =>
      fetchExpiringNfds({
        owner: activeAddress ?? undefined,
        view: 'brief',
        sort: 'expiresAsc',
        expiresBefore: dayjs().add(30, 'days').toISOString(),
        limit: 1
      }),
    enabled: activeAddress !== undefined,
    staleTime: Infinity
  })

  const hasExpirations = (expireQuery.data?.length ?? 0) > 0
  const isAlreadyExpired = expireQuery.data?.some((nfd) => dayjs().isAfter(dayjs(nfd.timeExpires)))

  React.useEffect(() => {
    if (activeAddress) {
      const storedValue = localStorage.getItem(storageKey)
      setIsDismissed(storedValue === 'true')
    } else {
      setIsDismissed(false)
    }
  }, [activeAddress, storageKey])

  const handleDismiss = React.useCallback(() => {
    setIsDismissed(true)
    localStorage.setItem(storageKey, 'true')
  }, [storageKey])

  if (!activeAddress || !hasExpirations || isDismissed) {
    return null
  }

  return (
    <div className="flex items-center gap-x-6 bg-gray-900/5 dark:bg-white/5 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900 dark:text-white">
          <strong className="font-semibold">Expiration Warning</strong>
          <svg
            viewBox="0 0 2 2"
            aria-hidden="true"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
          >
            <circle r={1} cx={1} cy={1} />
          </svg>
          {isAlreadyExpired
            ? 'NFD(s) you own have expired! Renew now to prevent others from claiming them.'
            : 'NFD(s) you own are expiring soon and need to be renewed.'}
        </p>
        <Link
          href="/manage?view=expirations"
          className="flex-none rounded-full bg-brand-500 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-brand-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
        >
          View Expirations <span aria-hidden="true">&rarr;</span>
        </Link>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={handleDismiss}
        >
          <span className="sr-only">Dismiss</span>
          <HiXMark aria-hidden="true" className="h-5 w-5 text-gray-900 dark:text-white" />
        </button>
      </div>
    </div>
  )
}
