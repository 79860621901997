import type { NfdRecord } from 'api/api-client'
import { meetsMinimumVersion } from './versions'

export const isVaultLocked = (nfd: NfdRecord): boolean => {
  if (!meetsMinimumVersion(nfd, '2.11')) {
    return isVaultLockedLegacy(nfd)
  }
  return nfd.properties?.internal?.vaultOptInLocked === '1'
}

export const isVaultUnlocked = (nfd: NfdRecord): boolean => {
  if (!meetsMinimumVersion(nfd, '2.11')) {
    return isVaultUnlockedLegacy(nfd)
  }
  return nfd.properties?.internal?.vaultOptInLocked !== '1'
}

// Legacy functions (for versions < 2.11)
export const isVaultLockedLegacy = (nfd: NfdRecord): boolean => {
  return nfd.properties?.internal?.vaultOptInLocked !== '0'
}

export const isVaultUnlockedLegacy = (nfd: NfdRecord): boolean => {
  return nfd.properties?.internal?.vaultOptInLocked === '0'
}
