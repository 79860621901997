import { BiCopyAlt } from 'react-icons/bi'
import { copyToClipboard } from 'helpers/copyToClipboard'
import { truncateAddress } from 'helpers/utilities'

interface AddressProps {
  address: string
}

export default function Address({ address }: AddressProps) {
  const addr = truncateAddress(address, { array: true })

  const renderAddress = () => {
    return (
      <span className="font-mono text-left whitespace-nowrap block truncate">
        {addr[0]}&hellip;{addr[1]}
      </span>
    )
  }

  const renderCopyAddress = () => {
    return (
      <span className="text-gray-400 flex-shrink-0 ml-2 dark:text-gray-600">
        <BiCopyAlt className="w-5 h-5" />
      </span>
    )
  }

  return (
    <button
      className="flex items-center w-full flex-wrap text-gray-700 dark:text-gray-400"
      data-clipboard-text={address}
      onClick={copyToClipboard}
    >
      {renderAddress()}
      {renderCopyAddress()}
    </button>
  )
}
