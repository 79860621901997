import AlgoSymbol from './AlgoSymbol'
import { formatMicroAlgoAmount } from 'helpers/format'
import { classNames, formatPrice } from 'helpers/utilities'

interface AlgoPriceProps {
  price: number | undefined
  options?: Intl.NumberFormatOptions | undefined
  className?: string
  symbolClassName?: string
  exactTrim?: boolean
}

export default function AlgoPrice({
  price = 0,
  options,
  className = '',
  symbolClassName = '',
  exactTrim = false
}: AlgoPriceProps) {
  const displayPrice = (price: number) => {
    if (exactTrim) {
      return <span>{formatMicroAlgoAmount(price)}</span>
    }

    return <span>{formatPrice(price, false, options)}</span>
  }

  return (
    <span className={classNames('whitespace-nowrap', className)}>
      <AlgoSymbol className={symbolClassName} /> {displayPrice(price)}
    </span>
  )
}
