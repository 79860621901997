import Image from 'next/image'

export default function TokenImage({ alt = '' }) {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-gray-500/5 dark:bg-gray-500/5">
      <Image src="/img/token-light.png" alt={alt} fill className="dark:hidden" />
      <Image src="/img/token-dark.png" alt={alt} fill className="hidden dark:block" />
    </div>
  )
}
