import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export type NocacheStore = {
  nocache: string[]
  isNocache: (name: string) => boolean
  setNocache: (nocache: string[]) => void
  addName: (name: string) => void
  removeName: (name: string) => void
  clear: () => void
}

export const useNocacheStore = create<NocacheStore>()(
  immer(
    devtools((set, get) => ({
      nocache: [],

      isNocache: (name: string) => get().nocache.includes(name),

      setNocache: (names: string[]) => {
        set((state) => {
          state.nocache = names
        })
      },

      addName: (name: string) => {
        set((state) => {
          if (state.nocache.includes(name)) {
            return
          }
          state.nocache.push(name)
        })
      },

      removeName: (name: string) => {
        set((state) => {
          const index = state.nocache.findIndex((n) => n === name)
          if (index !== -1) state.nocache.splice(index, 1)
        })
      },

      clear: () => {
        set((state) => {
          state.nocache = []
        })
      }
    }))
  )
)
