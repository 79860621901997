import { DetailedHTMLProps, InputHTMLAttributes, useState } from 'react'
import { classNames } from 'helpers/utilities'

interface CheckboxProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string
  label?: string | React.ReactNode
  labelOn?: 'left' | 'right'
  checked: boolean
  disabled?: boolean
  className?: string
  labelClassName?: string
}

export default function Checkbox({
  id,
  label,
  labelOn = 'right',
  checked,
  disabled = false,
  className = 'flex items-center',
  labelClassName = 'px-3',
  ...props
}: CheckboxProps) {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className={classNames('relative', className)}>
      {label && labelOn === 'left' && (
        <label
          className={classNames(
            disabled ? 'opacity-25 cursor-not-allowed' : 'cursor-pointer',
            labelClassName
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <input
        id={id}
        type="checkbox"
        className="absolute left-[-9999px] peer"
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <label
        className={classNames(
          'border border-gray-300 transition-colors ease-in rounded overflow-hidden relative w-5 h-5 inline-block text-center cursor-pointer text-gray-500 peer-checked:border-brand-500 peer-focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(255,96,46,1)] peer-disabled:opacity-50 peer-disabled:cursor-not-allowed dark:border-gray-600 dark:peer-checked:border-brand-500 dark:peer-focus:shadow-[0_0_0_2px_rgba(24,33,48,1),0_0_0_4px_rgba(255,96,46,1)]',
          isActive
            ? 'shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(255,96,46,1)] dark:shadow-[0_0_0_2px_rgba(24,33,48,1),0_0_0_4px_rgba(255,96,46,1)]'
            : ''
        )}
        htmlFor={id}
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        onBlur={() => setIsActive(false)}
      >
        <span className="flex items-center justify-center">
          <div
            className={classNames(
              checked ? 'transition-transform ease-[cubic-bezier(.4,0,.3,1)] scale-100' : 'scale-0',
              'bg-brand-500 absolute inset-0'
            )}
          />
          <div
            className={classNames(
              checked
                ? 'transition-transform delay-150 ease-[cubic-bezier(.4,0,.3,1)] scale-100'
                : 'scale-0',
              'absolute inset-0 flex items-center justify-center'
            )}
          >
            <i
              className="h-[18px] w-[18px] inline-block my-0 mx-auto align-middle bg-contain"
              aria-hidden="true"
            >
              <svg
                id="check-chunky_svg__eds-icon--check-chunky_svg"
                x="0"
                y="0"
                viewBox="0 0 24 24"
                xmlSpace="preserve"
              >
                <path
                  id="check-chunky_svg__eds-icon--check_base"
                  d="M5.5 12.2L9.4 16l9.1-9"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
              </svg>
            </i>
          </div>
          <div className="bg-white opacity-[.01]" />
        </span>
      </label>

      {label && labelOn === 'right' && (
        <label
          className={classNames(
            disabled ? 'opacity-25 cursor-not-allowed' : 'cursor-pointer',
            labelClassName
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  )
}
