import type { CBPayInstanceType } from '@coinbase/cbpay-js'
import type { DestinationWallet } from './types'
import { APP_ID } from './constants'

type generateOnRampURLArgs = {
  destinationWallets: DestinationWallet[]
}

export const generateOnRampURL = async ({ destinationWallets }: generateOnRampURLArgs) => {
  const generateOnRampURL = (await import('@coinbase/cbpay-js')).generateOnRampURL

  return generateOnRampURL({
    appId: APP_ID,
    destinationWallets
  })
}

type InitCoinbasePayArgs = {
  destinationWallets: DestinationWallet[]
  onSuccess?: () => void
  onExit?: (error?: Error) => void
}

export const initCoinbasePay = async ({
  destinationWallets,
  onSuccess,
  onExit
}: InitCoinbasePayArgs): Promise<CBPayInstanceType> => {
  const initOnRamp = (await import('@coinbase/cbpay-js')).initOnRamp

  return new Promise(function (resolve, reject) {
    initOnRamp(
      {
        appId: APP_ID,
        widgetParameters: {
          destinationWallets
        },
        experienceLoggedIn: 'embedded',
        experienceLoggedOut: 'popup',
        closeOnExit: true,
        closeOnSuccess: true,
        onSuccess,
        onExit
      },
      (error, instance) => {
        if (error) {
          reject(error)
        }

        resolve(instance)
      }
    )
  })
}
