import { ALGORAND_NETWORK } from 'src/data/constants'
import { EXPLORER_DATA, Explorer, ExplorerConfig, ExplorerPaths } from 'src/data/explorers'

enum AlgorandNetwork {
  BetaNet = 'betanet',
  TestNet = 'testnet',
  MainNet = 'mainnet'
}

export class ExplorerLink {
  private baseUrl: string
  private paths: ExplorerPaths

  constructor(explorer: Explorer) {
    if (!EXPLORER_DATA[explorer]) {
      throw new Error(`Invalid explorer: ${explorer}`)
    }

    const config = EXPLORER_DATA[explorer]
    this.baseUrl = this.getBaseUrl(config)
    this.paths = config.paths
  }

  private getBaseUrl(config: ExplorerConfig): string {
    switch (ALGORAND_NETWORK as AlgorandNetwork) {
      case AlgorandNetwork.BetaNet:
        return config.baseUrls.betanet || config.baseUrls.mainnet
      case AlgorandNetwork.TestNet:
        return config.baseUrls.testnet || config.baseUrls.mainnet
      default:
        return config.baseUrls.mainnet
    }
  }

  account(address: string): string {
    return `${this.baseUrl}${this.paths.accountUrl}/${address}`
  }

  tx(id: string): string {
    return `${this.baseUrl}${this.paths.transactionUrl}/${id}`
  }

  group(id: string): string {
    return `${this.baseUrl}${this.paths.groupUrl}/${encodeURIComponent(id)}`
  }

  asset(id: string | number): string {
    return `${this.baseUrl}${this.paths.assetUrl}/${id}`
  }

  app(id: string | number): string {
    return `${this.baseUrl}${this.paths.appUrl}/${id}`
  }

  block(id: string | number): string {
    return `${this.baseUrl}${this.paths.blockUrl}/${id}`
  }
}
