import copy from 'copy-to-clipboard'
import toast from 'react-hot-toast'
import { HiOutlineClipboardCheck } from 'react-icons/hi'
import Toast from 'components/Toast'

export const copyToClipboard = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault()
  const text = event.currentTarget.getAttribute('data-clipboard-text') as string

  const showSuccessToast = (text: string) => {
    const toastContent = () => (
      <>
        <p className="text-sm font-medium text-gray-900 dark:text-gray-100">Copied to Clipboard</p>
        <p className="mt-1 text-sm text-gray-500 truncate dark:text-gray-400">{text}</p>
      </>
    )

    toast.custom((t) => <Toast t={t} content={toastContent()} icon={HiOutlineClipboardCheck} />, {
      id: 'clipboard'
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showErrorToast = (error: any) => {
    toast.error(error.message, {
      id: 'clipboard'
    })
  }

  if (navigator.clipboard) {
    // Use Clipboard API if available
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSuccessToast(text)
      })
      .catch((error) => {
        showErrorToast(error)
      })
  } else {
    // Fallback method if Clipboard API is not available
    try {
      copy(text)
      showSuccessToast(text)
    } catch (error) {
      showErrorToast(error)
    }
  }
}
