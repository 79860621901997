import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  nfdGetLookup,
  NfdGetLookup200,
  NfdGetLookupParams,
  NfdGetLookupView,
  NfdRecordCollection
} from 'src/api/api-client'
import { captureException } from '@sentry/nextjs'
import { chunkArray } from 'helpers/utilities'

export const getByAddresses = async (
  params: NfdGetLookupParams,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  // split up the addresses into chunks of 20 max
  const chunked = chunkArray(params.address)

  // make the requests in parallel
  const responses = await Promise.all(
    chunked.map((chunk) => {
      return nfdGetLookup(
        {
          ...params,
          address: chunk
        },
        {
          ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
          ...options
        }
      )
    })
  )

  // merge the responses
  const mergedResponse = responses.reduce(
    (acc: NfdGetLookup200, response: AxiosResponse<NfdGetLookup200, AxiosError>) => {
      return {
        ...acc,
        ...response.data
      }
    },
    {} as NfdGetLookup200
  )

  const results = Object.values(mergedResponse)
    .map((nfd) => {
      const verifiedMatch = nfd.caAlgo?.[0]

      if (verifiedMatch) {
        // return first verified match for this address
        return nfd
      }

      const unverifiedMatch = nfd.unverifiedCaAlgo?.[0]

      if (unverifiedMatch) {
        // return first unverified match for this address
        return nfd
      }

      // no match for this address
      return null
    })
    .filter(Boolean) as NfdRecordCollection

  return results
}

export const fetchReverseLookup = async (
  address: string | string[],
  view?: NfdGetLookupView,
  allowUnverified?: boolean
) => {
  address = Array.isArray(address) ? address : [address]
  return getByAddresses({ address, view, allowUnverified }, false, {
    validateStatus: (status: number) => (status >= 200 && status < 300) || status === 404
  })
}

type UseAddressesArgs = {
  params: NfdGetLookupParams
  options?: UseQueryOptions<NfdRecordCollection, AxiosError>
}

export default function useGetLookup({ params, options }: UseAddressesArgs) {
  const result = useQuery<NfdRecordCollection, AxiosError>(
    ['nfd-lookup', { ...params }],
    () =>
      getByAddresses(params, false, {
        validateStatus: (status: number) => (status >= 200 && status < 300) || status === 404
      }),
    {
      ...options
    }
  )

  if (result.error) {
    captureException(result.error)
  }

  return result
}
