import { AccountAsset, AlgoAccountAsset, AsaAccountAsset } from 'types/node'
export const isAlgoAsset = (asset: AccountAsset | undefined): asset is AlgoAccountAsset => {
  return asset?.id === 0
}

export const isAsaAsset = (asset: AccountAsset | undefined): asset is AsaAccountAsset => {
  return !!asset?.id && asset.id !== 0
}

export const isAssetOneOfOne = (asset: AccountAsset | undefined) => {
  return isAsaAsset(asset) && asset?.total === 1
}
