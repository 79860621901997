import { useWallet } from '@txnlab/use-wallet-react'
import { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { use100vh } from 'react-div-100vh'
import { usePopper } from 'react-popper'
import useGetLookup from 'api/hooks/useGetLookup'
import useWalletBalance from 'hooks/useWalletBalance'
import { topBarHeight } from 'src/data/layout'

export default function useConnectWallet() {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [scrollHeight, setScrollHeight] = useState(0)
  const [showTopShadow, setShowTopShadow] = useState(false)
  const [showBottomShadow, setShowBottomShadow] = useState(false)
  const [isScrollable, setIsScrollable] = useState(false)
  const [isSendModalOpen, setIsSendModalOpen] = useState(false)

  const screenHeight = use100vh()

  const menuMaxHeight = screenHeight
    ? `${screenHeight - topBarHeight}px`
    : `calc(100vh - ${topBarHeight}px)`

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 11]
          }
        }
      ]
    }
  )

  const ulRef = useRef<HTMLUListElement>(null)
  const isOpen = useMemo(() => Boolean(popperElement), [popperElement])

  const handleResize = useCallback(() => {
    if (ulRef.current && isOpen) {
      const scroll = ulRef.current
      const scrollHeight = scroll.scrollHeight
      setScrollHeight(scrollHeight)
      const position = ulRef.current.scrollTop
      const visibleHeight = ulRef.current.getBoundingClientRect().height

      const menuHeight = popperElement?.getBoundingClientRect().height || 0
      const maxHeight = Number(screenHeight) - topBarHeight

      setIsScrollable(menuHeight === maxHeight)

      setShowBottomShadow(position < scrollHeight - Math.round(visibleHeight))
    }
  }, [isOpen, popperElement, screenHeight])

  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const position = Math.round(e.currentTarget.scrollTop)
    const visibleHeight = e.currentTarget.getBoundingClientRect().height

    setShowTopShadow(position > 0)
    setShowBottomShadow(position < scrollHeight - Math.round(visibleHeight))
  }

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', isOpen)
    document.body.classList.toggle('sm:overflow-auto', isOpen)
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  const { wallets, activeAccount, activeWalletAddresses } = useWallet()
  const activeAddress = activeAccount?.address

  const { data } = useGetLookup({
    params: {
      address: [activeAddress as string],
      view: 'brief'
    },
    options: {
      enabled: !!activeAddress
    }
  })

  const connectedAddresses =
    activeWalletAddresses?.filter((address) => address !== activeAddress) || []

  const primaryNfd = data?.[0]

  const senders = [
    ...(activeAddress ? [activeAddress] : []),
    ...connectedAddresses,
    ...(primaryNfd?.nfdAccount ? [primaryNfd] : [])
  ]

  const isConnected = !!activeAddress
  const hasActiveAccount = useMemo(() => activeAccount !== null, [activeAccount])

  const { walletBalance, walletAvailableBalance } = useWalletBalance()

  return {
    wallets,
    activeAccount,
    walletBalance,
    walletAvailableBalance,
    popperStyles,
    popperAttributes,
    setReferenceElement,
    setPopperElement,
    handleScroll,
    hasActiveAccount,
    isOpen,
    ulRef,
    showTopShadow,
    showBottomShadow,
    menuMaxHeight,
    isConnected,
    isScrollable,
    isSendModalOpen,
    setIsSendModalOpen,
    senders
  }
}
