import { classNames } from 'helpers/utilities'
import {
  HiCheckCircle,
  HiInformationCircle,
  HiExclamation,
  HiXCircle,
  HiOutlineCheckCircle,
  HiOutlineInformationCircle,
  HiOutlineExclamation,
  HiOutlineXCircle
} from 'react-icons/hi'
import type { IconType } from 'react-icons'

type AlertTheme = {
  bgColor: string
  icon: IconType
  iconColor: string
  darkIcon: IconType
  darkIconColor: string
  accentColor: string
}

type AlertType = 'success' | 'error' | 'warning' | 'info'

type AlertMap = {
  [key in AlertType]: AlertTheme
}

const alerts: AlertMap = {
  success: {
    bgColor: 'bg-green-50',
    icon: HiCheckCircle,
    iconColor: 'text-green-400',
    darkIcon: HiOutlineCheckCircle,
    darkIconColor: 'text-green-500',
    accentColor: 'border-green-500'
  },
  info: {
    bgColor: 'bg-blue-50',
    icon: HiInformationCircle,
    iconColor: 'text-blue-400',
    darkIcon: HiOutlineInformationCircle,
    darkIconColor: 'text-blue-500',
    accentColor: 'border-blue-500'
  },
  warning: {
    bgColor: 'bg-amber-50',
    icon: HiExclamation,
    iconColor: 'text-amber-400',
    darkIcon: HiOutlineExclamation,
    darkIconColor: 'text-amber-500',
    accentColor: 'border-amber-500'
  },
  error: {
    bgColor: 'bg-red-50',
    icon: HiXCircle,
    iconColor: 'text-red-400',
    darkIcon: HiOutlineXCircle,
    darkIconColor: 'text-red-500',
    accentColor: 'border-red-500'
  }
}

type AlertProps = {
  type?: AlertType | 'custom'
  theme?: AlertTheme
  title?: string
  message?: string | React.ReactNode
  error?: Error
  alignIcon?: 'top' | 'center'
  showIcon?: boolean
  className?: string
  children?: JSX.Element[] | JSX.Element
}

export default function Alert(props: AlertProps) {
  const {
    type = 'info',
    theme,
    title,
    message,
    error,
    alignIcon,
    showIcon = true,
    className = '',
    children,
    ...rest
  } = props
  const alertTheme = type === 'custom' ? theme || alerts.info : alerts[type]

  const { bgColor, iconColor, darkIconColor, accentColor } = alertTheme
  const Icon = alertTheme.icon
  const DarkIcon = alertTheme.darkIcon

  const iconAlignment = !!alignIcon ? alignIcon : !!title && !!message ? 'top' : 'center'
  const iconTopShift = iconAlignment === 'top' ? '-translate-y-0.5' : ''

  const renderContent = () => {
    if (children) {
      return children
    }

    return (
      <>
        {title && <h3 className="text-sm font-medium text-gray-900 dark:text-gray-300">{title}</h3>}
        {message && (
          <p className="mt-2 text-sm text-gray-700 first-letter:capitalize dark:text-gray-400">
            {message}
          </p>
        )}

        {error && (
          <div className="overflow-x-auto">
            <p className="mt-2 text-sm text-gray-700 first-letter:capitalize dark:text-gray-400">
              {error.message}
            </p>
            {/* <pre className="mt-2 text-sm text-gray-700">{error.stack}</pre> */}
          </div>
        )}
      </>
    )
  }

  return (
    <div className={classNames('rounded-md overflow-hidden max-w-xl', className)} {...rest}>
      <div
        className={classNames(
          'p-4 border-l-4 dark:bg-gray-300/5 dark:highlight',
          bgColor,
          accentColor
        )}
      >
        <div
          className={classNames(
            'flex',
            iconAlignment === 'top' ? 'items-start' : 'items-center',
            showIcon ? '' : ''
          )}
        >
          {showIcon && (
            <div className="flex-shrink-0">
              <Icon
                className={classNames('inline-flex h-5 w-5 dark:hidden', iconColor, iconTopShift)}
                aria-hidden="true"
              />
              <DarkIcon
                className={classNames(
                  'hidden h-5 w-5 dark:inline-flex',
                  darkIconColor,
                  iconTopShift
                )}
                aria-hidden="true"
              />
            </div>
          )}
          <div className="ml-3">{renderContent()}</div>
        </div>
      </div>
    </div>
  )
}
