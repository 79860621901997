import { captureException } from '@sentry/nextjs'
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { nfdGetNFD, NfdGetNFDParams } from 'src/api/api-client'
import { useOptimisticUpdateStore } from 'store/index'
import type { AxiosRequestConfig } from 'axios'
import type { ICustomError } from 'api/customError'
import type { NfdRecord } from 'types/api'
import type { OperationType } from 'store/state/optimisticUpdates/types'

export const getByName = async (
  name: string,
  params?: NfdGetNFDParams,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const { data } = await nfdGetNFD(name, params, {
    ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
    ...options
  })

  return data
}

type UseNameArgs = {
  name: string
  params?: NfdGetNFDParams
  options?: UseQueryOptions<NfdRecord | void, ICustomError>
  optimisticUpdate?: boolean
}

export default function useName({ name, params, options, optimisticUpdate = true }: UseNameArgs) {
  const getOptimisticUpdates = useOptimisticUpdateStore((state) => state.getOptimisticUpdates)

  const result = useQuery<NfdRecord | void, ICustomError>(
    ['name', name],
    () => getByName(name, params),
    {
      select: (data) => {
        if (!data || !optimisticUpdate) return data

        const [update] = getOptimisticUpdates({ queryKey: ['name', name] })

        if (!update) return data

        return {
          ...data,
          ...update.record
        }
      },
      ...options
    }
  )

  if (result.error) {
    captureException(result.error)
  }

  return result
}

export function useNameUpdate() {
  const addOptimisticUpdate = useOptimisticUpdateStore((state) => state.addOptimisticUpdate)
  const queryClient = useQueryClient()

  const optimisticUpdate = (record: NfdRecord | undefined, operation: OperationType = 'update') => {
    if (!record?.name) return

    addOptimisticUpdate<NfdRecord>({
      queryKey: ['name', record.name],
      record,
      uniqueKey: 'name',
      operation
    })

    queryClient.setQueryData(['name', record.name], record)
  }

  return optimisticUpdate
}
