import Link from 'next/link'
import { BiWallet } from 'react-icons/bi'
import { HiArrowUpRight } from 'react-icons/hi2'
import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import { useAdvancedSearchStore } from 'store/state/advancedSearch'

interface AddressCardProps {
  address: string
  activeAddress: string | undefined
  onSendClick: (receiver: string) => void
  onViewClick: () => void
}

export default function AddressCard({
  address,
  activeAddress,
  onSendClick,
  onViewClick
}: AddressCardProps) {
  const addToRecent = useAdvancedSearchStore((state) => state.addToRecent)

  const handleSendClick = () => {
    onSendClick(address)
    addToRecent(address)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      onViewClick()
      addToRecent(address)
    }
  }

  const handleAnchorClick = () => {
    onViewClick()
    addToRecent(address)
  }

  return (
    <div className="absolute inset-0 bg-white md:h-96 md:static md:w-1/2 flex flex-col dark:bg-gray-900">
      <div className="flex-1 flex flex-col divide-y divide-gray-100 overflow-y-auto dark:divide-gray-800">
        <div className="flex-none px-6 py-4 text-center">
          <div className="flex justify-center">
            <BiWallet className="h-12 w-12 text-gray-400" />
          </div>
          <p className="truncate p-1 mt-1">
            <Link
              href={`/address/${address}`}
              className="p-1 rounded-md font-mono text-gray-900 outline-brand-500 hover:text-brand-500 dark:text-gray-100 dark:hover:text-brand-500"
              onKeyDown={handleKeyDown}
              onClick={handleAnchorClick}
            >
              <span>{address}</span>
            </Link>
          </p>
        </div>
        <div className="flex flex-auto flex-col justify-between p-6">
          <div />
          <div className="grid grid-cols-2 gap-3">
            <ButtonLink
              href={`/address/${address}`}
              onKeyDown={handleKeyDown}
              onClick={handleAnchorClick}
            >
              View account
            </ButtonLink>
            <Button
              variant="primary"
              icon={HiArrowUpRight}
              onClick={handleSendClick}
              disabled={!activeAddress}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
