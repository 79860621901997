export const DEFAULT_IMAGE = '/img/nfd-image-placeholder.jpg'

export const CONFIGURED_HOSTS = [
  'app.betanet.nf.domains',
  'app.testnet.nf.domains',
  'app.nf.domains',
  'images.nf.domains',
  'ipfs.algonode.xyz',
  'algoxnft.mypinata.cloud',
  'cloudflare-ipfs.com',
  'ipfs.algonft.tools',
  'ipfs.io'
]

export const MAX_SIZE_TO_OPTIMIZE = 10000000 // 10MB
