import { toast } from 'react-hot-toast'
import ErrorToast from 'components/toasts/ErrorToast'
import { captureException } from '@sentry/nextjs'
import { CustomError } from 'api/customError'
import usePush from 'hooks/usePush'
import { formatPrice } from 'helpers/utilities'

type HandleErrorOptions = {
  errorMessage?: string
  redirect?: string
  toastId?: string
}

export default function useErrorToast(fallbackMessage?: string, duration = Infinity) {
  const push = usePush()

  return (error: unknown, options?: HandleErrorOptions) => {
    const errorMessage =
      getErrorMessage(error) || options?.errorMessage || fallbackMessage || JSON.stringify(error)

    console.error(error)
    captureException(error)

    if (options?.redirect) {
      push(options.redirect)
    }

    toast.error(
      (t) => {
        return ErrorToast({
          error: errorMessage,
          t
        })
      },
      {
        duration,
        ...(options?.toastId && { id: options.toastId })
      }
    )
  }
}

function getErrorMessage(error: unknown) {
  if (error instanceof Error || error instanceof CustomError) {
    return processTxnErrorMessage(error.message)
  }

  return null
}

function processTxnErrorMessage(message: string) {
  // Algod error base pattern
  const algodErrorPattern = /TransactionPool\.Remember:/i

  if (!algodErrorPattern.test(message)) {
    return message
  }

  // Mint collision error
  const alreadyOptedInPattern = /account \w+ has already opted in to app \d+/
  if (alreadyOptedInPattern.test(message)) {
    return 'This NFD has already been minted.'
  }

  // Verification already requested error
  const verificationRequestedPattern = /this name\/nfd already has a verify request outstanding/i
  if (verificationRequestedPattern.test(message)) {
    return 'There is already a pending verification request for this NFD. Please wait 15 minutes and try again.'
  }

  // Insufficient balance error
  const insufficientBalancePattern = /account (\w+) balance (\d+) below min (\d+) \((\d+) assets\)/
  let match = message.match(insufficientBalancePattern)
  if (match) {
    const account = match[1]
    const balance = formatPrice(parseInt(match[2]))
    const min = formatPrice(parseInt(match[3]))
    const assets = match[4]
    return `Insufficient balance: ${account} balance ${balance} ALGO below min ${min} ALGO (${assets} assets)`
  }

  // Overspend error
  const overspendPattern =
    /account (\w+), data.*MicroAlgos:\{Raw:(\d+)\}[^}]*\}[^}]*\}[^}]*\}[^}]*\}[^}]*\}[^}]*\}, tried to spend \{(\d+)\}/s
  match = message.match(overspendPattern)
  if (match) {
    const account = match[1]
    const balance = formatPrice(parseInt(match[2]))
    const triedToSpend = formatPrice(parseInt(match[3]))
    return `Insufficient balance: ${account} has ${balance} ALGO available but attempted to spend ${triedToSpend} ALGO`
  }

  // Transaction sent outside range error
  const outsideRangePattern = /txn dead: round (\d+) outside of (\d+)--(\d+)/
  match = message.match(outsideRangePattern)
  if (match) {
    const round = match[1]
    const rangeStart = match[2]
    const rangeEnd = match[3]
    return `Transaction sent outside range: round ${round} outside of ${rangeStart}–${rangeEnd}`
  }

  // Smart contract logic eval error
  const smartContractErrorPattern = /transaction (\w+): logic eval error: (.+)/
  match = message.match(smartContractErrorPattern)
  if (match) {
    const txnId = match[1]
    const details = match[2]
    return `Smart contract logic eval error: ${details}. Transaction: ${txnId}`
  }

  // Transaction rejected by smart contract error
  const rejectedByContractPattern = /transaction (\w+): transaction rejected by ApprovalProgram/
  match = message.match(rejectedByContractPattern)
  if (match) {
    const txnId = match[1]
    return `Transaction rejected by smart contract. Transaction: ${txnId}`
  }

  // No match, return original message
  return message
}
