import React, { useRef } from 'react'
import { clsxMerge } from 'helpers/utilities'

interface TooltipProps extends React.HTMLAttributes<HTMLElement> {
  as?: 'div' | 'span'
  children: React.ReactElement<HTMLElement>
  text: string
  direction?: 'top' | 'bottom' | 'left' | 'right'
  distance?: number
  className?: string
  wrapperClassName?: string
}

const Tooltip: React.FC<TooltipProps> = ({
  as = 'div',
  children,
  text,
  direction = 'top',
  distance = 16,
  className = '',
  wrapperClassName = ''
}) => {
  const tipRef = useRef<HTMLSpanElement>(null)

  const getMarginRule = () => {
    switch (direction) {
      case 'top':
        return 'marginBottom'
      case 'bottom':
        return 'marginTop'
      case 'left':
        return 'marginRight'
      case 'right':
        return 'marginLeft'
      default:
        return 'marginBottom'
    }
  }

  const getTooltipClassname = () => {
    switch (direction) {
      case 'top':
        return 'bottom-full left-1/2 -translate-x-1/2'
      case 'bottom':
        return 'top-full left-1/2 -translate-x-1/2'
      case 'left':
        return 'right-full'
      case 'right':
        return 'left-full'
      default:
        return 'bottom-full'
    }
  }

  const getArrowClassname = () => {
    switch (direction) {
      case 'top':
        return '-bottom-[4px] left-1/2 -translate-x-1/2'
      case 'bottom':
        return '-top-[4px] left-1/2 -translate-x-1/2'
      case 'left':
        return '-right-[4px]'
      case 'right':
        return '-left-[4px]'
      default:
        return '-bottom-[4px] left-1/2 -translate-x-1/2'
    }
  }

  const tooltipClassname = clsxMerge(
    'absolute inline-flex items-center px-2.5 py-1 rounded-md text-sm font-medium text-white bg-gray-900 whitespace-nowrap transition-all duration-150 opacity-0 shadow-sm pointer-events-none dark:bg-gray-200 dark:text-gray-800',
    getTooltipClassname(),
    className
  )

  const arrowClassname = clsxMerge(
    'block bg-gray-900 h-2 w-2 absolute rotate-45 dark:bg-gray-200',
    getArrowClassname()
  )

  const handleMouseEnter = () => {
    if (tipRef.current) {
      tipRef.current.style.opacity = '1'
      tipRef.current.style[getMarginRule()] = `${distance}px`
    }
  }

  const handleMouseLeave = () => {
    if (tipRef.current) {
      tipRef.current.style.opacity = '0'
      tipRef.current.style[getMarginRule()] = `${distance / 2}px`
    }
  }

  const Component = as as React.ElementType

  return (
    <Component
      className={clsxMerge(
        as === 'div' ? 'flex' : 'inline-flex',
        'relative items-center z-10',
        wrapperClassName
      )}
    >
      <span className={tooltipClassname} ref={tipRef}>
        <span className={arrowClassname} />
        {text}
      </span>
      {React.Children.map(children, (child: React.ReactNode) => {
        if (React.isValidElement<React.HTMLAttributes<HTMLElement>>(child)) {
          return React.cloneElement(child, {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave
          })
        }
      })}
    </Component>
  )
}

export default Tooltip
