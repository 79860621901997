import { Combobox, Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { BiWallet } from 'react-icons/bi'
import { FaUsersSlash } from 'react-icons/fa'
import { HiOutlineSearch } from 'react-icons/hi'
import { HiChevronLeft, HiSparkles } from 'react-icons/hi2'
import ButtonLink from 'components/ButtonLink'
import Loading from 'components/Loading'
import SendModal from 'components/SendModal'
import Avatar from 'components/UserThumbnail/Avatar'
import AddressCard from './AddressCard'
import NfdCard from './NfdCard'
import useAdvancedSearch from './AdvancedSearch.hooks'
import { classNames, isValidName, trimExtension, truncateAddress } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

interface AdvancedSearchProps {
  addressLookup?: boolean
  suggestMint?: boolean
}

export default function AdvancedSearch({
  addressLookup = false,
  suggestMint = false
}: AdvancedSearchProps) {
  const {
    open,
    setOpen,
    handleSearchClick,
    handleLeave,
    query,
    debouncedQuery,
    isFetching,
    suggestions,
    selected,
    setSelected,
    handleSelection,
    handleInputChange,
    recentSearches,
    activeAccount,
    isSendModalOpen,
    setIsSendModalOpen,
    handleSendClick
  } = useAdvancedSearch({ addressLookup })

  const renderCard = (activeOption: NfdRecord | string | null) => {
    if (!activeOption) return null

    if (typeof activeOption === 'string') {
      return (
        <AddressCard
          address={activeOption}
          activeAddress={activeAccount?.address}
          onSendClick={handleSendClick}
          onViewClick={() => setOpen(false)}
        />
      )
    }

    return (
      <NfdCard
        nfd={activeOption}
        activeAddress={activeAccount?.address}
        onSendClick={handleSendClick}
        onViewClick={() => setOpen(false)}
      />
    )
  }

  const renderSuggestion = (suggestion: NfdRecord | string | null) => {
    if (!suggestion) return null

    const isAddress = typeof suggestion === 'string'

    const renderAddress = (address: string) => {
      const addr = truncateAddress(address, { array: true })

      return (
        <span className="flex items-center flex-auto text-sm font-mono">
          <BiWallet className="flex-shrink-0 ml-2 mr-5 h-6 w-6 my-1.5" aria-hidden="true" />
          {addr[0]}&hellip;{addr[1]}
        </span>
      )
    }

    const renderNfd = (nfd: NfdRecord) => {
      return (
        <>
          <Avatar nfd={nfd} isPadded />
          <span className="ml-3 flex-auto font-medium truncate">{nfd.name}</span>
        </>
      )
    }

    const key = isAddress ? suggestion : suggestion.name

    return (
      <Combobox.Option
        as="div"
        key={key}
        value={suggestion}
        className={({ active }) =>
          classNames(
            'flex cursor-pointer select-none items-center rounded-md py-1.5 px-2',
            active ? 'bg-gray-100 text-gray-900 dark:bg-gray-850 dark:text-gray-100' : ''
          )
        }
      >
        {isAddress ? renderAddress(suggestion) : renderNfd(suggestion)}
      </Combobox.Option>
    )
  }

  const renderMenu = (selected: NfdRecord | string | null) => {
    if (debouncedQuery === '' && recentSearches.length === 0) {
      return null
    }

    if (debouncedQuery === '' || suggestions.length > 0) {
      return (
        <Combobox.Options
          as="div"
          static
          hold
          className="relative flex md:divide-x md:divide-gray-100 dark:divide-gray-800"
        >
          <div
            className={classNames(
              'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
              selected ? 'h-96' : ''
            )}
          >
            {debouncedQuery === '' && (
              <h2 className="mt-2 mb-4 text-xs font-semibold text-gray-500">Recent searches</h2>
            )}
            <div className="-mx-2 text-sm text-gray-700 dark:text-gray-400">
              {(debouncedQuery === '' ? recentSearches : suggestions)
                .map(renderSuggestion)
                .filter(Boolean)}
            </div>
          </div>

          {!!selected && (
            <div className="md:hidden absolute top-0 left-0 pt-4 pl-4 z-[61]">
              <button
                type="button"
                className="inline-flex items-center bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 dark:bg-transparent dark:text-gray-600 dark:hover:text-gray-500 dark:ring-offset-gray-900 dark:ring-gray-300"
                onClick={() => setSelected(null)}
              >
                <HiChevronLeft className="h-6 w-6" aria-hidden="true" />
                <span className="sr-only">Back</span>
              </button>
            </div>
          )}

          {renderCard(selected)}
        </Combobox.Options>
      )
    }

    if (debouncedQuery !== '' && suggestions.length === 0) {
      if (isFetching) {
        return (
          <div className="py-14 px-6 flex items-center justify-center sm:px-14">
            <Loading />
          </div>
        )
      }

      const nfdToMint = `${trimExtension(debouncedQuery)}.algo` // prevent double '.algo'

      return (
        <div className="py-14 px-6 text-center text-sm sm:px-14">
          <FaUsersSlash
            className="mx-auto h-8 w-8 text-gray-400 dark:text-gray-600"
            aria-hidden="true"
          />
          <p className="mt-4 font-semibold text-gray-900 dark:text-gray-100">No matches found</p>
          {suggestMint && isValidName(nfdToMint) ? (
            <ButtonLink
              href={`/mint?q=${nfdToMint}`}
              variant="primary"
              icon={HiSparkles}
              className="mt-4"
              onClick={() => setOpen(false)}
            >
              Mint {nfdToMint}
            </ButtonLink>
          ) : (
            <p className="mt-2 text-gray-500">
              We couldn&apos;t find any NFDs matching that term. Please try again.
            </p>
          )}
        </div>
      )
    }
  }

  return (
    <>
      <div className="relative inset-0 h-full flex items-center">
        <button
          className="flex-1 flex items-center group pl-4 pr-4 py-2 -ml-2 rounded-sm text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600 dark:text-gray-600 dark:focus:text-gray-400"
          onClick={handleSearchClick}
        >
          <HiOutlineSearch
            className="-ml-2 h-6 w-6 mr-2.5 group-focus:text-gray-800 dark:group-focus:text-gray-200"
            aria-hidden="true"
          />
          <span className="hidden xs:inline-flex items-center">
            Find an NFD{' '}
            <kbd
              className={classNames(
                isMobile
                  ? 'hidden'
                  : 'inline-flex items-center rounded border border-gray-200 ml-2.5 px-1 font-mono text-sm text-gray-400 dark:border-white/5 dark:bg-gray-400/5 dark:text-gray-600'
              )}
            >
              /
            </kbd>
          </span>
        </button>
      </div>

      <Transition.Root show={open} as={Fragment} afterLeave={handleLeave} appear>
        <Dialog as="div" className="relative z-[60]" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/75 transition-opacity dark:bg-black/75" />
          </Transition.Child>

          <div className="fixed inset-0 z-[60] overflow-y-auto pt-20 pb-4 px-4 sm:pb-6 sm:px-6 lg:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all dark:bg-gray-900 dark:divide-gray-800 dark:highlight">
                <Combobox value={selected} onChange={handleSelection}>
                  <div className="relative">
                    <HiOutlineSearch
                      className="pointer-events-none absolute top-3.5 left-4 my-1 mx-0.5 h-5 w-5 text-gray-400 dark:text-gray-500"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-14 w-full border-0 bg-transparent pl-12 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 dark:text-gray-100 dark:placeholder-gray-500 dark:caret-gray-400"
                      placeholder="Search..."
                      displayValue={() => query}
                      onChange={handleInputChange}
                      autoComplete="new-password"
                      spellCheck={false}
                      autoCorrect="off"
                      autoCapitalize="off"
                      maxLength={60}
                    />
                  </div>

                  {renderMenu(selected)}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {!!activeAccount && !!selected && (
        <SendModal
          key={typeof selected === 'string' ? selected : selected.name}
          isOpen={isSendModalOpen}
          setIsOpen={setIsSendModalOpen}
          sender={activeAccount.address}
          receiver={typeof selected === 'string' ? selected : selected.name}
          showReceiver
          onClose={() => setSelected(null)}
        />
      )}
    </>
  )
}
