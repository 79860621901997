import AssetMedia from 'components/AssetMedia'
import { getAvatarURL } from 'helpers/avatar'
import galleryImageLoader from 'helpers/galleryImageLoader'
import type { NfdRecord } from 'api/api-client'

type AvatarImageProps = {
  nfd: NfdRecord
}

export default function AvatarImage({ nfd }: AvatarImageProps) {
  return (
    <AssetMedia
      src={getAvatarURL(nfd)}
      alt={nfd.name}
      className="object-cover w-full h-full"
      loader={galleryImageLoader}
      sizes="48px"
      fill
      options={{ optimizeGifs: false }}
    />
  )
}
