import { useState, useMemo } from 'react'
import { generateOnRampURL } from 'lib/coinbasePay'
import { isValidName } from 'helpers/utilities'
import { Account, CaName } from './CoinbasePay.types'
import { BLOCKCHAINS_SMYBOL_NAME_MAP } from './Coinbase.constants'
import { NfdRecord } from 'api/api-client'

const DEFAULT_ACCOUNT: { blockchain: CaName; symbol: string; address: string } = {
  blockchain: 'algorand',
  symbol: 'ALGO',
  address: ''
}

export default function useCoinbasePayComponent() {
  const [name, setName] = useState('')
  const [accounts, setAccounts] = useState<Account[]>([DEFAULT_ACCOUNT])
  const [selectedAccount, setSelectedAccount] = useState<Account>(DEFAULT_ACCOUNT)
  const [onrampUrl, setOnrampUrl] = useState('')

  const reset = () => {
    setName('')
    setOnrampUrl('')
  }

  const initOnrampUrl = async (_account: Account) => {
    const wallets = [
      {
        address: _account.address,
        blockchains: [_account.blockchain]
      }
    ]

    const _onrampUrl = await generateOnRampURL({ destinationWallets: wallets })

    setOnrampUrl(_onrampUrl)
  }

  const handleChangeName = async (_name: string, nfd?: NfdRecord) => {
    try {
      setName(_name)

      if (!isValidName(_name) || !nfd) {
        return
      }

      const _accounts: Account[] = [
        { blockchain: 'algorand', symbol: 'ALGO', address: nfd.depositAccount || '' }
      ]

      if (nfd.unverifiedCa) {
        Object.entries(nfd.unverifiedCa).forEach(([symbol, address]) => {
          if (!Object.keys(BLOCKCHAINS_SMYBOL_NAME_MAP).includes(symbol.toUpperCase() as CaName)) {
            return
          }

          _accounts.push({
            blockchain: BLOCKCHAINS_SMYBOL_NAME_MAP[symbol.toUpperCase()] as CaName,
            symbol: symbol.toUpperCase(),
            address: address[0]
          })
        })
      }

      setAccounts(_accounts)
      setSelectedAccount(_accounts[0])

      await initOnrampUrl(_accounts[0])
    } catch (e) {
      console.error('No matches found')
    }
  }

  const handleChangeAccount = async (account: string) => {
    const _account = JSON.parse(account) as Account
    setSelectedAccount(_account)
    await initOnrampUrl(_account)
  }

  const handleClickCoinbasePay = (href: string) => {
    window.open(href, '_blank', 'noopener,noreferrer')
    reset()
  }

  const isDisabled = useMemo(() => {
    return !Boolean(selectedAccount.address) || !isValidName(name)
  }, [name, selectedAccount.address])

  return {
    name,
    accounts,
    selectedAccount,
    onrampUrl,
    isDisabled,
    handleChangeName,
    handleChangeAccount,
    handleClickCoinbasePay
  }
}
