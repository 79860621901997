import type { NfdRecord } from 'api/api-client'

export const getNfdVersion = (nfd: NfdRecord | undefined) => {
  return nfd?.properties?.internal?.ver
}

export const parseVersion = (versionString: string): { major: number; minor: number } => {
  const [major = '', minor = ''] = versionString.split('.')
  return {
    major: major === '' ? 0 : parseInt(major, 10),
    minor: minor === '' ? 0 : parseInt(minor, 10)
  }
}

export const meetsMinimumVersion = (nfd: NfdRecord, minVersion: string): boolean => {
  const nfdVersion = getNfdVersion(nfd)

  if (typeof nfdVersion === 'undefined') {
    throw new Error('NFD does not contain version information')
  }

  const nfdParsedVersion = parseVersion(nfdVersion)
  const minParsedVersion = parseVersion(minVersion)

  if (nfdParsedVersion.major > minParsedVersion.major) {
    return true
  } else if (nfdParsedVersion.major < minParsedVersion.major) {
    return false
  } else {
    // Same major version, check minor
    return nfdParsedVersion.minor >= minParsedVersion.minor
  }
}

// Feature support functions
export const isSegmentsSupported = (nfd: NfdRecord): boolean => {
  return meetsMinimumVersion(nfd, '1.16')
}

export const isVaultsSupported = (nfd: NfdRecord): boolean => {
  return meetsMinimumVersion(nfd, '2.6')
}
