import SocialLink from './SocialLink'
import useSocialLinks from './SocialLinks.hooks'
import type { SocialLinkType } from './SocialLinks.types'
import type { NfdRecord } from 'api/api-client'

interface SocialLinksProps {
  nfd: NfdRecord
}

export default function SocialLinks({ nfd }: SocialLinksProps) {
  const { telegramQuery, discordQuery, isLoading, hasNoVerifiedSocials } = useSocialLinks(nfd)

  const getValue = (type: SocialLinkType) => {
    switch (type) {
      case 'telegram':
        return telegramQuery.data
      case 'discord':
        return discordQuery.data
      default:
        return nfd.properties?.verified?.[type]
    }
  }

  if (!isLoading && hasNoVerifiedSocials) {
    return <span className="px-1">N/A</span>
  }

  return (
    <div className="flex items-center space-x-2">
      <SocialLink type="email" value={getValue('email')} />
      <SocialLink type="twitter" value={getValue('twitter')} />
      <SocialLink
        type="telegram"
        value={getValue('telegram')}
        isLoading={telegramQuery.isInitialLoading}
      />
      <SocialLink
        type="discord"
        value={getValue('discord')}
        isLoading={discordQuery.isInitialLoading}
      />
    </div>
  )
}
