import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
// import LogRocket from 'logrocket'
import queryString from 'query-string'
import type { NfdBrowseParams } from 'api/api-client'
import * as rax from 'retry-axios'
import axios from 'axios'
import { CustomError } from 'api/customError'

const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API as string
})

AXIOS_INSTANCE.defaults.raxConfig = {
  instance: AXIOS_INSTANCE,
  retry: 5,
  statusCodesToRetry: [[429, 429]],
  onRetryAttempt: async (err: AxiosError) => {
    if (err.response?.data?.secsRemaining) {
      console.log(`Rate limit exceeded, retrying in ${err.response.data.secsRemaining} seconds`)
      await wait(err.response.data.secsRemaining + 1)
      return true
    } else {
      const cfg = rax.getConfig(err)
      console.log(`Retry attempt #${cfg?.currentRetryAttempt}`)
    }
  }
}

function wait(secs: number) {
  return new Promise((resolve) => setTimeout(resolve, secs * 1000))
}

const _interceptorId = rax.attach(AXIOS_INSTANCE)

export const customAxios = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  // let recordingURL = ''

  // LogRocket.getSessionURL(function (sessionURL) {
  //   recordingURL = sessionURL
  // })

  const axiosConfig = {
    ...config,
    ...options,
    headers: {
      ...config.headers,
      ...options?.headers
      // 'X-LogRocket-URL': recordingURL
    },
    paramsSerializer: (params: NfdBrowseParams) => queryString.stringify(params)
  }

  const promise = AXIOS_INSTANCE(axiosConfig)
    .then((res) => res)
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        throw new CustomError(
          error.response?.data.name,
          error.response?.data.message,
          error.response?.status,
          error.response?.headers,
          error
        )
      }

      throw new CustomError(error?.name, error?.message, error?.statusCode, error?.headers, error)
    })

  return promise
}
