import { useInfiniteQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { SelectAssetProps } from './SelectAsset'
import useDebounce from 'hooks/useDebounce'
import type { AccountAsset, AccountAssetsResponse } from 'types/node'

export default function useSelectAsset({
  sender,
  selectedAsset,
  setSelectedAsset
}: SelectAssetProps) {
  const [query, setQuery] = useState('')
  const debouncedSearchText = useDebounce(query, 300)

  const fetchAssets = async ({ pageParam = 0 }) => {
    const { data } = await axios.get(`/api/node/account-assets`, {
      params: {
        address: sender as string,
        cursor: pageParam,
        ...(debouncedSearchText && { search: debouncedSearchText })
      }
    })
    return data
  }

  const enableQuery = useMemo(
    () => !!sender && debouncedSearchText === query,
    [debouncedSearchText, query, sender]
  )

  const infiniteQuery = useInfiniteQuery<AccountAssetsResponse, Error>({
    queryKey: ['select-assets', sender, { searchText: debouncedSearchText }],
    queryFn: fetchAssets,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    enabled: enableQuery,
    staleTime: 1 * 60 * 1000
  })

  const { data, isLoading } = infiniteQuery

  useEffect(() => {
    if (data && !selectedAsset) {
      setSelectedAsset(data.pages[0].assets[0])
    }
  }, [data, selectedAsset, setSelectedAsset])

  const allAssets = useMemo(() => data?.pages?.flatMap((page) => page.assets) || [], [data])

  const handleSelectAsset = (asset: AccountAsset) => {
    setSelectedAsset(asset)
    setQuery('')
  }

  const showMenu = useMemo(() => enableQuery && !isLoading, [enableQuery, isLoading])

  const showAssets = useMemo(() => showMenu && allAssets.length > 0, [allAssets.length, showMenu])

  const showNoResults = useMemo(
    () => showMenu && allAssets.length === 0 && debouncedSearchText !== '',
    [allAssets.length, debouncedSearchText, showMenu]
  )

  const showSpinner = useMemo(() => enableQuery && isLoading, [enableQuery, isLoading])

  return {
    infiniteQuery,
    setQuery,
    handleSelectAsset,
    showAssets,
    showNoResults,
    showSpinner
  }
}
