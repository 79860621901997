import AlgoSymbol from 'components/AlgoSymbol'

type BalanceProps = {
  balance: string | null
  availableBalance: string | null
}

export default function Balance({ balance, availableBalance }: BalanceProps) {
  if (!balance) {
    return null
  }

  return (
    <div className="flex-shrink-0">
      <div className="hidden sm:grid sm:grid-cols-[72px_1fr] sm:gap-x-3">
        <span className="flex justify-end items-center col-start-1 text-sm text-gray-600 dark:text-gray-400">
          Total
        </span>
        <span className="flex col-start-2 border-l dark:border-gray-700 dark:text-gray-300">
          <span className="font-mono whitespace-nowrap">
            <AlgoSymbol className="mr-1.5 ml-3" />
            {balance}
          </span>
        </span>
        <span className="flex justify-end items-center col-start-1 text-sm text-gray-600 dark:text-gray-400">
          Available
        </span>
        <span className="col-start-2 border-l dark:border-gray-700 dark:text-gray-300">
          <span className="font-mono whitespace-nowrap">
            <AlgoSymbol className="mr-1.5 ml-3" />
            {availableBalance}
          </span>
        </span>
      </div>
      <div className="sm:hidden">
        <div>
          <span className="text-sm text-gray-600 dark:text-gray-400">Total: </span>
          <span className="font-mono whitespace-nowrap dark:text-gray-300">
            <AlgoSymbol className="mr-1.5 ml-3" />
            {balance}
          </span>
        </div>
        <div>
          <span className="text-sm text-gray-600 dark:text-gray-400">Available: </span>
          <span className="font-mono whitespace-nowrap dark:text-gray-300">
            <AlgoSymbol className="mr-1.5 ml-3" />
            {availableBalance}
          </span>
        </div>
      </div>
    </div>
  )
}
