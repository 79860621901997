import { useCallback, useEffect, useMemo, useState } from 'react'
import useSearch, { getByQuery } from 'api/hooks/v2/useSearch'
import useDebounce from 'hooks/useDebounce'
import { isVaultLocked as _isVaultLocked } from 'helpers/vaults'
import { isValidName, truncateAddress } from 'helpers/utilities'
import type { NfdRecord, NfdSearchV2StateItem } from 'api/api-client'
import type { NfdLookupProps } from './NfdLookup'

export default function useNfdLookup(props: NfdLookupProps) {
  const {
    receiver,
    onRecieverChange,
    onNfdChange,
    onReceiverTypeChange,
    limit,
    readOnly,
    nfdState
  } = props

  const [query, setQuery] = useState('')
  const [receiverType, setReceiverType] = useState(props.receiverType || 'account')
  const [selectedNfd, setSelectedNfd] = useState<NfdRecord | undefined>(
    props.selectedNfd || undefined
  )
  const [isExactMatch, setIsExactMatch] = useState(false)

  const sendToVault = receiverType === 'nfdVault'

  const debouncedQuery = useDebounce(query, 500)

  const sanitizedQuery = useMemo(() => {
    return debouncedQuery
      .replace(/[^a-zA-Z0-9.]/g, '')
      .slice(0, 32)
      .toLowerCase()
  }, [debouncedQuery])

  const enableQuery = useMemo(() => {
    return sanitizedQuery.length > 0 && debouncedQuery === query
  }, [debouncedQuery, query, sanitizedQuery])

  const { data = [], isLoading } = useSearch({
    params: {
      prefix: sanitizedQuery,
      view: 'full',
      state: nfdState,
      sort: 'nameAsc',
      limit
    },
    options: {
      enabled: enableQuery
    }
  })

  const suggestions = useMemo(() => {
    return data.filter((nfd) => (sendToVault ? nfd.nfdAccount : nfd.depositAccount))
  }, [data, sendToVault])

  const showSuggestions = useMemo(() => {
    return enableQuery && !isLoading && suggestions.length > 0 && !isExactMatch
  }, [enableQuery, isExactMatch, isLoading, suggestions.length])

  const isVaultLocked = useMemo(() => {
    if (!selectedNfd) return false
    return _isVaultLocked(selectedNfd)
  }, [selectedNfd])

  const getTooltipAddress = (nfd: NfdRecord) => {
    const address = sendToVault ? nfd.nfdAccount : nfd.depositAccount

    return truncateAddress(address) as string
  }

  const handleNfdChange = useCallback(
    (nfd: NfdRecord) => {
      setSelectedNfd(nfd)
      onNfdChange?.(nfd)

      const receiver = sendToVault ? nfd.name : nfd.depositAccount

      if (receiver) {
        onRecieverChange(receiver)
      }
    },
    [onRecieverChange, onNfdChange, sendToVault]
  )

  const fetchNfdMatch = useCallback(
    async (value: string, readOnly = false) => {
      const result = await getByQuery({ prefix: value, view: 'full', limit: 2 })
      const hasOnlyOneResult = result?.length === 1
      const nfdMatch = result?.find((nfd) => {
        if (nfd.name !== value) {
          return false
        }

        if (nfd.state && nfdState && !nfdState.includes(nfd.state as NfdSearchV2StateItem)) {
          return false
        }

        return true
      })

      if (readOnly) {
        // Recipient is known and field is read-only. Throw an error if no match is found.
        if (nfdMatch) {
          handleNfdChange(nfdMatch)
          setIsExactMatch(true)
        } else {
          throw new Error('No NFD found')
        }
      } else {
        // User is typing. If two results are found, do nothing. If one result is found, set it.
        if (hasOnlyOneResult && nfdMatch) {
          handleNfdChange(nfdMatch)
          setIsExactMatch(true)
        }
      }
    },
    [handleNfdChange, nfdState]
  )

  useEffect(() => {
    if (readOnly && isValidName(receiver) && selectedNfd?.name !== receiver) {
      fetchNfdMatch(receiver, true)
    }
  }, [fetchNfdMatch, readOnly, receiver, selectedNfd?.name])

  const isReadOnlyAccountSend = useMemo(
    () => readOnly && props.receiverType === 'account',
    [props.receiverType, readOnly]
  )

  const isReadOnlyVaultSend = useMemo(
    () => readOnly && props.receiverType === 'nfdVault',
    [props.receiverType, readOnly]
  )

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = isValidName(event.target.value.toLowerCase())
      ? event.target.value.toLowerCase()
      : event.target.value

    setSelectedNfd(undefined)
    onNfdChange?.(undefined)
    setReceiverType('account')
    setIsExactMatch(false)

    setQuery(inputValue)

    if (isValidName(inputValue)) {
      try {
        await fetchNfdMatch(inputValue)
      } catch (error) {
        console.error(error)
        onRecieverChange(inputValue)
      }
    } else {
      onRecieverChange(inputValue)
    }
  }

  const handleSendToVaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sendToVault = event.target.checked

    setReceiverType(sendToVault ? 'nfdVault' : 'account')
    onReceiverTypeChange?.(sendToVault ? 'nfdVault' : 'account')

    if (selectedNfd) {
      const value = sendToVault ? selectedNfd.name : selectedNfd.depositAccount

      if (value) {
        onRecieverChange(value)
      }
    }
  }

  return {
    query,
    sendToVault,
    selectedNfd,
    suggestions,
    showSuggestions,
    isVaultLocked,
    isReadOnlyAccountSend,
    isReadOnlyVaultSend,
    getTooltipAddress,
    handleInputChange,
    handleNfdChange,
    handleSendToVaultChange
  }
}
