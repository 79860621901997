import { captureException } from '@sentry/nextjs'
import { UseQueryOptions, useQueries } from '@tanstack/react-query'
import { NfdGetNFDParams, NfdRecord } from 'api/api-client'
import { getByName } from './useName'
import { useOptimisticUpdateStore } from 'store/index'
import { isValidName } from 'helpers/utilities'
import type { ICustomError } from 'api/customError'

type UseNamesArgs = {
  names: string[]
  params?: NfdGetNFDParams
  options?: UseQueryOptions<NfdRecord | void, ICustomError>
}

export default function useNames({ names, params, options }: UseNamesArgs) {
  const getOptimisticUpdates = useOptimisticUpdateStore((state) => state.getOptimisticUpdates)

  const queries = names.map((name) => {
    return {
      queryKey: ['name', name],
      queryFn: () => getByName(name, params),
      enabled: isValidName(name),
      select: (data: NfdRecord | void) => {
        if (!data) return data

        const [update] = getOptimisticUpdates({ queryKey: ['name', name] })

        if (!update) return data

        return {
          ...data,
          ...update.record
        } as NfdRecord
      },
      ...options
    }
  })

  const results = useQueries({ queries })

  results.forEach((result) => result.error && captureException(result.error))

  return results
}
