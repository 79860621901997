import { CaName } from './CoinbasePay.types'

// https://docs.cloud.coinbase.com/pay-sdk/docs/faq
export const SUPPORTED_BLOCKCHAINS = [
  'ethereum',
  'bitcoin',
  'solana',
  'avalanche c-Chain',
  'celo',
  'algorand',
  'bitcoin cash',
  'cardano',
  'cosmos',
  'dash',
  'dogecoin',
  'EOS',
  'ethereum classic',
  'filecoin',
  'dfinity',
  'horizenv',
  'litecoin',
  'polkadot',
  'stellar',
  'tezos',
  'zcash'
] as const

/** @todo get key types from api-client */
export const BLOCKCHAINS_SMYBOL_NAME_MAP: { [key: string]: CaName } = {
  ETH: 'ethereum',
  BTC: 'bitcoin',
  SOL: 'solana',
  AVAX: 'avalanche c-Chain',
  CGLD: 'celo',
  ALGO: 'algorand',
  BCH: 'bitcoin cash',
  ADA: 'cardano',
  ATOM: 'cosmos',
  DASH: 'dash',
  DOGE: 'dogecoin',
  EOS: 'EOS',
  ETC: 'ethereum classic',
  FIL: 'filecoin',
  ICP: 'dfinity',
  ZEN: 'horizenv',
  LTC: 'litecoin',
  DOT: 'polkadot',
  XLM: 'stellar',
  XTZ: 'tezos',
  ZEC: 'zcash'
}
