import { useEffect, useRef } from 'react'
import type { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'
import { initVideoJS } from './Video.utils'
import 'video.js/dist/video-js.css'

export type VideoProps = {
  src: string
  type: string
  title?: string
  preload?: 'auto' | 'metadata' | 'none'
  options?: VideoJsPlayerOptions
  onReady?: (player: VideoJsPlayer) => void
}

export default function Video({ src, type, title, options, onReady, preload }: VideoProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const playerRef = useRef<VideoJsPlayer | null>(null)

  useEffect(() => {
    if (!playerRef.current) {
      initVideoJS(src, type, videoRef, playerRef, onReady, options)
    }
  }, [onReady, options, src, type, videoRef])

  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player style={{ height: '100%' }}>
      <video
        title={title}
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        preload={preload}
      />
    </div>
  )
}
