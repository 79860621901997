import { useEffect, useState } from 'react'
import { immer } from 'zustand/middleware/immer'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import type {
  NfdSearchV2CategoryItem,
  NfdSearchV2LengthItem,
  NfdSearchV2Sort,
  NfdSearchV2StateItem
} from 'api/api-client'

export type MarketplaceView = 'grid' | 'list'

export type PriceRange = {
  minPrice: number | null
  maxPrice: number | null
}

export type PriceRangeUsd = {
  minPriceUsd: number | null
  maxPriceUsd: number | null
}

export type MarketplaceStore = {
  category: NfdSearchV2CategoryItem[] | null
  setCategory: (category: NfdSearchV2CategoryItem[] | null) => void

  length: NfdSearchV2LengthItem[] | null
  setLength: (length: NfdSearchV2LengthItem[] | null) => void

  sort: NfdSearchV2Sort | null
  setSort: (sort: NfdSearchV2Sort | null) => void

  state: NfdSearchV2StateItem[] | null
  setState: (state: NfdSearchV2StateItem[] | null) => void

  priceRange: PriceRange
  setPriceRange: (priceRange: PriceRange) => void

  priceRangeUsd: PriceRangeUsd
  setPriceRangeUsd: (priceRangeUsd: PriceRangeUsd) => void

  name: string | null
  setName: (name: string | null) => void

  segmentLocked: boolean | null
  setSegmentLocked: (segmentLocked: boolean | null) => void

  segmentRoot: boolean | null
  setSegmentRoot: (segmentView: boolean | null) => void

  excludeReserved: boolean
  setExcludeReserved: (excludeReserved: boolean) => void

  clearAllFilters: () => void

  activeFiltersCount: number
  computeActiveFilters: (state: MarketplaceStore) => number

  view: MarketplaceView
  setView: (view: MarketplaceView) => void

  limit: number | null
  setLimit: (limit: number) => void
}

const emptyState: MarketplaceStore = {
  category: null,
  setCategory: () => {
    return
  },
  length: null,
  setLength: () => {
    return
  },
  sort: null,
  setSort: () => {
    return
  },
  state: null,
  setState: () => {
    return
  },
  priceRange: {
    minPrice: null,
    maxPrice: null
  },
  setPriceRange: () => {
    return
  },
  priceRangeUsd: {
    minPriceUsd: null,
    maxPriceUsd: null
  },
  setPriceRangeUsd: () => {
    return
  },
  name: null,
  setName: () => {
    return
  },
  segmentLocked: null,
  setSegmentLocked: () => {
    return
  },
  segmentRoot: null,
  setSegmentRoot: () => {
    return
  },
  excludeReserved: true,
  setExcludeReserved: () => {
    return
  },
  clearAllFilters: () => {
    return
  },
  activeFiltersCount: 1,
  computeActiveFilters: () => {
    return 1
  },
  view: 'grid',
  setView: () => {
    return
  },
  limit: null,
  setLimit: () => {
    return
  }
}

const usePersistedStore = create<MarketplaceStore>()(
  immer(
    persist(
      devtools((set) => ({
        category: null,
        setCategory: (category) =>
          set((state) => {
            state.category = category
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        length: null,
        setLength: (length) =>
          set((state) => {
            state.length = length
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        sort: 'saleTypeAsc',
        setSort: (sort) =>
          set((state) => {
            state.sort = sort
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        state: ['forSale'],
        setState: (state_) =>
          set((state) => {
            if (state_ !== null && state_.includes('forSale') && state.segmentLocked === false) {
              state.segmentLocked = null
              state.priceRangeUsd = {
                minPriceUsd: null,
                maxPriceUsd: null
              }
            }
            state.state = state_
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        priceRange: {
          minPrice: null,
          maxPrice: null
        },
        setPriceRange: (priceRange) =>
          set((state) => {
            state.priceRangeUsd = {
              minPriceUsd: null,
              maxPriceUsd: null
            }
            state.priceRange = priceRange
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        priceRangeUsd: {
          minPriceUsd: null,
          maxPriceUsd: null
        },
        setPriceRangeUsd: (priceRangeUsd) =>
          set((state) => {
            state.priceRange = {
              minPrice: null,
              maxPrice: null
            }
            state.priceRangeUsd = priceRangeUsd
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        name: null,
        setName: (name) =>
          set((state) => {
            state.name = name
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        segmentLocked: null,
        setSegmentLocked: (segmentLocked) =>
          set((state) => {
            if (state.state?.includes('forSale') && segmentLocked === false) {
              state.state = null
              state.priceRange = {
                minPrice: null,
                maxPrice: null
              }
            }
            state.segmentLocked = segmentLocked
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        segmentRoot: null,
        setSegmentRoot: (segmentRoot) =>
          set((state) => {
            if (segmentRoot === false) {
              state.segmentLocked = null
            }

            state.segmentRoot = segmentRoot
            state.activeFiltersCount = state.computeActiveFilters(state)
          }),

        excludeReserved: true,
        setExcludeReserved: (excludeReserved) =>
          set((state) => {
            state.excludeReserved = excludeReserved
          }),

        view: 'grid',
        setView: (view: MarketplaceView) => {
          set((state) => {
            state.view = view
          })
        },

        limit: null,
        setLimit: (limit: number) => {
          set((state) => {
            state.limit = limit
          })
        },

        clearAllFilters: () =>
          set((state) => {
            state.category = null
            state.length = null
            state.state = null
            state.priceRange = {
              minPrice: null,
              maxPrice: null
            }
            state.priceRangeUsd = {
              minPriceUsd: null,
              maxPriceUsd: null
            }
            state.name = null
            state.segmentLocked = null
            state.segmentRoot = null
            state.excludeReserved = true
            state.activeFiltersCount = 0
          }),

        activeFiltersCount: 1,
        computeActiveFilters: (state: MarketplaceStore) => {
          let count = 0

          // Add the length of the array to the count if it exists
          if (state.category) {
            count += state.category.length
          }
          if (state.length) {
            count += state.length.length
          }
          if (state.state) {
            count += state.state.length
          }

          if (state.priceRange.minPrice !== null || state.priceRange.maxPrice !== null) {
            count++
          }
          if (
            state.priceRangeUsd.minPriceUsd !== null ||
            state.priceRangeUsd.maxPriceUsd !== null
          ) {
            count++
          }

          if (state.name !== null) {
            count++
          }
          if (state.segmentLocked !== null) {
            count++
          }
          if (state.segmentRoot !== null) {
            count++
          }

          return count
        }
      })),
      {
        name: 'nfd-marketplace',
        version: 2
      }
    )
  )
)

// @see https://github.com/pmndrs/zustand/issues/1145#issuecomment-1207233036
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMarketplaceStore = ((selector: any, compare: any) => {
  const store = usePersistedStore(selector, compare)
  const [hydrated, setHydrated] = useState(false)
  useEffect(() => setHydrated(true), [])

  return hydrated ? store : selector(emptyState)
}) as typeof usePersistedStore
