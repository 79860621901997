import { NfdRecord } from 'api/api-client'
import Link from 'next/link'
import { isAvatarVerified } from 'helpers/avatar'
import AvatarImage from 'components/ConnectWallet/User/AvatarImage'

type AvatarProps = {
  nfd: NfdRecord
  onNavigateToAccount?: () => void
}

function Avatar({ nfd, onNavigateToAccount }: AvatarProps) {
  const isVerified = isAvatarVerified(nfd)

  if (isVerified) {
    return (
      <Link
        href={`/name/${nfd.name}`}
        className="relative w-14 h-14 flex items-center justify-center rounded-full bg-gradient-to-br from-violet-600 to-pink-500"
        onClick={onNavigateToAccount}
      >
        <div className="h-12 w-12 relative rounded-full bg-gray-200 overflow-hidden ring-2 ring-gray-200 dark:bg-gray-800 dark:ring-gray-800">
          <AvatarImage nfd={nfd} />
        </div>
      </Link>
    )
  }

  return (
    <Link
      href={`/name/${nfd.name}`}
      className="relative w-14 h-14 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-800"
      onClick={onNavigateToAccount}
    >
      <AvatarImage nfd={nfd} />
    </Link>
  )
}

export default Avatar
