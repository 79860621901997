import { Fragment, useMemo } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import useSearch from 'api/hooks/v2/useSearch'
import useDebounce from 'hooks/useDebounce'
import { classNames, isValidName, truncateAddress } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'
import { Account } from './CoinbasePay.types'

interface LookupNameInputProps {
  name: string
  accounts?: Account[]
  selectedAccount?: Account
  onChange: (string: string, nfd?: NfdRecord) => void
  onChangeAccount: (account: string) => void
  isDisabled: boolean
  placeholder?: string
  suggestionLimit?: number
}

export default function LookupNameInput({
  name,
  accounts,
  selectedAccount,
  onChange,
  onChangeAccount,
  isDisabled,
  placeholder,
  suggestionLimit
}: LookupNameInputProps) {
  const debouncedQuery = useDebounce(name, 500)

  const trimExtension = (name: string) => {
    return name.replace(/\.\w+$|\./gm, '')
  }

  const enableQuery = useMemo(
    () =>
      trimExtension(debouncedQuery).length > 0 &&
      trimExtension(debouncedQuery).length <= 27 &&
      debouncedQuery === name &&
      debouncedQuery.match(/^[a-zA-Z0-9\.]+$/gm) !== null,
    [debouncedQuery, name]
  )

  const { data, isInitialLoading, error } = useSearch({
    params: {
      prefix: trimExtension(debouncedQuery).toLowerCase()
    },
    options: {
      enabled: enableQuery
    }
  })

  const showOptions = enableQuery && !isInitialLoading

  const handleChange = (name: string) => {
    const nfd = data?.find((nfd) => nfd.name === name)

    onChange(name, nfd)
  }

  const handleBlur = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    if (!isValidName(inputValue)) {
      return
    }

    try {
      const result = data?.find((nfd) => nfd.name === inputValue)
      const resultName = result?.name

      if (!resultName) {
        return
      }

      onChange(resultName, result)
    } catch (err) {
      console.error(err)
    }
  }

  const renderAddress = (address: string | undefined, className = '') => {
    if (!address) {
      return null
    }

    const addr = truncateAddress(address, { array: true })

    return (
      <span
        className={classNames('text-sm text-gray-400 font-mono ml-3 dark:text-gray-500', className)}
      >
        {addr[0]}&hellip;{addr[1]}
      </span>
    )
  }

  const renderOptions = () => {
    const filterData = (result: NfdRecord) => {
      const resultAddress = result.depositAccount

      if (!resultAddress) {
        return false
      }

      return true
    }

    let suggestions: NfdRecord[]

    if (suggestionLimit) {
      suggestions = data?.filter(filterData).slice(0, suggestionLimit) || []
    } else {
      suggestions = data?.filter(filterData) || []
    }

    if (error) {
      return (
        <div className="cursor-default select-none relative py-2 px-4 text-gray-500">
          <span className="text-red-500">Error:</span> {error.message}
        </div>
      )
    }

    if (suggestions.length === 0) {
      return (
        <div className="cursor-default select-none relative py-2 px-4 text-gray-500">
          No matches found, try a different name
        </div>
      )
    }

    return suggestions.map((suggestion) => (
      <Combobox.Option
        key={suggestion.name}
        className={({ active }) =>
          `cursor-default select-none py-2 px-4 text-gray-900 truncate dark:text-gray-500 ${
            active || suggestion.name === name
              ? 'bg-gray-50 dark:bg-gray-900/75'
              : 'bg-white dark:bg-gray-800'
          }`
        }
        value={suggestion.name}
      >
        <span className="dark:text-gray-200">{suggestion.name}</span>
        {suggestion.depositAccount && renderAddress(suggestion.depositAccount)}
      </Combobox.Option>
    ))
  }

  return (
    <Combobox value={name} onChange={(e) => handleChange(e)}>
      <div className="relative mt-1">
        <div className="relative flex w-full text-left bg-white cursor-default focus:outline-none sm:text-sm dark:bg-gray-950 dark:bg-transparent">
          <Combobox.Input
            className="border flex-1 rounded-l-md shadow-sm border-gray-300 focus:ring-brand-500 focus:border-brand-500 py-2 pl-3 text-sm leading-5 text-gray-900 dark:bg-gray-700/60 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500 dark:placeholder-gray-400 dark:caret-gray-500"
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            autoComplete="new-password"
            spellCheck="false"
            placeholder={placeholder}
          />
          {accounts && accounts.length && (
            <select
              disabled={isDisabled}
              id="blockchain"
              name="blockchain"
              className="relative inline-flex items-center w-24 cursor-default text-sm font-medium border rounded-r-md bg-gray-100 border-gray-300 disabled:text-gray-500 py-2 pl-3 pr-10 text-left shadow-sm focus:border-brand-500 focus:outline-none focus:ring-1 focus:ring-brand-500 sm:text-sm dark:bg-gray-700/40 dark:border-transparent dark:focus:border-brand-500"
              value={JSON.stringify(selectedAccount)}
              onChange={(e) => onChangeAccount(e.target.value)}
            >
              {accounts?.map((account) => {
                return (
                  <option
                    className="capitalize"
                    key={account.symbol}
                    value={JSON.stringify(account)}
                  >
                    {account.symbol}
                  </option>
                )
              })}
            </select>
          )}
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {showOptions ? (
            <Combobox.Options className="absolute w-full z-10 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-800 dark:text-gray-400 dark:ring-gray-800/40">
              {renderOptions()}
            </Combobox.Options>
          ) : (
            <div />
          )}
        </Transition>
      </div>
    </Combobox>
  )
}
