import { useEffect, useState } from 'react'
import { immer } from 'zustand/middleware/immer'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export type Store = {
  isDebugEnabled: boolean
  setIsDebugEnabled: (isDebuggingEnabled: boolean) => void
  hasAcceptedTerms: boolean
  setHasAcceptedTerms: (hasAcceptedTerms: boolean) => void
  showNFDsInGallery: boolean
  setShowNFDsInGallery: (show: boolean) => void
}

const emptyState: Store = {
  isDebugEnabled: false,
  setIsDebugEnabled: () => {
    return
  },
  hasAcceptedTerms: true,
  setHasAcceptedTerms: () => {
    return
  },
  showNFDsInGallery: true,
  setShowNFDsInGallery: () => {
    return
  }
}

const usePersistedStore = create<Store>()(
  immer(
    persist(
      devtools((set) => ({
        isDebugEnabled: false,
        setIsDebugEnabled: (isDebuggingEnabled) =>
          set((state) => {
            state.isDebugEnabled = isDebuggingEnabled
          }),

        hasAcceptedTerms: false,
        setHasAcceptedTerms: (hasAcceptedTerms) =>
          set((state) => {
            state.hasAcceptedTerms = hasAcceptedTerms
          }),

        showNFDsInGallery: true,
        setShowNFDsInGallery: (show) =>
          set((state) => {
            state.showNFDsInGallery = show
          })
      })),
      {
        name: 'nfd-store',
        version: 1
      }
    )
  )
)

// @see https://github.com/pmndrs/zustand/issues/1145#issuecomment-1207233036
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useStore = ((selector: any, compare: any) => {
  const store = usePersistedStore(selector, compare)
  const [hydrated, setHydrated] = useState(false)
  useEffect(() => setHydrated(true), [])

  return hydrated ? store : selector(emptyState)
}) as typeof usePersistedStore
