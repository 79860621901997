import { type SendFromVaultRequestBody, nfdSendFromVault } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'
import type { SendModalContext } from 'components/SendModal/SendModal.types'

/**
 * Type that ensures either amount or amountStr is provided, but not both.
 */
export type StrictSendFromVaultRequestBody =
  | (Omit<SendFromVaultRequestBody, 'amount' | 'amountStr'> & { amount: number; amountStr?: never })
  | (Omit<SendFromVaultRequestBody, 'amount' | 'amountStr'> & { amount?: never; amountStr: string })

export type SendFromVaultParams = {
  name: string
  body: StrictSendFromVaultRequestBody
}

type SendFromVaultOptions = MutationOptions<SendFromVaultParams, SendModalContext>

export function usePostSendFromVault(options: SendFromVaultOptions = {}) {
  return usePostTransaction<SendFromVaultParams, SendModalContext>({
    mutationFn: ({ name, body }) => nfdSendFromVault(name, body),
    ...options
  })
}
