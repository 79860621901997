import axios from 'axios'
import { DiscordData } from 'pages/api/discord/[id]'

export default async function fetchDiscordName(id: string | undefined): Promise<string> {
  if (!id) {
    return ''
  }

  const { data } = (await axios.get('/api/discord/' + id)) as { data: DiscordData }

  return data.username
}
