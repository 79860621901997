import type { MutableRefObject } from 'react'
import type { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'

export const initVideoJS = async (
  src: string,
  type: string,
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  playerRef: MutableRefObject<VideoJsPlayer | null>,
  onReady?: (player: VideoJsPlayer) => void,
  options?: VideoJsPlayerOptions
) => {
  const videojs = (await import('video.js')).default

  if (!videoRef.current) {
    return
  }

  const videoOptions = {
    sources: [
      {
        src,
        type
      }
    ],
    ...options
  }

  const player = (playerRef.current = videojs(videoRef.current, videoOptions, () => {
    onReady && onReady(player)
  }))
}
