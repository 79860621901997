/* eslint-disable @next/next/no-img-element */
import Image, { ImageProps as NextImageProps } from 'next/image'
import { MdOutlineImageNotSupported } from 'react-icons/md'
import Video from 'components/Video'
import VideoIcon from './VideoIcon'
import useAssetMedia from './AssetMedia.hooks'
import { isConfiguredHost, isImage, isLocalImage, isSmallImage } from './AssetMedia.utils'
import { DEFAULT_IMAGE, MAX_SIZE_TO_OPTIMIZE } from './AssetMedia.constants'
import { classNames } from 'helpers/utilities'
import type { ReactNode } from 'react'
import type { AssetMediaOptions, VideoOptions } from './AssetMedia.types'

export type AssetMediaProps = Omit<NextImageProps, 'src'> & {
  src: string
  className?: string
  options?: AssetMediaOptions
  videoJsOptions?: VideoOptions
}

export default function AssetMedia({
  className = '',
  src,
  alt,
  loader,
  options = {},
  videoJsOptions = {},
  ...props
}: AssetMediaProps) {
  const { mediaInfo, isLoading, isError, handleError } = useAssetMedia(src)

  const {
    optimizeGifs = false,
    showLoading = true,
    showError = true,
    stateBgColor = 'bg-black/5 dark:bg-white/5',
    defaultImg = DEFAULT_IMAGE,
    showVideoIcon = true
  } = options

  const StateWrapper = ({ children }: { children?: ReactNode }) => (
    <div className="flex items-center justify-center w-full h-full">
      <div
        className={classNames(
          isSmallImage(props)
            ? 'text-black/20 dark:text-white/20'
            : 'text-black/5 dark:text-white/5',
          'flex items-center justify-center w-full h-full',
          stateBgColor
        )}
      >
        {children}
      </div>
    </div>
  )

  if (isLoading) {
    if (!showLoading) return null

    return <StateWrapper />
  }

  if (isError || !mediaInfo) {
    if (!showError) return null

    return (
      <StateWrapper>
        <MdOutlineImageNotSupported className="w-3/5 h-3/5 max-w-[9rem]" />
      </StateWrapper>
    )
  }

  const { url, contentType, contentLength = 0 } = mediaInfo

  if (isImage(contentType)) {
    // Relative path starting with /img/
    if (isLocalImage(url)) {
      return <Image src={url} alt={alt} className={className} onError={handleError} {...props} />
    }

    // next/image would error in both cases below
    if (!isConfiguredHost(url) || contentType === 'image/svg+xml') {
      return <img src={url} alt={alt} className={className} onError={handleError} />
    }

    // Handle animated gifs
    const isGif = contentType === 'image/gif'
    const imgVideoIcon = isGif && optimizeGifs && showVideoIcon
    const unoptimized = props.unoptimized
      ? props.unoptimized
      : isGif && (!optimizeGifs || contentLength > MAX_SIZE_TO_OPTIMIZE)

    return (
      <>
        <Image
          src={url}
          alt={alt}
          className={className}
          loader={loader}
          unoptimized={unoptimized}
          onError={handleError}
          {...props}
        />
        {imgVideoIcon && <VideoIcon />}
      </>
    )
  }

  // fall back to default image if no video options are provided
  if (!videoJsOptions) {
    return defaultImg !== false ? (
      <Image src={defaultImg} alt={alt} className={className} {...props} />
    ) : (
      <StateWrapper />
    )
  }

  const { preload, ...videoOptions } = videoJsOptions
  const videoIcon = videoOptions.controls === false && !videoOptions.autoplay && showVideoIcon

  return (
    <>
      <Video src={url} type={contentType} title={alt} preload={preload} options={videoOptions} />
      {videoIcon && <VideoIcon />}
    </>
  )
}
