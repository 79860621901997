import useCoinbasePayComponent from './CoinbasePay.hooks'
import LookupNameInput from './LookupNameInput'
import Image from 'next/image'
import { isMobile } from 'react-device-detect'
import { truncateAddress } from 'helpers/utilities'

export default function CoinbasePay() {
  const {
    name,
    accounts,
    selectedAccount,
    onrampUrl,
    isDisabled,
    handleChangeName,
    handleChangeAccount,
    handleClickCoinbasePay
  } = useCoinbasePayComponent()

  const renderDepositAccount = () => {
    if (!selectedAccount?.address) {
      return null
    }

    const addr = truncateAddress(selectedAccount.address, { array: true })

    return (
      selectedAccount?.address &&
      !isDisabled && (
        <p className="ml-1 text-sm sm:text-xs text-gray-500 dark:text-gray-500">
          <span className="font-medium">
            <span className="capitalize">{selectedAccount.blockchain}</span> address:{' '}
          </span>
          <span className="font-mono">
            {addr[0]}&hellip;{addr[1]}
          </span>
        </p>
      )
    )
  }

  return (
    <div className="w-full">
      <div className="sm:flex items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full">
        <div className="flex-grow">
          <label
            htmlFor="url"
            className="pl-1 block text-sm font-medium text-gray-700 dark:text-gray-400"
          >
            Send or withdraw from Coinbase
          </label>
          <LookupNameInput
            name={name}
            accounts={accounts}
            selectedAccount={selectedAccount}
            isDisabled={isDisabled}
            onChange={handleChangeName}
            onChangeAccount={handleChangeAccount}
            placeholder="Search by NFD"
            suggestionLimit={isMobile ? 1 : undefined}
          />
        </div>
        <button
          type="button"
          onClick={() => handleClickCoinbasePay(onrampUrl)}
          disabled={isDisabled}
          className="relative inline-flex items-center justify-center h-10 w-full self-end sm:w-36 mt-1 overflow-hidden shadow-sm font-medium px-4 py-2 text-sm rounded-md border border-transparent text-white bg-[#0052FF] hover:bg-[#0052FF] hover:brightness-125 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0052FF] disabled:bg-[#0052FF] disabled:opacity-25 dark:focus:ring-offset-gray-800"
          tabIndex={0}
        >
          <span className="block sm:hidden">
            <Image
              className="no-dark-adjust object-contain"
              src="/img/coinbase-button.png"
              fill
              alt="Coinbase Pay"
              sizes="100vw"
            />
          </span>
          <span className="hidden sm:block">
            <Image
              className="no-dark-adjust object-contain"
              src="/img/coinbase-button-sm.png"
              fill
              alt="Coinbase Pay"
              sizes="142px"
            />
          </span>
        </button>
      </div>
      <div className="h-2 mt-1">{renderDepositAccount()}</div>
    </div>
  )
}
