import axios from 'axios'
import isIPFS from 'is-ipfs'

export const getIpfsCID = (url: string) => {
  const prefixes = ['https://images.nf.domains/ipfs/', 'https://ipfs.algonode.xyz/ipfs/', 'ipfs://']

  for (const prefix of prefixes) {
    if (url.startsWith(prefix)) {
      const result = url.split(prefix).pop() as string
      if (isIPFS.cid(result) || isIPFS.cidPath(result)) {
        return result
      }
    }
  }

  return undefined
}

export const prefetchIpfs = async (CID: string) => {
  try {
    const response = await axios.head(`https://images.nf.domains/ipfs/${CID}`)

    if (response.status === 200 && response.config.url) {
      return response
    }
  } catch (e) {
    console.info(CID, 'is not cached, trying IPFS gateway...')
  }

  const response = await axios.head(`https://ipfs.algonode.xyz/ipfs/${CID}`)

  if (response.headers['content-type'] === 'application/json') {
    const { data: metadata } = await axios.get(response.config.url as string)

    const mediaSrc = metadata?.image || metadata?.animation_url
    const mediaCID = getIpfsCID(mediaSrc)

    if (!mediaCID) {
      return response
    }

    return await axios.head(`https://ipfs.algonode.xyz/ipfs/${mediaCID}`)
  }

  return response
}

export const getIsIPFS = (url: string) => {
  return (
    url.startsWith('ipfs://') || url.includes('images.nf.domains/ipfs') || isIPFS.urlOrPath(url)
  )
}

export const getIPFSUrl = (url: string) => {
  if (url.startsWith('https://images.nf.domains/ipfs/')) {
    return url.replace('https://images.nf.domains/ipfs/', 'https://ipfs.algonode.xyz/ipfs/')
  }

  if (url.startsWith('ipfs://')) {
    return url.replace('ipfs://', 'https://ipfs.algonode.xyz/ipfs/')
  }

  return url
}

export const isValidIpfsUrl = (url: string, isGatewayUrl = false) => {
  if (isGatewayUrl) {
    return isIPFS.url(url)
  }

  const isIpfs = url.startsWith('ipfs://')
  if (isIpfs) {
    const gatewayUrl = url.replace('ipfs://', 'https://ipfs.algonode.xyz/ipfs/')
    return isIPFS.url(gatewayUrl)
  }

  return false
}
