import { CgMail } from 'react-icons/cg'
import { FaDiscord, FaTelegram, FaTwitter } from 'react-icons/fa'
import { formatUsername } from 'helpers/utilities'
import type { SocialLinkDefs } from './SocialLinks.types'

export const linkDefs: SocialLinkDefs = {
  twitter: {
    url: (username: string) => `https://twitter.com/${formatUsername(username, true)}`,
    icon: FaTwitter
  },
  email: {
    url: (email: string) => `mailto:${email}`,
    icon: CgMail
  },
  telegram: {
    url: (username: string) => `https://t.me/${username}`,
    icon: FaTelegram
  },
  discord: {
    url: (username: string) => `https://discord.com/users/${username}`,
    icon: FaDiscord
  }
}
