/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { toast, Toast as ToastType } from 'react-hot-toast'
import {
  HiOutlineCheckCircle,
  HiOutlineExclamation,
  HiOutlineXCircle,
  HiOutlineInformationCircle,
  HiX
} from 'react-icons/hi'

interface Style {
  icon: (props: any) => JSX.Element
  color: string
}

interface StyleMap {
  [key: string]: Style
}

const styles: StyleMap = {
  success: {
    icon: (props: any) => <HiOutlineCheckCircle {...props} />,
    color: 'text-green-400 dark:text-green-500'
  },
  error: {
    icon: (props: any) => <HiOutlineXCircle {...props} />,
    color: 'text-red-400 dark:text-red-500'
  },
  info: {
    icon: (props: any) => <HiOutlineInformationCircle {...props} />,
    color: 'text-blue-400 dark:text-blue-500'
  },
  warning: {
    icon: (props: any) => <HiOutlineExclamation {...props} />,
    color: 'text-amber-400 dark:text-amber-500'
  }
}

interface ToastInterface {
  t: ToastType
  content: string | JSX.Element[] | JSX.Element
}

// style can be set, or icon (with optional color) can be set, but not both
type ToastProps = (
  | {
      style: 'success' | 'error' | 'info' | 'warning'
      icon?: never
      iconColor?: never
    }
  | {
      style?: never
      icon: (props: any) => JSX.Element
      iconColor?: string
    }
) &
  ToastInterface

export default function Toast(props: ToastProps) {
  const { t, content, style, icon, iconColor = 'text-brand-400' } = props
  const Icon = ({ className }: { className: string }) =>
    style ? styles[style].icon({ className }) : icon ? icon({ className }) : null

  const color = style ? styles[style].color : iconColor

  return (
    <Transition
      appear
      show={t.visible}
      as={Fragment}
      enter="transition transform ease-out duration-300"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-gray-900 ring-opacity-5 dark:bg-gray-800">
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            {(style || !!icon) && (
              <div className="flex-shrink-0">
                <Icon className={`h-6 w-6 ${color}`} />
              </div>
            )}
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {typeof content === 'string' ? (
                <p className="text-sm font-medium text-gray-900 dark:text-gray-400">{content}</p>
              ) : (
                content
              )}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-offset-800 dark:focus:ring-gray-300"
                onClick={() => toast.dismiss(t.id)}
              >
                <span className="sr-only">Close</span>
                <HiX className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}
