import axios from 'axios'
import { captureException } from '@sentry/nextjs'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import type { ICustomError } from 'api/customError'
import type { AccountAsset } from 'types/node'

type AccountAssetParams = {
  account: string
  assetId: number
  baseUnits?: boolean
}

interface UseAccountAsset {
  params: AccountAssetParams
  options?: UseQueryOptions<AccountAsset, ICustomError>
}

export default function useAccountAsset({ params, options }: UseAccountAsset) {
  const { account, assetId, baseUnits = false } = params

  const query = useQuery<AccountAsset, ICustomError>(
    ['account-asset', account, assetId],
    async () => {
      const { data: accountAsset } = await axios.get(`/api/node/account-asset`, {
        params: {
          address: account,
          assetId,
          baseUnits
        }
      })

      return accountAsset
    },
    {
      ...options
    }
  )

  if (query.error) {
    captureException(query.error)
  }

  return query
}
