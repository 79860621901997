import { Listbox, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { AiFillBank } from 'react-icons/ai'
import { HiCheck, HiChevronUpDown } from 'react-icons/hi2'
import { classNames } from 'helpers/utilities'
import type { Sender } from './SendModal.types'

interface SelectSenderProps {
  selected: Sender
  setSelected: (sender: Sender) => void
  senders: Sender[]
}

export default function SelectSender({ selected, setSelected, senders }: SelectSenderProps) {
  useEffect(() => {
    // When senders changes, reset to default (active account)
    if (senders[0]) {
      setSelected(senders[0])
    }
  }, [senders, setSelected])

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
            Sender
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-500 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white dark:ring-transparent dark:focus:ring-brand-500">
              <span className="block truncate">
                {selected.nfd ? (
                  <span className="inline-flex items-center">
                    {selected.nfd.name} <AiFillBank className="ml-2 h-5 w-5 text-gray-500" />
                  </span>
                ) : (
                  selected.address
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <HiChevronUpDown
                  className="h-5 w-5 text-gray-400 dark:text-gray-600"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-850 dark:ring-white dark:ring-opacity-5">
                {senders.map((sender) => (
                  <Listbox.Option
                    key={sender.address}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-brand-500 text-white' : 'text-gray-900 dark:text-gray-400',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={sender}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {sender.nfd ? (
                            <span className="inline-flex items-center">
                              {sender.nfd.name}{' '}
                              <AiFillBank
                                className={classNames(
                                  active ? 'text-white/75' : 'text-gray-500',
                                  'ml-2 h-5 w-5'
                                )}
                              />
                            </span>
                          ) : (
                            sender.address
                          )}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-brand-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <HiCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
