import { immer } from 'zustand/middleware/immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type JoyrideStore = {
  runTour: boolean
  setRunTour: (runTour: boolean) => void
}

export const useJoyrideStore = create<JoyrideStore>()(
  immer(
    devtools((set) => ({
      runTour: false,

      setRunTour: (runTour: boolean) => {
        set((state) => {
          state.runTour = runTour
        })
      }
    }))
  )
)
