import { Popover, Transition } from '@headlessui/react'
import { WalletId } from '@txnlab/use-wallet-react'
import dynamic from 'next/dynamic'
import { Fragment, useMemo } from 'react'
import { HiArrowUpRight } from 'react-icons/hi2'
import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import Balance from 'components/ConnectWallet/Balance'
import User from 'components/ConnectWallet/User'
import WalletProvider from 'components/ConnectWallet/WalletProvider'
import useConnectWallet from 'components/ConnectWallet/ConnectWallet.hooks'
import CoinbasePay from 'components/CoinbasePay'
import SendModal from 'components/SendModal'
import SetPrimaryNFD from 'components/manage/SetPrimaryNFD'
import usePrimaryNfd from 'components/manage/SetPrimaryNFD/SetPrimaryNFD.hooks'
import { classNames } from 'helpers/utilities'
// import { isFirebaseSupported } from 'helpers/auth'
// import { Auth } from './_Auth'

const MenuButton = dynamic(() => import('components/ConnectWallet/MenuButton'), { ssr: false })

export default function ConnectWallet() {
  const {
    wallets,
    activeAccount,
    walletBalance,
    walletAvailableBalance,
    popperStyles,
    popperAttributes,
    setReferenceElement,
    setPopperElement,
    handleScroll,
    hasActiveAccount,
    isOpen,
    ulRef,
    showTopShadow,
    showBottomShadow,
    menuMaxHeight,
    isConnected,
    isScrollable,
    isSendModalOpen,
    setIsSendModalOpen,
    senders
  } = useConnectWallet()

  const { revLookupQuery, handleSetPrimaryNfd } = usePrimaryNfd(activeAccount?.address)

  const { data, isInitialLoading: isLoading, error } = revLookupQuery

  const showSetPrimaryNfd = useMemo(() => {
    return data && data?.length > 1
  }, [data])

  const renderSendModal = () => {
    if (!senders?.length) {
      return null
    }
    return <SendModal isOpen={isSendModalOpen} setIsOpen={setIsSendModalOpen} sender={senders} />
  }

  const renderAccountInformation = (closeMenu: () => void) => {
    if (!activeAccount?.address) {
      return null
    }

    return (
      <div className="relative px-6 py-4 bg-gray-50 text-base font-medium dark:bg-gray-900/50">
        <div className="sm:flex sm:justify-between sm:items-center">
          <User
            nfd={data?.[0]}
            isLoading={isLoading}
            error={error}
            activeAccount={activeAccount}
            address={activeAccount.address}
            onNavigateToAccount={closeMenu}
          />
          <Balance balance={walletBalance} availableBalance={walletAvailableBalance || '0'} />
        </div>
        <div className="sm:hidden mt-3 grid grid-cols-2 gap-3 align-middle">
          {showSetPrimaryNfd && (
            <SetPrimaryNFD
              className="col-span-2 sm:col-span-1"
              address={activeAccount.address}
              revLookupQuery={revLookupQuery}
              onSetPrimaryNfd={handleSetPrimaryNfd}
            />
          )}
          <ButtonLink size="sm" href="/manage" onClick={closeMenu}>
            Manage NFDs
          </ButtonLink>
          <Button
            size="sm"
            variant="primary"
            icon={HiArrowUpRight}
            onClick={() => setIsSendModalOpen(true)}
          >
            Send
          </Button>
        </div>
        <div
          className={classNames(
            showSetPrimaryNfd ? 'grid-cols-3' : 'grid-cols-2',
            'hidden mt-2 sm:grid gap-3 align-middle'
          )}
        >
          {showSetPrimaryNfd && (
            <SetPrimaryNFD
              address={activeAccount.address}
              revLookupQuery={revLookupQuery}
              onSetPrimaryNfd={handleSetPrimaryNfd}
            />
          )}
          <ButtonLink href="/manage">Manage NFDs</ButtonLink>
          <Button variant="primary" icon={HiArrowUpRight} onClick={() => setIsSendModalOpen(true)}>
            Send
          </Button>
        </div>
        <div
          className={classNames(
            showTopShadow ? 'opacity-10' : 'opacity-0',
            'absolute h-2 bg-gradient-to-t from-transparent to-black transition-opacity inset-x-0 bottom-0 translate-y-full pointer-events-none'
          )}
        />
      </div>
    )
  }

  const renderWalletProviders = () => {
    return wallets?.map((wallet) => (
      <li
        key={wallet.id}
        className={classNames(
          wallet.isActive ? 'bg-gray-100 dark:bg-gray-900' : 'bg-white dark:bg-gray-900/25',
          'flex flex-col justify-center'
        )}
      >
        <WalletProvider
          providerId={wallet.id as WalletId}
          name={wallet.metadata.name}
          icon={wallet.metadata.icon}
          isConnected={wallet.isConnected}
          isScrollable={isScrollable}
          accounts={wallet.accounts}
          activeAccount={wallet.activeAccount}
          isActive={wallet.isActive}
          handleConnectWallet={wallet.connect}
          handleDisconnectWallet={wallet.disconnect}
          handleSetActiveWallet={wallet.setActive}
          handleSelectAccount={wallet.setActiveAccount}
        />
      </li>
    ))
  }

  return (
    <>
      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-in-out duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-75"
        leave="transition ease-in-out duration-75"
        leaveFrom="opacity-75"
        leaveTo="opacity-0"
      >
        <div
          className="sm:hidden fixed inset-x-0 top-16 bg-gray-800 dark:bg-gray-975"
          style={{ height: menuMaxHeight }}
        />
      </Transition>
      <Popover>
        <MenuButton
          setReferenceElement={setReferenceElement}
          activeAccount={activeAccount}
          hasActiveAccount={hasActiveAccount}
          walletBalance={walletBalance}
        />
        <Popover.Panel
          ref={setPopperElement}
          style={popperStyles.popper}
          {...popperAttributes.popper}
        >
          {({ close }) => (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div
                className="relative bg-white flex flex-col shadow-lg w-screen sm:max-w-xl sm:rounded-b-lg border-t border-gray-100 sm:mx-4 dark:bg-gray-800 dark:border-gray-700/20"
                style={{ maxHeight: menuMaxHeight }}
              >
                <div
                  className={classNames(
                    isConnected ? 'hidden' : '',
                    showTopShadow ? 'opacity-10' : 'opacity-0',
                    'absolute z-10 h-2 bg-gradient-to-t from-transparent to-black transition-opacity inset-x-0 top-0 pointer-events-none'
                  )}
                />
                {renderAccountInformation(close)}
                {/* {isFirebaseSupported() && <Auth />} */}
                <ul
                  ref={ulRef}
                  onScroll={handleScroll}
                  role="list"
                  className={classNames(
                    isScrollable ? 'overflow-y-auto' : '',
                    'flex-1 divide-y sm:mr-0 dark:divide-gray-700/50'
                  )}
                >
                  {renderWalletProviders()}
                </ul>
                <div className="relative flex w-full justify-center items-center p-4 sm:p-6 bg-gray-50 rounded-b-lg dark:bg-gray-900/50">
                  <div
                    className={classNames(
                      showBottomShadow ? 'opacity-10' : 'opacity-0',
                      'absolute z-10 h-2 bg-gradient-to-b from-transparent to-black transition-opacity inset-x-0 top-0 -translate-y-full pointer-events-none'
                    )}
                  />
                  <CoinbasePay />
                </div>
              </div>
            </Transition>
          )}
        </Popover.Panel>
      </Popover>

      {renderSendModal()}
    </>
  )
}
