import Tooltip from 'components/Tooltip'
import { classNames } from 'helpers/utilities'
import { linkDefs } from './SocialLinks.constants'
import type { SocialLinkType } from './SocialLinks.types'

export interface SocialLinkProps {
  type: SocialLinkType
  value: string | undefined
  isLoading?: boolean
}

export default function SocialLink({ type, value, isLoading }: SocialLinkProps) {
  const className = 'flex items-center text-sm text-gray-500 rounded-md p-1 dark:text-gray-400'

  const Icon = linkDefs[type].icon

  if (isLoading) {
    return (
      <span className={classNames(className, 'opacity-25')}>
        <Icon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
      </span>
    )
  }

  if (!value) {
    return null
  }

  return (
    <Tooltip text={value} className="translate-y-2">
      <a
        href={linkDefs[type].url(value)}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(
          className,
          'hover:text-brand-500 outline-brand-500 dark:hover:text-brand-500'
        )}
      >
        <Icon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
      </a>
    </Tooltip>
  )
}
