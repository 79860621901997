/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * NFD Management Service
 * Service for querying and managing NFDs
 * OpenAPI spec version: 1.0
 */
import { customAxios } from './mutator/custom-axios';
export type NfdSearchV2View = typeof NfdSearchV2View[keyof typeof NfdSearchV2View];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSearchV2View = {
  tiny: 'tiny',
  thumbnail: 'thumbnail',
  brief: 'brief',
  full: 'full',
} as const;

export type NfdSearchV2Sort = typeof NfdSearchV2Sort[keyof typeof NfdSearchV2Sort];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSearchV2Sort = {
  createdDesc: 'createdDesc',
  timeChangedDesc: 'timeChangedDesc',
  soldDesc: 'soldDesc',
  priceAsc: 'priceAsc',
  priceDesc: 'priceDesc',
  highestSaleDesc: 'highestSaleDesc',
  saleTypeAsc: 'saleTypeAsc',
  nameAsc: 'nameAsc',
  expiresAsc: 'expiresAsc',
} as const;

export type NfdSearchV2Vproperty = typeof NfdSearchV2Vproperty[keyof typeof NfdSearchV2Vproperty];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSearchV2Vproperty = {
  discord: 'discord',
  telegram: 'telegram',
  twitter: 'twitter',
  github: 'github',
  email: 'email',
  domain: 'domain',
  nostrpubkey: 'nostrpubkey',
} as const;

export type NfdSearchV2TraitsItem = typeof NfdSearchV2TraitsItem[keyof typeof NfdSearchV2TraitsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSearchV2TraitsItem = {
  emoji: 'emoji',
  pristine: 'pristine',
  segment: 'segment',
} as const;

export type NfdSearchV2LengthItem = typeof NfdSearchV2LengthItem[keyof typeof NfdSearchV2LengthItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSearchV2LengthItem = {
  '1_letters': '1_letters',
  '2_letters': '2_letters',
  '3_letters': '3_letters',
  '4_letters': '4_letters',
  '5_letters': '5_letters',
  '6_letters': '6_letters',
  '7_letters': '7_letters',
  '8_letters': '8_letters',
  '9_letters': '9_letters',
  '10+_letters': '10+_letters',
} as const;

/**
 * State of NFD
 */
export type NfdSearchV2StateItem = typeof NfdSearchV2StateItem[keyof typeof NfdSearchV2StateItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSearchV2StateItem = {
  reserved: 'reserved',
  forSale: 'forSale',
  owned: 'owned',
  expired: 'expired',
} as const;

/**
 * Sale type of NFD
 */
export type NfdSearchV2SaleTypeItem = typeof NfdSearchV2SaleTypeItem[keyof typeof NfdSearchV2SaleTypeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSearchV2SaleTypeItem = {
  auction: 'auction',
  buyItNow: 'buyItNow',
} as const;

/**
 * Category of NFD
 */
export type NfdSearchV2CategoryItem = typeof NfdSearchV2CategoryItem[keyof typeof NfdSearchV2CategoryItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSearchV2CategoryItem = {
  curated: 'curated',
  premium: 'premium',
  common: 'common',
} as const;

export type NfdSearchV2Params = {
/**
 * name or partial match of NFD name to filter on
 */
name?: string;
category?: NfdSearchV2CategoryItem[];
saleType?: NfdSearchV2SaleTypeItem[];
state?: NfdSearchV2StateItem[];
/**
 * The parent NFD Application ID to find. Used for fetching segments of an NFD
 */
parentAppID?: number;
/**
 * Length of NFD
 */
length?: NfdSearchV2LengthItem[];
/**
 * Traits of NFD
 */
traits?: NfdSearchV2TraitsItem[];
/**
 * An Algorand account address to find all NFDs owned by that address
 */
owner?: string;
/**
 * An Algorand account address to find all NFDs reserved for that address
 */
reservedFor?: string;
/**
 * Should NFDs reserved for an account (transfers for example or unclaimed winning auctions) be excluded
 */
excludeUserReserved?: boolean;
/**
 * The start of an NFD name, fetching multiple NFDs that have that prefix
 */
prefix?: string;
/**
 * Part of an NFD name, fetching multiple NFDs that have that substring (minimum 3 characters)
 */
substring?: string;
/**
 * Verified property name to search on - specify value with vvalue
 */
vproperty?: NfdSearchV2Vproperty;
/**
 * Value to find in the vproperty field specified with the vproperty parameter
 */
vvalue?: string;
/**
 * Whether to explicitly filter on segments being locked or unlocked.  Typically only valuable when filtering on unlocked
 */
segmentLocked?: boolean;
/**
 * Whether to explicitly filter on NFD roots or segments.  True to only see roots, False to only see segments.
 */
segmentRoot?: boolean;
/**
 * Minimum price of NFD
 */
minPrice?: number;
/**
 * Maximum price of NFD
 */
maxPrice?: number;
/**
 * Minimum price of NFD Segment in USD (cents)
 */
minPriceUsd?: number;
/**
 * Maximum price of NFD Segment in USD (cents)
 */
maxPriceUsd?: number;
/**
 * Fetch NFDs that changed after the specified timestamp
 */
changedAfter?: string;
/**
 * Return only NFDs with an expiration time at or before the specified timestamp
 */
expiresBefore?: string;
/**
 * Limit the number of results returned - max 200
 */
limit?: number;
/**
 * Starting document in large list.  Fetch 1-100 [limit 100], pass offset 100 to fetch 100-200
 */
offset?: number;
/**
 * What to sort on
 */
sort?: NfdSearchV2Sort;
/**
 * View of data to return, tiny (name, owner, caAlgo, unverifiedCaAlgo only), brief (default), or full
 */
view?: NfdSearchV2View;
};

export type NfdGetNFDsForAddressesV2200 = {[key: string]: NfdRecordinaddressCollection};

export type NfdGetNFDsForAddressesV2View = typeof NfdGetNFDsForAddressesV2View[keyof typeof NfdGetNFDsForAddressesV2View];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdGetNFDsForAddressesV2View = {
  tiny: 'tiny',
  thumbnail: 'thumbnail',
  brief: 'brief',
  full: 'full',
} as const;

export type NfdGetNFDsForAddressesV2Params = {
/**
 * one or more addresses (algo or otherwise) to look up, maximum of 20 can be defined.  Specify the same query parameter multiple times for each address, ie: address=xxx&address=yyy&address=zzz
 */
address: string[];
/**
 * Limit the total number of NFDs returned - automatically changed to at least be 1 per address
 */
limit?: number;
/**
 * View of data to return, tiny (name, owner, caAlgo, unverifiedCaAlgo only [default]), thumbnail (tiny + avatar), brief, or full
 */
view?: NfdGetNFDsForAddressesV2View;
};

export type NfdUpdateImageParams = {
/**
 * Whether to return transactions to sign to update the specified image attribute within the NFD
 */
wantTxn?: boolean;
};

export type NfdSuggestView = typeof NfdSuggestView[keyof typeof NfdSuggestView];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSuggestView = {
  brief: 'brief',
  full: 'full',
} as const;

export type NfdSuggestParams = {
/**
 * Limit the number of results returned - max 40
 */
limit?: number;
/**
 * View of data to return, brief (default), or full
 */
view?: NfdSuggestView;
/**
 * Expected buyer of name.  Used for segment minting as additional check on availability of mint
 */
buyer: string;
};

export type NfdGetLookup200 = {[key: string]: NfdRecordinaddress};

export type NfdGetLookupView = typeof NfdGetLookupView[keyof typeof NfdGetLookupView];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdGetLookupView = {
  tiny: 'tiny',
  thumbnail: 'thumbnail',
  brief: 'brief',
  full: 'full',
} as const;

export type NfdGetLookupParams = {
/**
 * one or more addresses (algo or otherwise) to look up, maximum of 20 can be defined.  Specify the same query parameter multiple times for each address, ie: address=xxx&address=yyy&address=zzz
 */
address: string[];
/**
 * View of data to return, tiny (name, owner, caAlgo, unverifiedCaAlgo only [default]), thumbnail (tiny + avatar), brief, or full
 */
view?: NfdGetLookupView;
/**
 * Whether to allow unverified addresses to match (and only if its only match).  Defaults to false
 */
allowUnverified?: boolean;
};

export type NfdGetQuoteParams = {
/**
 * Address requesting price. Optional, but important when getting quote on existing nfd that is expired. If expired, then owner can renew for base price, but if not existing (but expired) owner, then the price is reverse-auction format over 28 days.
 */
buyer: string;
};

export type NfdConsensusMetrics200Item = {[key: string]: string};

export type NfdConsensusMetrics200 = {[key: string]: NfdConsensusMetrics200Item[]};

export type NfdConsensusLeadersParams = {
/**
 * should it only match against accounts matching NFD linked addresses
 */
requireNFD?: boolean;
};

export type NfdBrowseView = typeof NfdBrowseView[keyof typeof NfdBrowseView];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdBrowseView = {
  tiny: 'tiny',
  brief: 'brief',
  full: 'full',
} as const;

export type NfdBrowseSort = typeof NfdBrowseSort[keyof typeof NfdBrowseSort];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdBrowseSort = {
  createdDesc: 'createdDesc',
  timeChangedDesc: 'timeChangedDesc',
  soldDesc: 'soldDesc',
  priceAsc: 'priceAsc',
  priceDesc: 'priceDesc',
  highestSaleDesc: 'highestSaleDesc',
  saleTypeAsc: 'saleTypeAsc',
} as const;

export type NfdBrowseVproperty = typeof NfdBrowseVproperty[keyof typeof NfdBrowseVproperty];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdBrowseVproperty = {
  discord: 'discord',
  telegram: 'telegram',
  twitter: 'twitter',
  email: 'email',
  domain: 'domain',
} as const;

export type NfdBrowseTraitsItem = typeof NfdBrowseTraitsItem[keyof typeof NfdBrowseTraitsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdBrowseTraitsItem = {
  emoji: 'emoji',
  pristine: 'pristine',
  segment: 'segment',
} as const;

export type NfdBrowseLengthItem = typeof NfdBrowseLengthItem[keyof typeof NfdBrowseLengthItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdBrowseLengthItem = {
  '1_letters': '1_letters',
  '2_letters': '2_letters',
  '3_letters': '3_letters',
  '4_letters': '4_letters',
  '5_letters': '5_letters',
  '6_letters': '6_letters',
  '7_letters': '7_letters',
  '8_letters': '8_letters',
  '9_letters': '9_letters',
  '10+_letters': '10+_letters',
} as const;

/**
 * State of NFD
 */
export type NfdBrowseStateItem = typeof NfdBrowseStateItem[keyof typeof NfdBrowseStateItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdBrowseStateItem = {
  reserved: 'reserved',
  forSale: 'forSale',
  owned: 'owned',
  expired: 'expired',
} as const;

/**
 * Sale type of NFD
 */
export type NfdBrowseSaleTypeItem = typeof NfdBrowseSaleTypeItem[keyof typeof NfdBrowseSaleTypeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdBrowseSaleTypeItem = {
  auction: 'auction',
  buyItNow: 'buyItNow',
} as const;

/**
 * Category of NFD
 */
export type NfdBrowseCategoryItem = typeof NfdBrowseCategoryItem[keyof typeof NfdBrowseCategoryItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdBrowseCategoryItem = {
  curated: 'curated',
  premium: 'premium',
  common: 'common',
} as const;

export type NfdBrowseParams = {
/**
 * name or partial match of NFD name to filter on
 */
name?: string;
category?: NfdBrowseCategoryItem[];
saleType?: NfdBrowseSaleTypeItem[];
state?: NfdBrowseStateItem[];
/**
 * The parent NFD Application ID to find. Used for fetching segments of an NFD
 */
parentAppID?: number;
/**
 * Length of NFD
 */
length?: NfdBrowseLengthItem[];
/**
 * Traits of NFD
 */
traits?: NfdBrowseTraitsItem[];
/**
 * An Algorand account address to find all NFDs owned by that address
 */
owner?: string;
/**
 * An Algorand account address to find all NFDs reserved for that address
 */
reservedFor?: string;
/**
 * The start of an NFD name, fetching multiple NFDs that have that prefix
 */
prefix?: string;
/**
 * Part of an NFD name, fetching multiple NFDs that have that substring (minimum 3 characters)
 */
substring?: string;
/**
 * Verified property name to search on - specify value with vvalue
 */
vproperty?: NfdBrowseVproperty;
/**
 * Value to find in the vproperty field specified with the vproperty parameter
 */
vvalue?: string;
/**
 * Minimum price of NFD
 */
minPrice?: number;
/**
 * Maximum price of NFD
 */
maxPrice?: number;
/**
 * Fetch NFDs that changed after the specified timestamp
 */
changedAfter?: string;
/**
 * Limit the number of results returned - max 200
 */
limit?: number;
/**
 * Starting document in large list.  Fetch 1-100 [limit 100], pass offset 100 to fetch 100-200
 */
offset?: number;
/**
 * What to sort on
 */
sort?: NfdBrowseSort;
/**
 * View of data to return, tiny (name, owner, caAlgo, unverifiedCaAlgo only), brief (default), or full
 */
view?: NfdBrowseView;
};

export type NfdBadges200Item = {[key: string]: string};

export type NfdBadges200 = {[key: string]: NfdBadges200Item[]};

export type NfdAnalyticsSort = typeof NfdAnalyticsSort[keyof typeof NfdAnalyticsSort];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdAnalyticsSort = {
  timeDesc: 'timeDesc',
  priceAsc: 'priceAsc',
  priceDesc: 'priceDesc',
} as const;

export type NfdAnalyticsTraitsItem = typeof NfdAnalyticsTraitsItem[keyof typeof NfdAnalyticsTraitsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdAnalyticsTraitsItem = {
  emoji: 'emoji',
  pristine: 'pristine',
  segment: 'segment',
} as const;

export type NfdAnalyticsLengthItem = typeof NfdAnalyticsLengthItem[keyof typeof NfdAnalyticsLengthItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdAnalyticsLengthItem = {
  '1_letters': '1_letters',
  '2_letters': '2_letters',
  '3_letters': '3_letters',
  '4_letters': '4_letters',
  '5_letters': '5_letters',
  '6_letters': '6_letters',
  '7_letters': '7_letters',
  '8_letters': '8_letters',
  '9_letters': '9_letters',
  '10+_letters': '10+_letters',
} as const;

/**
 * Sale type of NFD
 */
export type NfdAnalyticsSaleTypeItem = typeof NfdAnalyticsSaleTypeItem[keyof typeof NfdAnalyticsSaleTypeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdAnalyticsSaleTypeItem = {
  auction: 'auction',
  buyItNow: 'buyItNow',
} as const;

/**
 * Category of NFD
 */
export type NfdAnalyticsCategoryItem = typeof NfdAnalyticsCategoryItem[keyof typeof NfdAnalyticsCategoryItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdAnalyticsCategoryItem = {
  curated: 'curated',
  premium: 'premium',
  common: 'common',
} as const;

export type NfdAnalyticsEventItem = typeof NfdAnalyticsEventItem[keyof typeof NfdAnalyticsEventItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdAnalyticsEventItem = {
  minted: 'minted',
  renewed: 'renewed',
  offeredForSale: 'offeredForSale',
  canceledSale: 'canceledSale',
  escrowedOffer: 'escrowedOffer',
  sold: 'sold',
  postedOffer: 'postedOffer',
} as const;

export type NfdAnalyticsParams = {
/**
 * name or partial match of NFD name to filter on
 */
name?: string;
/**
 * Buyer address to filter on
 */
buyer?: string;
/**
 * Seller address to filter on
 */
seller?: string;
/**
 * one or more events to filter on
 */
event?: NfdAnalyticsEventItem[];
/**
 * Whether the buyer property must be present in the data.  Setting this will exclude auction mint events for eg
 */
requireBuyer?: boolean;
/**
 * Whether to add a currentOwner property to each event with the 'current' owner of the NFD referenced by that event
 */
includeOwner?: boolean;
/**
 * Whether to exclude events where NFDomains is the seller.  If set to true, and filtering on 'sold' event for eg, returned items will will be secondary sales only.
 */
excludeNFDAsSeller?: boolean;
category?: NfdAnalyticsCategoryItem[];
saleType?: NfdAnalyticsSaleTypeItem[];
/**
 * Length of NFD
 */
length?: NfdAnalyticsLengthItem[];
/**
 * Traits of NFD
 */
traits?: NfdAnalyticsTraitsItem[];
/**
 * The parent NFD Application ID to find. Used for fetching segments of an NFD
 */
parentAppID?: number;
/**
 * Minimum price of NFD
 */
minPrice?: number;
/**
 * Maximum price of NFD
 */
maxPrice?: number;
/**
 * Fetch analytics events that occurred only after the specified time
 */
afterTime?: string;
/**
 * Limit the number of results returned - max 200
 */
limit?: number;
/**
 * Starting document in large list.  Fetch 1-100 [limit 100], pass offset 100 to fetch 100-200
 */
offset?: number;
/**
 * What to sort on - descending timestamp is default
 */
sort?: NfdAnalyticsSort;
};

export type NfdActivitySort = typeof NfdActivitySort[keyof typeof NfdActivitySort];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdActivitySort = {
  timeDesc: 'timeDesc',
  timeAsc: 'timeAsc',
} as const;

export type NfdActivityType = typeof NfdActivityType[keyof typeof NfdActivityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdActivityType = {
  changes: 'changes',
} as const;

export type NfdActivityParams = {
/**
 * Name(s) of NFDs to fetch activity for.  Specify the same query parameter multiple times for each nane, ie: name=xxx&name=yyy&name=zzz")
 */
name: string[];
/**
 * type of activity to retrieve
 */
type?: NfdActivityType;
/**
 * Fetch events that occurred only after the specified time
 */
afterTime?: string;
/**
 * Limit the number of results returned, per NFD - max 50
 */
limit?: number;
/**
 * What to sort on - descending timestamp is default
 */
sort?: NfdActivitySort;
};

export type NfdGetNFDView = typeof NfdGetNFDView[keyof typeof NfdGetNFDView];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdGetNFDView = {
  tiny: 'tiny',
  brief: 'brief',
  full: 'full',
} as const;

export type NfdGetNFDParams = {
/**
 * View of data to return, tiny, brief (default), or full
 */
view?: NfdGetNFDView;
/**
 * Use if polling waiting for state change - causes notFound to return as 204 instead of 404.  Should only be used when waiting for an NFD to transition from not-existing to being reserved for user to claim
 */
poll?: boolean;
/**
 * Set to true to return a never-cached result.  Use sparingly and only during certain 'NFD already exists' UX state transitions.
 */
nocache?: boolean;
};

export interface VersionResponseBody {
  appVersion: string;
  contractVersion: string;
}

/**
 * Data to use as part of verification
 */
export interface VerifyRequestResponseBody {
  /** Challenge to be used as part of verification process, with use specific to each field */
  challenge: string;
  /** ID of challenge, must be used in subsequent confirmation call but may be blank */
  id: string;
  /** If set, no confirmation is required, the verify call was sufficient */
  validated?: boolean;
}

/**
 * User defined field name to verify
 */
export type VerifyRequestRequestBodyFieldToVerify = typeof VerifyRequestRequestBodyFieldToVerify[keyof typeof VerifyRequestRequestBodyFieldToVerify];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerifyRequestRequestBodyFieldToVerify = {
  twitter: 'twitter',
  github: 'github',
  domain: 'domain',
  email: 'email',
  avatar: 'avatar',
  banner: 'banner',
} as const;

export interface VerifyRequestRequestBody {
  /** User defined field name to verify */
  fieldToVerify: VerifyRequestRequestBodyFieldToVerify;
  name: string;
  /** Sender of transaction - needs to be owner of NFD */
  sender: string;
}

/**
 * Whether verification was successful
 */
export interface VerifyConfirmResponseBody {
  confirmed: boolean;
}

export interface VerifyConfirmRequestBody {
  /** Challenge value, optional depending on verification type */
  challenge?: string;
}

export interface UpdatePartialRequestBody {
  properties: NFDProperties;
  /** Address that will be signing the returned transactions.  Should be owner of NFD */
  sender: string;
}

/**
 * TwitterRecord contains information about an NFD w/ Verified Twitter account and basic info on its twitter metrics
 */
export interface TwitterRecord {
  followers: number;
  following: number;
  nfd: Nfd;
  timeChanged: string;
  tweets: number;
  twitterHandle: string;
}

export type TotalsOKResponseBodySoldTotals = {
  day?: number;
  lifetime?: number;
  month?: number;
  week?: number;
};

export type TotalsOKResponseBodySegmentTotals = {
  day?: number;
  lifetime?: number;
  month?: number;
  week?: number;
};

export type TotalsOKResponseBodyMintedTotals = {
  day?: number;
  lifetime?: number;
  month?: number;
  week?: number;
};

/**
 * Contract versions
 */
export type TotalsOKResponseBodyContractTotalsMajorVersions = {[key: string]: number};

/**
 * Contract versions
 */
export type TotalsOKResponseBodyContractTotalsAllVersions = {[key: string]: number};

export type TotalsOKResponseBodyContractTotals = {
  /** Contract versions */
  allVersions?: TotalsOKResponseBodyContractTotalsAllVersions;
  /** Contract versions */
  majorVersions?: TotalsOKResponseBodyContractTotalsMajorVersions;
};

export interface TotalsOKResponseBody {
  contractTotals: TotalsOKResponseBodyContractTotals;
  /** NFDs that have expired */
  expiredTotal: number;
  forSale: number;
  /** Not returned, used in tagging for response to indicate if-none-match etag matched */
  'match-check'?: string;
  mintedTotals: TotalsOKResponseBodyMintedTotals;
  segmentTotals: TotalsOKResponseBodySegmentTotals;
  soldTotals: TotalsOKResponseBodySoldTotals;
  total: number;
  totalSegments: number;
  uniqueOwners: number;
}

export interface SetPrimaryAddressRequestBody {
  /** The address (already linked) to make primary - swapping into position if necessary */
  address: string;
  /** Address that will be signing the returned transactions.  Should be owner of NFD */
  sender: string;
}

export interface SendToVaultRequestBody {
  /** Base amount (in base units of specified asset - so decimals must be considered) of asset to send.   If multiple assets specified, amount is ignored and ALL of each are sent */
  amount?: number;
  /** String version of Base amount (in base units of specified asset - so decimals must be considered) of asset to send.  This is used to work around JS deficiencies with bigint */
  amountStr?: string;
  /** Algorand ASA IDs to transfer (and opt-in inside vault if necessary) - use asset 0 to send ALGO.  Specifying multiple assets means ALL of each are sent and amount is ignored. 13 is max assets that can be specified if they're being sent (2 for MBR payments, 2 for opt-in txns (8+4 asset opt-ins), 12 asset transfers).  If opt-in only then 64 is maximum (1 MBR per 8 assets, 8 assets per txn * 8 txns) */
  assets: number[];
  /** Optional note to include in asset send transaction */
  note?: string;
  /** Whether to only opt-in to the asset, instead of including asset transfer txn */
  optInOnly: boolean;
  sender: string;
}

/**
 * Type of receiver, 'account', or 'nfdVault'
 */
export type SendFromVaultRequestBodyReceiverType = typeof SendFromVaultRequestBodyReceiverType[keyof typeof SendFromVaultRequestBodyReceiverType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SendFromVaultRequestBodyReceiverType = {
  account: 'account',
  nfdVault: 'nfdVault',
} as const;

export interface SendFromVaultRequestBody {
  /** Base amount (in base units of specified asset - so decimals must be considered) of asset to send.   If multiple assets are specified, amount is should be 0 as ALL of each are sent and closed out */
  amount?: number;
  /** String version of Base amount (in base units of specified asset - so decimals must be considered) of asset to send.  This is used to work around JS deficiencies with bigint */
  amountStr?: string;
  /** Algorand ASA IDs to transfer FROM vault - use asset 0 to send ALGO.  Specifying multiple assets means ALL of each are sent and amount is ignored. If receiver is a vault and needs to opt-in, then need MBR/opt-in pairs (5 pairs - 8  opt-ins each - 40 assets), then 6 send calls of 7 assets w/ 5 at end for total of 40.  If receiver is already opted-in, then 112 (7 per txn, 16 tnxs) is max. */
  assets: number[];
  /** Optional note to include in asset send transaction */
  note?: string;
  /** Algorand account or NFD Name (if vault receiver) the asset(s) should be sent to */
  receiver: string;
  /** Specifies that the receiver account is something the caller can sign for. If specified, then opt-in transactions it signs may be included */
  receiverCanSign?: boolean;
  /** Type of receiver, 'account', or 'nfdVault' */
  receiverType?: SendFromVaultRequestBodyReceiverType;
  /** Sender of transaction, must be NFD owner */
  sender: string;
}

export interface SegmentLockRequestBody {
  /** Whether to lock (true), or unlock (false) */
  lock: boolean;
  /** amount users minting off the segment have to pay to mint in USD (in cents - ie: 300 = $3.00). Set to 0 if locking */
  sellAmount: number;
  /** Sender of transaction - needs to be owner of NFD */
  sender: string;
}

export interface RescindOfferRequestBody {
  /** Sender of transaction - needs to be owner of NFD */
  sender: string;
}

export interface RenewRequestBody {
  /** Offer price in ALGO. Expiration time set prorated based on amount paid vs fixed per-year cost when years argument is used */
  algoOffer?: number;
  /** Address paying/signing for minting transaction */
  buyer: string;
  name: string;
  /** Number of years until expiration, 1 yr minimum, 20 max, extending renewal date - sets price in multiples of 1 year. If not current owner and expired, only 1 year is allowed */
  years?: number;
}

export interface RateLimited {
  limit: number;
  secsRemaining: number;
}

export interface PurchaseRequestBody {
  buyer: string;
  /** Amount in microAlgo to purchase/claim the NFD for */
  offer: number;
}

export interface PostOfferToOwnerRequestBody {
  /** Note to pass along to the NFD owner.  Must be provided but can be blank */
  note: string;
  /** Amount in microAlgo being offered to the NFD owner */
  offer: number;
  sender: string;
}

export interface OfferRequestBody {
  /** Amount in microAlgo to sell the NFD for.  1 ALGO is 1,000,000 microAlgo. 100 million maximum. */
  offer: number;
  /** Whether to pay the offer amount + fees to the account the NFD is reserved for.  Equivalent to 'gifting' the NFD */
  payReceiver?: boolean;
  reservedFor?: string;
  /** Sender of transaction - needs to be owner of NFD */
  sender: string;
}

export interface NfdV2SearchRecords {
  /** Not returned, used in tagging for response to indicate if-none-match etag matched */
  'match-check'?: string;
  nfds: NfdRecordCollection;
  /** total number of results, with data containing paged amount based on offset/limit */
  total: number;
}

export type NfdRecordinaddressCollection = NfdRecordinaddress[];

/**
 * Unverified (non-algo) Crypto addresses for this NFD
 */
export type NfdRecordinaddressUnverifiedCa = {[key: string]: string[]};

export type NfdRecordinaddressState = typeof NfdRecordinaddressState[keyof typeof NfdRecordinaddressState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordinaddressState = {
  available: 'available',
  minting: 'minting',
  reserved: 'reserved',
  forSale: 'forSale',
  owned: 'owned',
  expired: 'expired',
} as const;

export type NfdRecordinaddressSaleType = typeof NfdRecordinaddressSaleType[keyof typeof NfdRecordinaddressSaleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordinaddressSaleType = {
  auction: 'auction',
  buyItNow: 'buyItNow',
} as const;

export type NfdRecordinaddressCategory = typeof NfdRecordinaddressCategory[keyof typeof NfdRecordinaddressCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordinaddressCategory = {
  curated: 'curated',
  premium: 'premium',
  common: 'common',
} as const;

/**
 * NFD contains all known information about an NFD record
 */
export interface NfdRecordinaddress {
  /** NFD Application ID */
  appID?: number;
  /** NFD ASA ID */
  asaID?: number;
  /** Whether the verified Avatar set in this NFD is newer (arc19) then is set into the NFD. This will only be present on direct NFD fetch and if true */
  avatarOutdated?: boolean;
  /** Verified Algorand addresses for this NFD */
  caAlgo?: string[];
  /** Cache-Control header */
  'cache-control'?: string;
  category?: NfdRecordinaddressCategory;
  /** Round this data was last fetched from */
  currentAsOfBlock?: number;
  /** account wallets should send funds to - precedence is: caAlgo[0], unverifiedCaAlgo[0], owner */
  depositAccount?: string;
  /** ETag */
  etag?: string;
  expired?: boolean;
  /** Not returned, used in tagging for response to indicate if-none-match etag matched */
  'match-check'?: string;
  /** Tags set by the system for tracking/analytics */
  metaTags?: string[];
  name: string;
  nfdAccount?: string;
  /** Owner of NFD */
  owner?: string;
  /** NFD Application ID of Parent if this is a segment */
  parentAppID?: number;
  properties?: NFDProperties;
  /** Reserved owner of NFD */
  reservedFor?: string;
  saleType?: NfdRecordinaddressSaleType;
  /** amount NFD is being sold for (microAlgos) */
  sellAmount?: number;
  /** RecipientUid of NFD sales */
  seller?: string;
  sigNameAddress?: string;
  state?: NfdRecordinaddressState;
  /** Tags assigned to this NFD */
  tags?: string[];
  timeChanged?: string;
  timeCreated?: string;
  timeExpires?: string;
  timePurchased?: string;
  /** Unverified (non-algo) Crypto addresses for this NFD */
  unverifiedCa?: NfdRecordinaddressUnverifiedCa;
  /** Unverified Algorand addresses for this NFD */
  unverifiedCaAlgo?: string[];
}

/**
 * Unverified (non-algo) Crypto addresses for this NFD
 */
export type NfdRecordResponseFullUnverifiedCa = {[key: string]: string[]};

export type NfdRecordResponseFullState = typeof NfdRecordResponseFullState[keyof typeof NfdRecordResponseFullState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordResponseFullState = {
  available: 'available',
  minting: 'minting',
  reserved: 'reserved',
  forSale: 'forSale',
  owned: 'owned',
  expired: 'expired',
} as const;

export type NfdRecordResponseFullSaleType = typeof NfdRecordResponseFullSaleType[keyof typeof NfdRecordResponseFullSaleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordResponseFullSaleType = {
  auction: 'auction',
  buyItNow: 'buyItNow',
} as const;

export type NfdRecordResponseFullCategory = typeof NfdRecordResponseFullCategory[keyof typeof NfdRecordResponseFullCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordResponseFullCategory = {
  curated: 'curated',
  premium: 'premium',
  common: 'common',
} as const;

/**
 * NFD contains all known information about an NFD record (full view)
 */
export interface NfdRecordResponseFull {
  /** NFD Application ID */
  appID?: number;
  /** NFD ASA ID */
  asaID?: number;
  /** Whether the verified Avatar set in this NFD is newer (arc19) then is set into the NFD. This will only be present on direct NFD fetch and if true */
  avatarOutdated?: boolean;
  /** Verified Algorand addresses for this NFD */
  caAlgo?: string[];
  /** Cache-Control header */
  'cache-control'?: string;
  category?: NfdRecordResponseFullCategory;
  /** Round this data was last fetched from */
  currentAsOfBlock?: number;
  /** account wallets should send funds to - precedence is: caAlgo[0], unverifiedCaAlgo[0], owner */
  depositAccount?: string;
  /** ETag */
  etag?: string;
  expired?: boolean;
  /** Not returned, used in tagging for response to indicate if-none-match etag matched */
  'match-check'?: string;
  /** Tags set by the system for tracking/analytics */
  metaTags?: string[];
  name: string;
  nfdAccount?: string;
  /** Owner of NFD */
  owner?: string;
  /** NFD Application ID of Parent if this is a segment */
  parentAppID?: number;
  properties?: NFDProperties;
  /** Reserved owner of NFD */
  reservedFor?: string;
  saleType?: NfdRecordResponseFullSaleType;
  /** amount NFD is being sold for (microAlgos) */
  sellAmount?: number;
  /** RecipientUid of NFD sales */
  seller?: string;
  sigNameAddress?: string;
  state?: NfdRecordResponseFullState;
  /** Tags assigned to this NFD */
  tags?: string[];
  timeChanged?: string;
  timeCreated?: string;
  timeExpires?: string;
  timePurchased?: string;
  /** Unverified (non-algo) Crypto addresses for this NFD */
  unverifiedCa?: NfdRecordResponseFullUnverifiedCa;
  /** Unverified Algorand addresses for this NFD */
  unverifiedCaAlgo?: string[];
}

/**
 * DonationListV2ResponseBody is the result type for an array of NfdRecordResponse (full view)
 */
export type NfdRecordResponseFullCollection = NfdRecordResponseFull[];

export type NfdRecordCollection = NfdRecord[];

/**
 * Unverified (non-algo) Crypto addresses for this NFD
 */
export type NfdRecordUnverifiedCa = {[key: string]: string[]};

export type NfdRecordState = typeof NfdRecordState[keyof typeof NfdRecordState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordState = {
  available: 'available',
  minting: 'minting',
  reserved: 'reserved',
  forSale: 'forSale',
  owned: 'owned',
  expired: 'expired',
} as const;

export type NfdRecordSaleType = typeof NfdRecordSaleType[keyof typeof NfdRecordSaleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordSaleType = {
  auction: 'auction',
  buyItNow: 'buyItNow',
} as const;

export type NfdRecordCategory = typeof NfdRecordCategory[keyof typeof NfdRecordCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdRecordCategory = {
  curated: 'curated',
  premium: 'premium',
  common: 'common',
} as const;

/**
 * Verified properties
 */
export type NFDPropertiesVerified = {[key: string]: string};

/**
 * User properties
 */
export type NFDPropertiesUserDefined = {[key: string]: string};

/**
 * Internal properties
 */
export type NFDPropertiesInternal = {[key: string]: string};

/**
 * NFDProperties contains the expanded metadata stored within an NFD contracts' global-state
 */
export interface NFDProperties {
  /** Internal properties */
  internal?: NFDPropertiesInternal;
  /** User properties */
  userDefined?: NFDPropertiesUserDefined;
  /** Verified properties */
  verified?: NFDPropertiesVerified;
}

export interface NfdRecord {
  /** NFD Application ID */
  appID?: number;
  /** NFD ASA ID */
  asaID?: number;
  /** Whether the verified Avatar set in this NFD is newer (arc19) then is set into the NFD. This will only be present on direct NFD fetch and if true */
  avatarOutdated?: boolean;
  /** Verified Algorand addresses for this NFD */
  caAlgo?: string[];
  category?: NfdRecordCategory;
  /** Round this data was last fetched from */
  currentAsOfBlock?: number;
  /** account wallets should send funds to - precedence is: caAlgo[0], unverifiedCaAlgo[0], owner */
  depositAccount?: string;
  expired?: boolean;
  /** Not returned, used in tagging for response to indicate if-none-match etag matched */
  'match-check'?: string;
  /** Tags set by the system for tracking/analytics */
  metaTags?: string[];
  name: string;
  nfdAccount?: string;
  /** Owner of NFD */
  owner?: string;
  /** NFD Application ID of Parent if this is a segment */
  parentAppID?: number;
  properties?: NFDProperties;
  /** Reserved owner of NFD */
  reservedFor?: string;
  saleType?: NfdRecordSaleType;
  /** amount NFD is being sold for (microAlgos) */
  sellAmount?: number;
  /** RecipientUid of NFD sales */
  seller?: string;
  sigNameAddress?: string;
  state?: NfdRecordState;
  /** Tags assigned to this NFD */
  tags?: string[];
  timeChanged?: string;
  timeCreated?: string;
  timeExpires?: string;
  timePurchased?: string;
  /** Unverified (non-algo) Crypto addresses for this NFD */
  unverifiedCa?: NfdRecordUnverifiedCa;
  /** Unverified Algorand addresses for this NFD */
  unverifiedCaAlgo?: string[];
}

/**
 * NFDAnalyticRecord is an NFD Analytics record in a time-series collection, with timestamp, price, and event data for that point in time.
 */
export interface NFDAnalyticRecord {
  data?: NFDAnalyticEvent;
  /** price of event in microAlgos */
  price?: number;
  /** price of event in USD */
  priceUsd?: number;
  timestamp?: string;
}

export interface NFDAnalyticRecords {
  /** Not returned, used in tagging for response to indicate if-none-match etag matched */
  'match-check'?: string;
  results: NFDAnalyticRecord[];
  /** total number of results, with data containing paged amount based on offset/limit */
  total: number;
}

export type NFDAnalyticEventSaleType = typeof NFDAnalyticEventSaleType[keyof typeof NFDAnalyticEventSaleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NFDAnalyticEventSaleType = {
  auction: 'auction',
  buyItNow: 'buyItNow',
} as const;

export type NFDAnalyticEventEvent = typeof NFDAnalyticEventEvent[keyof typeof NFDAnalyticEventEvent];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NFDAnalyticEventEvent = {
  minted: 'minted',
  renewed: 'renewed',
  offeredForSale: 'offeredForSale',
  canceledSale: 'canceledSale',
  escrowedOffer: 'escrowedOffer',
  sold: 'sold',
  postedOffer: 'postedOffer',
} as const;

export type NFDAnalyticEventCategory = typeof NFDAnalyticEventCategory[keyof typeof NFDAnalyticEventCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NFDAnalyticEventCategory = {
  curated: 'curated',
  premium: 'premium',
  common: 'common',
} as const;

/**
 * NFDAnalyticEvent contains a price history record for a point in time of an NFD
 */
export interface NFDAnalyticEvent {
  block?: number;
  buyer?: string;
  /** extra amount paid to cover minimum balance requirements - add to price to determine total amount paid */
  carryCost?: number;
  category?: NFDAnalyticEventCategory;
  /** NFD current owner - if set via includeOwner property */
  currentOwner?: string;
  event?: NFDAnalyticEventEvent;
  groupID?: string;
  metaTags?: string[];
  /** NFD Name */
  name?: string;
  /** new expiration time set from minting or renewing */
  newExpTime?: string;
  note?: string;
  /** price for one year mint/renew */
  oneYearRenewalPrice?: number;
  saleType?: NFDAnalyticEventSaleType;
  seller?: string;
}

/**
 * Changed properties
 */
export type NFDActivityChanges = {[key: string]: string};

/**
 * NFDActivity contains the property changes made in a particular NFD contract update call
 */
export interface NFDActivity {
  /** Algorand Block number of change */
  block: number;
  'cache-control'?: string;
  /** Changed properties */
  changes?: NFDActivityChanges;
  etag?: string;
  /** Not returned, used in tagging for response to indicate if-none-match etag matched */
  'match-check'?: string;
  /** NFD Name */
  name: string;
  timeChanged: string;
}

/**
 * Unverified (non-algo) Crypto addresses for this NFD
 */
export type NfdUnverifiedCa = {[key: string]: string[]};

export type NfdState = typeof NfdState[keyof typeof NfdState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdState = {
  available: 'available',
  minting: 'minting',
  reserved: 'reserved',
  forSale: 'forSale',
  owned: 'owned',
  expired: 'expired',
} as const;

export type NfdSaleType = typeof NfdSaleType[keyof typeof NfdSaleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdSaleType = {
  auction: 'auction',
  buyItNow: 'buyItNow',
} as const;

export type NfdCategory = typeof NfdCategory[keyof typeof NfdCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NfdCategory = {
  curated: 'curated',
  premium: 'premium',
  common: 'common',
} as const;

/**
 * NFD contains all known information about an NFD record
 */
export interface Nfd {
  /** NFD Application ID */
  appID?: number;
  /** NFD ASA ID */
  asaID?: number;
  /** Whether the verified Avatar set in this NFD is newer (arc19) then is set into the NFD. This will only be present on direct NFD fetch and if true */
  avatarOutdated?: boolean;
  /** Verified Algorand addresses for this NFD */
  caAlgo?: string[];
  /** Cache-Control header */
  'cache-control'?: string;
  category?: NfdCategory;
  /** Round this data was last fetched from */
  currentAsOfBlock?: number;
  /** account wallets should send funds to - precedence is: caAlgo[0], unverifiedCaAlgo[0], owner */
  depositAccount?: string;
  /** ETag */
  etag?: string;
  expired?: boolean;
  /** Tags set by the system for tracking/analytics */
  metaTags?: string[];
  name: string;
  nfdAccount?: string;
  /** Owner of NFD */
  owner?: string;
  /** NFD Application ID of Parent if this is a segment */
  parentAppID?: number;
  properties?: NFDProperties;
  /** Reserved owner of NFD */
  reservedFor?: string;
  saleType?: NfdSaleType;
  /** amount NFD is being sold for (microAlgos) */
  sellAmount?: number;
  /** RecipientUid of NFD sales */
  seller?: string;
  sigNameAddress?: string;
  state?: NfdState;
  /** Tags assigned to this NFD */
  tags?: string[];
  timeChanged?: string;
  timeCreated?: string;
  timeExpires?: string;
  timePurchased?: string;
  /** Unverified (non-algo) Crypto addresses for this NFD */
  unverifiedCa?: NfdUnverifiedCa;
  /** Unverified Algorand addresses for this NFD */
  unverifiedCaAlgo?: string[];
}

export interface MintRequestBody {
  /** Offer price in ALGO. Expiration time set prorated based on amount paid vs fixed per-year cost when years argument is used */
  algoOffer?: number;
  /** Address paying/signing for minting transaction */
  buyer: string;
  name: string;
  /** Address NFD is being minted for if not buyer */
  reservedFor?: string;
  /** Number of years until expiration, 1 yr minimum, 20 max, extending renewal date - sets price in multiples of 1 year */
  years?: number;
}

export interface LinkAddressRequestBody {
  /** Address(es) to link to the NFD (must be able to sign for each) */
  address: string[];
  /** Address that will be signing the returned transactions.  Should be owner of NFD */
  sender: string;
}

/**
 * Whether NFD App ID is for authentic NFD
 */
export interface IsValidNFDResponseBody {
  isValid: boolean;
  message: string;
  name: string;
  sigNameAddress?: string;
}

/**
 * Whether NFD NFT ASA ID is for authentic NFD
 */
export interface IsValidASAResponseBody {
  appID: number;
  isValid: boolean;
  message: string;
  name: string;
}

export interface GetQuoteResponseBody {
  /** extra needed to cover MBR (if necessary) - price already includes this amount */
  carryCost: number;
  /** whether the nfd exists. if so, cost is renewal, otherwise mint (including carry) */
  exists: boolean;
  /** if nfd is in auction pricing for given buyer */
  inAuction: boolean;
  /** whether the (existing) nfd is expired */
  isExpired: boolean;
  /** price in microAlgo for 1 year w/ carry cost (if needs minted) added in. subtract carry cost for per-year cost */
  price: number;
}

export interface Error {
  /** Is the error a server-side fault? */
  fault: boolean;
  /** ID is a unique identifier for this particular occurrence of the problem. */
  id: string;
  /** Message is a human-readable explanation specific to this occurrence of the problem. */
  message: string;
  /** Name is the name of this class of errors. */
  name: string;
  /** Is the error temporary? */
  temporary: boolean;
  /** Is the error a timeout? */
  timeout: boolean;
}

/**
 * Donation contains basic information about donation totals to specific addresses from accounts related to an NFD
 */
export interface Donation {
  /** Sender or Receiver Algorand address depending on request */
  address: string;
  /** Total donation in microAlgos */
  total: number;
}

export interface ContractLockRequestBody {
  /** Whether to lock (true), or unlock (false) */
  lock: boolean;
  /** Sender of transaction - needs to be owner of NFD */
  sender: string;
}

/**
 * ConsensusRecord contains information about an account that participated in consensus
 */
export interface ConsensusRecord {
  account: string;
  lastPropBlock?: number;
  lastPropTime?: string;
  lastVoteBlock?: number;
  lastVoteTime?: string;
  linkType?: string;
  ownerAccount: string;
  pctOfOnline: number;
  proposals?: number;
  rank?: number;
  votes?: number;
}




// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


  /**
 * YAML document containing the API swagger definition
 * @summary Download ./pubfiles/openapi3.yaml
 */
export const infoInfoOpenapi3Yaml = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<void>(
      {url: `/info/openapi3.yaml`, method: 'get'
    },
      options);
    }
  
/**
 * Returns version information for the service
 * @summary version info
 */
export const infoVersion = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<VersionResponseBody>(
      {url: `/info/version`, method: 'get'
    },
      options);
    }
  
/**
 * Get a specific NFD by name or by its application ID
 * @summary Get a specific NFD by name or by its application ID
 */
export const nfdGetNFD = (
    nameOrID: string,
    params?: NfdGetNFDParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdRecord | void>(
      {url: `/nfd/${nameOrID}`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Fetch change activity for an NFD, specifically general 'block-level' deltas for an NFD
 * @summary Fetch change activity for an NFD
 */
export const nfdActivity = (
    params: NfdActivityParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NFDActivity[]>(
      {url: `/nfd/activity`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Fetch NFD analytics via various filters
 * @summary Fetch NFD analytics via various filters
 */
export const nfdAnalytics = (
    params?: NfdAnalyticsParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NFDAnalyticRecords>(
      {url: `/nfd/analytics`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Fetch badge information (ie: donations) for an NFD
 * @summary Fetch badge information (donations/etc) for an NFD
 */
export const nfdBadges = (
    name: string,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdBadges200>(
      {url: `/nfd/badges/${name}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary Browse NFDs via various filters
 */
export const nfdBrowse = (
    params?: NfdBrowseParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdRecordCollection>(
      {url: `/nfd/browse`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Get top consensus leaders
 * @summary consensusLeaders nfd
 */
export const nfdConsensusLeaders = (
    params?: NfdConsensusLeadersParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<ConsensusRecord[]>(
      {url: `/nfd/consensus/leaders`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Get general metrics about Algorand consensus
 * @summary consensusMetrics nfd
 */
export const nfdConsensusMetrics = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdConsensusMetrics200>(
      {url: `/nfd/consensus/metrics`, method: 'get'
    },
      options);
    }
  
/**
 * Lock/Unlock an NFD contract - if locked, the contract can never being modified until unlocked again by the owner.
 * @summary contractLock nfd
 */
export const nfdContractLock = (
    name: string,
    contractLockRequestBody: ContractLockRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/contract/lock/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: contractLockRequestBody
    },
      options);
    }
  
/**
 * Request upgrade of a pre 2.11 NFD to 2.11 (going no further)
 * @summary contractUpgrade nfd
 */
export const nfdContractUpgrade = (
    name: string,
    rescindOfferRequestBody: RescindOfferRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/contract/upgrade/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: rescindOfferRequestBody
    },
      options);
    }
  
/**
 * Fetch donation activity for an NFD, totalling amounts sent 'to' designated donation accounts
 * @summary donations nfd
 */
export const nfdDonations = (
    name: string,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<Donation[]>(
      {url: `/nfd/donations/${name}`, method: 'get'
    },
      options);
    }
  
/**
 * get price / carry cost to mint or rewnew an NFD (if existing)
 * @summary getQuote nfd
 */
export const nfdGetQuote = (
    name: string,
    params: NfdGetQuoteParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<GetQuoteResponseBody>(
      {url: `/nfd/getQuote/${name}`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Determines if specified NFD Application ID is authentic
 * @summary isValidNFD nfd
 */
export const nfdIsValidNFD = (
    appID: number,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<IsValidNFDResponseBody>(
      {url: `/nfd/isValid/${appID}`, method: 'get'
    },
      options);
    }
  
/**
 * Determines if specified NFD NFT ASA ID is authentic NFD
 * @summary isValidASA nfd
 */
export const nfdIsValidASA = (
    asaID: number,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<IsValidASAResponseBody>(
      {url: `/nfd/isValidASA/${asaID}`, method: 'get'
    },
      options);
    }
  
/**
 * Link one or more addresses to an NFD, adding to the reverse-address lookups as well as to this NFD.  Sender must be owner, and each added address must be able to be signed for.
 * @summary linkAddress nfd
 */
export const nfdLinkAddress = (
    name: string,
    linkAddressRequestBody: LinkAddressRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/links/addAddress/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: linkAddressRequestBody
    },
      options);
    }
  
/**
 * UnLink one or more addresses to an NFD, adding to the reverse-address lookups as well as to this NFD.  Sender must be owner, and each added address must be able to be signed for.
 * @summary unlinkAddress nfd
 */
export const nfdUnlinkAddress = (
    name: string,
    linkAddressRequestBody: LinkAddressRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/links/removeAddress/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: linkAddressRequestBody
    },
      options);
    }
  
/**
 * Set which of the currently verified addresses should be the first in the list (swapping positions as necessary)
 * @summary setPrimaryAddress nfd
 */
export const nfdSetPrimaryAddress = (
    name: string,
    setPrimaryAddressRequestBody: SetPrimaryAddressRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/links/setPrimaryAddress/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: setPrimaryAddressRequestBody
    },
      options);
    }
  
/**
 * Set the specified NFD as the primary NFD to return for the specified address via its reverse lookup
 * @summary setPrimaryNFD nfd
 */
export const nfdSetPrimaryNFD = (
    name: string,
    setPrimaryAddressRequestBody: SetPrimaryAddressRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/links/setPrimaryNFD/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: setPrimaryAddressRequestBody
    },
      options);
    }
  
/**
 * Get the primary NFD for an address.  Must be verified address, or if allowUnverified is set, it may match against an unverified address
 * @summary Reverse Address lookup with results returned per address
 */
export const nfdGetLookup = (
    params: NfdGetLookupParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdGetLookup200>(
      {url: `/nfd/lookup`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Mint a new NFD, with user buying specified NFD and paying for a prorated amount of time based on its yearly price.
 * @summary mint nfd
 */
export const nfdMint = (
    mintRequestBody: MintRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string | void>(
      {url: `/nfd/mint`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: mintRequestBody
    },
      options);
    }
  
/**
 * Returns NameSig address for an NFD name (usable for V1 only)
 * @summary getNameSig nfd
 */
export const nfdGetNameSig = (
    name: string,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/nameSig/${name}`, method: 'get'
    },
      options);
    }
  
/**
 * Offer up an NFD for sale - specifying price and optionally an address it is reserved for.
 * @summary offer nfd
 */
export const nfdOffer = (
    name: string,
    offerRequestBody: OfferRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/offer/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: offerRequestBody
    },
      options);
    }
  
/**
 * Post an offer to buy to the owner of an NFD, offering up a particular amount with optional note for them to consider
 * @summary postOfferToOwner nfd
 */
export const nfdPostOfferToOwner = (
    name: string,
    postOfferToOwnerRequestBody: PostOfferToOwnerRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/postOfferToOwner/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postOfferToOwnerRequestBody
    },
      options);
    }
  
/**
 * Purchase an NFD for sale - specifying buyer (to sign transaction) and price
 * @summary purchase nfd
 */
export const nfdPurchase = (
    name: string,
    purchaseRequestBody: PurchaseRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/purchase/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: purchaseRequestBody
    },
      options);
    }
  
/**
 * Renew or extend the expiration of an NFD.  If current owner, renews at base price. If other owner, can take ownership but goes through reverse auction process for first 28 days where price drops to base price - with price being for 1 year
 * @summary renew nfd
 */
export const nfdRenew = (
    renewRequestBody: RenewRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/renew`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: renewRequestBody
    },
      options);
    }
  
/**
 * Rescind offer of sale. Claiming NFD back for self, and removing it for sale.
 * @summary rescindOffer nfd
 */
export const nfdRescindOffer = (
    name: string,
    rescindOfferRequestBody: RescindOfferRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/rescindOffer/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: rescindOfferRequestBody
    },
      options);
    }
  
/**
 * Returns RevAddress address for an NFD name (usable for V1 only)
 * @summary getRevAddressSig nfd
 */
export const nfdGetRevAddressSig = (
    address: string,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/revAddressSig/${address}`, method: 'get'
    },
      options);
    }
  
/**
 * Get top segment roots
 * @summary segmentLeaders nfd
 */
export const nfdSegmentLeaders = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdRecordCollection>(
      {url: `/nfd/segment/leaders`, method: 'get'
    },
      options);
    }
  
/**
 * Lock/Unlock an NFD segment - if locked, the segment only allows minted names created by the segment owner.  If unlocked, anyone can mint off the segment for the price (in USD) the owner sets
 * @summary Lock/Unlock an NFD segment - specifying open price if unlocking
 */
export const nfdSegmentLock = (
    name: string,
    segmentLockRequestBody: SegmentLockRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/segment/lock/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: segmentLockRequestBody
    },
      options);
    }
  
/**
 * Suggest NFDs to purchase
 * @summary suggest nfd
 */
export const nfdSuggest = (
    name: string,
    params: NfdSuggestParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdRecordCollection>(
      {url: `/nfd/suggest/${name}`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Fetch NFD summary data - results subject to change in the future
 * @summary totals nfd
 */
export const nfdTotals = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<TotalsOKResponseBody>(
      {url: `/nfd/totals`, method: 'get'
    },
      options);
    }
  
/**
 * Get top twitter influencers
 * @summary twitterLeaders nfd
 */
export const nfdTwitterLeaders = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<TwitterRecord[]>(
      {url: `/nfd/twitter/leaders`, method: 'get'
    },
      options);
    }
  
/**
 * Set an attribute in an NFD on behalf of a particular sender (who must be the owner).  Can set user-defined fields, or clear verified fields (except v.ca*)
 * @summary updatePartial nfd
 */
export const nfdUpdatePartial = (
    name: string,
    updatePartialRequestBody: UpdatePartialRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/update/${name}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updatePartialRequestBody
    },
      options);
    }
  
/**
 * Replace all NFD user-settable attributes with those passed-in, removing, adding and replacing on behalf of a particular sender (who must be the owner).  Returns transaction group of transactions to sign
 * @summary updateAll nfd
 */
export const nfdUpdateAll = (
    name: string,
    updatePartialRequestBody: UpdatePartialRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/update/${name}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updatePartialRequestBody
    },
      options);
    }
  
/**
 * Update the avatar or banner image associated with an NFD by uploading new image content
 * @summary updateImage nfd
 */
export const nfdUpdateImage = (
    name: string,
    sender: string,
    which: 'avatar' | 'banner',
    params?: NfdUpdateImageParams,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/updateImage/${name}/${sender}/${which}`, method: 'post',
        params
    },
      options);
    }
  
/**
 * Get all NFDs which have been explicitly linked to one or more verified [or unverified] Algorand address(es).  Unverified addresses will match but return as unverifiedCaAlgo array.  These should be treated specially and not have the same trust level as verified addresses as they can be falsely attributed.  The caAlgo array is what should be trusted for things like NFT creation addresses. For reverse lookups returning multiple NFDs, the first result should be used.
 * @summary Reverse Address lookup with results returned per address
 */
export const nfdGetNFDsForAddressesV2 = (
    params: NfdGetNFDsForAddressesV2Params,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdGetNFDsForAddressesV2200>(
      {url: `/nfd/v2/address`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Get top donors to a specific NFD Donation target
 * @summary donationLeadersV2 nfd
 */
export const nfdDonationLeadersV2 = (
    name: string,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<Donation[]>(
      {url: `/nfd/v2/donations/leaders/${name}`, method: 'get'
    },
      options);
    }
  
/**
 * Fetch list of tracked Donation NFD 'targets'.
 * @summary donationListV2 nfd
 */
export const nfdDonationListV2 = (
    
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdRecordResponseFullCollection>(
      {url: `/nfd/v2/donations/list`, method: 'get'
    },
      options);
    }
  
/**
 * Search NFDs via various filters
 * @summary Search NFDs via various filters
 */
export const nfdSearchV2 = (
    params?: NfdSearchV2Params,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<NfdV2SearchRecords>(
      {url: `/nfd/v2/search`, method: 'get',
        params
    },
      options);
    }
  
/**
 * Request upgrade of a 2.11 or 3.x NFD to 3.x+ (post renewals). First switch to 3.x will pay 1 year renewal fee
 * @summary ContractUpgradeV3 nfd
 */
export const nfdContractUpgradeV3 = (
    name: string,
    rescindOfferRequestBody: RescindOfferRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/v3/contract/upgrade/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: rescindOfferRequestBody
    },
      options);
    }
  
/**
 * Lock/Unlock ability for the specified NFD Vault to auto opt-in to assets, allowing airdrops from other accounts
 * @summary vaultOptInLock nfd
 */
export const nfdVaultOptInLock = (
    name: string,
    contractLockRequestBody: ContractLockRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/vault/lock/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: contractLockRequestBody
    },
      options);
    }
  
/**
 * Send an amount of an asset [0 == ALGO] to another account FROM the NFD Vault.  Only owner of NFD can send.
 * @summary sendFromVault nfd
 */
export const nfdSendFromVault = (
    name: string,
    sendFromVaultRequestBody: SendFromVaultRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/vault/sendFrom/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sendFromVaultRequestBody
    },
      options);
    }
  
/**
 * Provide transaction to send an asset owned by sender account to an NFD vault.  Call to have opt-in to vault will be included if necessary.  Callable by NFD owner, or if Opt-in is UNLOCKED (or asset already opted-in), anyone can call
 * @summary sendToVault nfd
 */
export const nfdSendToVault = (
    name: string,
    sendToVaultRequestBody: SendToVaultRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<string>(
      {url: `/nfd/vault/sendTo/${name}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sendToVaultRequestBody
    },
      options);
    }
  
/**
 * Verify a particular piece of data on, or off-chain.  Each verification differs in its requirements
 * @summary verifyConfirm nfd
 */
export const nfdVerifyConfirm = (
    id: string,
    verifyConfirmRequestBody: VerifyConfirmRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<VerifyConfirmResponseBody>(
      {url: `/nfd/verify/confirm/${id}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: verifyConfirmRequestBody
    },
      options);
    }
  
/**
 * Request Verification for particular piece of data on, or off-chain.  Each verification differs in its requirements. Returns data to be used in challenge
 * @summary verifyRequest nfd
 */
export const nfdVerifyRequest = (
    verifyRequestRequestBody: VerifyRequestRequestBody,
 options?: SecondParameter<typeof customAxios>,) => {
      return customAxios<VerifyRequestResponseBody>(
      {url: `/nfd/verify/request`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: verifyRequestRequestBody
    },
      options);
    }
  
export type InfoInfoOpenapi3YamlResult = NonNullable<Awaited<ReturnType<typeof infoInfoOpenapi3Yaml>>>
export type InfoVersionResult = NonNullable<Awaited<ReturnType<typeof infoVersion>>>
export type NfdGetNFDResult = NonNullable<Awaited<ReturnType<typeof nfdGetNFD>>>
export type NfdActivityResult = NonNullable<Awaited<ReturnType<typeof nfdActivity>>>
export type NfdAnalyticsResult = NonNullable<Awaited<ReturnType<typeof nfdAnalytics>>>
export type NfdBadgesResult = NonNullable<Awaited<ReturnType<typeof nfdBadges>>>
export type NfdBrowseResult = NonNullable<Awaited<ReturnType<typeof nfdBrowse>>>
export type NfdConsensusLeadersResult = NonNullable<Awaited<ReturnType<typeof nfdConsensusLeaders>>>
export type NfdConsensusMetricsResult = NonNullable<Awaited<ReturnType<typeof nfdConsensusMetrics>>>
export type NfdContractLockResult = NonNullable<Awaited<ReturnType<typeof nfdContractLock>>>
export type NfdContractUpgradeResult = NonNullable<Awaited<ReturnType<typeof nfdContractUpgrade>>>
export type NfdDonationsResult = NonNullable<Awaited<ReturnType<typeof nfdDonations>>>
export type NfdGetQuoteResult = NonNullable<Awaited<ReturnType<typeof nfdGetQuote>>>
export type NfdIsValidNFDResult = NonNullable<Awaited<ReturnType<typeof nfdIsValidNFD>>>
export type NfdIsValidASAResult = NonNullable<Awaited<ReturnType<typeof nfdIsValidASA>>>
export type NfdLinkAddressResult = NonNullable<Awaited<ReturnType<typeof nfdLinkAddress>>>
export type NfdUnlinkAddressResult = NonNullable<Awaited<ReturnType<typeof nfdUnlinkAddress>>>
export type NfdSetPrimaryAddressResult = NonNullable<Awaited<ReturnType<typeof nfdSetPrimaryAddress>>>
export type NfdSetPrimaryNFDResult = NonNullable<Awaited<ReturnType<typeof nfdSetPrimaryNFD>>>
export type NfdGetLookupResult = NonNullable<Awaited<ReturnType<typeof nfdGetLookup>>>
export type NfdMintResult = NonNullable<Awaited<ReturnType<typeof nfdMint>>>
export type NfdGetNameSigResult = NonNullable<Awaited<ReturnType<typeof nfdGetNameSig>>>
export type NfdOfferResult = NonNullable<Awaited<ReturnType<typeof nfdOffer>>>
export type NfdPostOfferToOwnerResult = NonNullable<Awaited<ReturnType<typeof nfdPostOfferToOwner>>>
export type NfdPurchaseResult = NonNullable<Awaited<ReturnType<typeof nfdPurchase>>>
export type NfdRenewResult = NonNullable<Awaited<ReturnType<typeof nfdRenew>>>
export type NfdRescindOfferResult = NonNullable<Awaited<ReturnType<typeof nfdRescindOffer>>>
export type NfdGetRevAddressSigResult = NonNullable<Awaited<ReturnType<typeof nfdGetRevAddressSig>>>
export type NfdSegmentLeadersResult = NonNullable<Awaited<ReturnType<typeof nfdSegmentLeaders>>>
export type NfdSegmentLockResult = NonNullable<Awaited<ReturnType<typeof nfdSegmentLock>>>
export type NfdSuggestResult = NonNullable<Awaited<ReturnType<typeof nfdSuggest>>>
export type NfdTotalsResult = NonNullable<Awaited<ReturnType<typeof nfdTotals>>>
export type NfdTwitterLeadersResult = NonNullable<Awaited<ReturnType<typeof nfdTwitterLeaders>>>
export type NfdUpdatePartialResult = NonNullable<Awaited<ReturnType<typeof nfdUpdatePartial>>>
export type NfdUpdateAllResult = NonNullable<Awaited<ReturnType<typeof nfdUpdateAll>>>
export type NfdUpdateImageResult = NonNullable<Awaited<ReturnType<typeof nfdUpdateImage>>>
export type NfdGetNFDsForAddressesV2Result = NonNullable<Awaited<ReturnType<typeof nfdGetNFDsForAddressesV2>>>
export type NfdDonationLeadersV2Result = NonNullable<Awaited<ReturnType<typeof nfdDonationLeadersV2>>>
export type NfdDonationListV2Result = NonNullable<Awaited<ReturnType<typeof nfdDonationListV2>>>
export type NfdSearchV2Result = NonNullable<Awaited<ReturnType<typeof nfdSearchV2>>>
export type NfdContractUpgradeV3Result = NonNullable<Awaited<ReturnType<typeof nfdContractUpgradeV3>>>
export type NfdVaultOptInLockResult = NonNullable<Awaited<ReturnType<typeof nfdVaultOptInLock>>>
export type NfdSendFromVaultResult = NonNullable<Awaited<ReturnType<typeof nfdSendFromVault>>>
export type NfdSendToVaultResult = NonNullable<Awaited<ReturnType<typeof nfdSendToVault>>>
export type NfdVerifyConfirmResult = NonNullable<Awaited<ReturnType<typeof nfdVerifyConfirm>>>
export type NfdVerifyRequestResult = NonNullable<Awaited<ReturnType<typeof nfdVerifyRequest>>>
