import Link from 'next/link'
import { BiWallet } from 'react-icons/bi'
import { HiCash } from 'react-icons/hi'
import Skeleton from './Skeleton'
import Address from './Address'
import Avatar from './Avatar'
import type { WalletAccount } from '@txnlab/use-wallet-react'
import type { AxiosError } from 'axios'
import type { NfdRecord } from 'api/api-client'

type UserProps = {
  nfd: NfdRecord | undefined
  isLoading: boolean
  error: AxiosError | null
  activeAccount: WalletAccount | null | undefined
  address: string
  onNavigateToAccount: () => void
}

export default function User({
  nfd,
  isLoading,
  error,
  activeAccount,
  address,
  onNavigateToAccount
}: UserProps) {
  if (isLoading) {
    return <Skeleton />
  }

  if (error || !nfd) {
    return (
      <div className="rounded-md mb-1 flex items-center sm:space-x-4">
        <div className="hidden sm:flex sm:flex-shrink-0">
          <div className="relative w-14 h-14 flex items-center justify-center rounded-full">
            <div className="flex items-center justify-center h-12 w-12 relative rounded-full bg-gray-200 overflow-hidden ring-2 ring-gray-200 dark:bg-gray-800 dark:ring-gray-800">
              <BiWallet
                className="h-6 w-6 lg:h-5 lg:w-5 text-gray-500 dark:text-gray-500"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-2 sm:space-x-0 sm:block flex-1 min-w-0">
          <div>
            <p className="inline-flex items-center font-medium">
              <HiCash
                className="sm:hidden flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600 dark:text-gray-400"
                aria-hidden="true"
              />
              {activeAccount?.name}
            </p>
          </div>
          <div>
            <Address address={address} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="rounded-md mb-1 flex items-center sm:space-x-4">
      <div className="hidden sm:flex sm:flex-shrink-0">
        <Avatar nfd={nfd} onNavigateToAccount={onNavigateToAccount} />
      </div>
      <div className="flex flex-wrap sm:space-x-0 sm:block flex-1 min-w-0">
        <p className="mr-2 sm:mr-0 font-medium truncate">
          <Link
            href={`/name/${nfd.name}`}
            className="hover:text-brand-500"
            onClick={onNavigateToAccount}
          >
            {nfd.name}
          </Link>
        </p>
        <div>
          <Address address={address} />
        </div>
      </div>
    </div>
  )
}
