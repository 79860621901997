/* eslint-disable @typescript-eslint/no-explicit-any */
import { MAX_UPDATE_AGE_SECONDS } from './constants'
import type { QueryKey } from '@tanstack/react-query'
import type { OptimisticUpdate, OptimisticUpdateStore } from './types'
import type { AnyRecord } from 'types/utility'

export const matchQuery = (queryKey1: QueryKey, queryKey2: QueryKey, exact: boolean): boolean => {
  if (exact) {
    return JSON.stringify(queryKey1) === JSON.stringify(queryKey2)
  } else {
    const minLength = Math.min(queryKey1.length, queryKey2.length)
    for (let i = 0; i < minLength; i++) {
      if (JSON.stringify(queryKey1[i]) !== JSON.stringify(queryKey2[i])) {
        return false
      }
    }
    return true
  }
}

export const cleanupOptimisticUpdates = (state: OptimisticUpdateStore) => {
  const now = new Date()

  state.queries = state.queries
    .map((query) => {
      // Remove updates older than MAX_UPDATE_AGE_SECONDS seconds
      const filteredOptimisticUpdates = query.optimisticUpdates.filter(
        (update) =>
          (now.getTime() - new Date(update.timeCreated).getTime()) / 1000 < MAX_UPDATE_AGE_SECONDS
      )

      // Remove duplicates, keeping only the most recent update for each record
      const updatesMap = new Map<string, OptimisticUpdate<any>>()

      filteredOptimisticUpdates.forEach((update) => {
        const recordId = (update.record as AnyRecord)[update.uniqueKey as string]
        const existingUpdate = updatesMap.get(String(recordId))

        if (
          !existingUpdate ||
          new Date(update.timeCreated) > new Date(existingUpdate.timeCreated)
        ) {
          updatesMap.set(String(recordId), update)
        }
      })

      return { ...query, optimisticUpdates: Array.from(updatesMap.values()) }
    })
    // Remove queries with no optimistic updates
    .filter((query) => query.optimisticUpdates.length > 0)
}
