import Button from 'components/Button'
import { toast, Toast as ToastType } from 'react-hot-toast'

type ErrorToastprops = {
  error: string
  t: ToastType
}

export default function ErrorToast({ error, t }: ErrorToastprops) {
  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-red-500">Error</h3>
      <div className="mt-2 max-w-[75vw] text-sm text-gray-600 dark:text-gray-400">
        <span className="break-words">{error}</span>
      </div>
      <div className="mt-5 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0">
        <Button onClick={() => toast.dismiss(t.id)}>Dismiss</Button>
      </div>
    </>
  )
}
