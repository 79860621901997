import Image from 'next/image'
import { WalletId, type WalletAccount } from '@txnlab/use-wallet-react'
import Button from 'components/Button'
import AccountSelect from 'components/ConnectWallet/WalletProvider/AccountSelect'
import { HiOutlineLightningBolt, HiOutlineMinusCircle, HiOutlinePlusCircle } from 'react-icons/hi'
import { classNames } from 'helpers/utilities'

type WalletProviderProps = {
  providerId: WalletId
  name: string
  icon: string
  accounts: WalletAccount[]
  activeAccount: WalletAccount | undefined | null
  isConnected: boolean
  isActive: boolean
  isScrollable: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleConnectWallet: (args?: Record<string, any>) => Promise<WalletAccount[]>
  handleDisconnectWallet: (walletId: WalletId) => Promise<void>
  handleSetActiveWallet: () => void
  handleSelectAccount: (address: string) => void
}

export default function WalletProvider({
  providerId,
  name,
  icon,
  accounts,
  activeAccount,
  isActive,
  isConnected,
  isScrollable,
  handleConnectWallet,
  handleDisconnectWallet,
  handleSetActiveWallet,
  handleSelectAccount
}: WalletProviderProps) {
  const renderConnectButton = () => {
    if (isActive) {
      return (
        <Button
          variant="default"
          className="group mt-3 w-full text-sm sm:mt-0 sm:w-36 self-end dark:bg-gray-800 dark:hover:bg-gray-700/75"
          onClick={() => handleDisconnectWallet(providerId)}
          icon={() => (
            <HiOutlineMinusCircle className="h-5 w-5 text-red-400 group-hover:text-red-500 mr-2 dark:text-red-500" />
          )}
        >
          <span>Disconnect</span>
        </Button>
      )
    }

    if (isConnected && !isActive) {
      return (
        <Button
          variant="default"
          className="group w-36 text-sm sm:mt-0 dark:hover:bg-gray-600/75"
          onClick={handleSetActiveWallet}
          icon={() => (
            <HiOutlineLightningBolt className="h-5 w-5 text-blue-400 group-hover:text-blue-500 mr-2 dark:text-blue-500" />
          )}
        >
          <span>Set Active</span>
        </Button>
      )
    }

    return (
      <Button
        variant="default"
        className="group w-36 text-sm sm:mt-0 dark:hover:bg-gray-600/75"
        onClick={() => handleConnectWallet()}
        icon={() => (
          <HiOutlinePlusCircle className="h-5 w-5 text-green-400 group-hover:text-green-500 mr-2 dark:text-green-500" />
        )}
      >
        <span>Connect</span>
      </Button>
    )
  }

  return (
    <div
      className={classNames(
        isActive ? 'sm:flex sm:items-center' : 'flex items-center',
        'p-4 sm:px-6'
      )}
    >
      <div className="flex-1 flex items-center">
        <div
          className={classNames(
            isActive ? 'mr-4 h-14 w-14 sm:h-14 sm:w-14' : 'h-10 w-10 sm:ml-2',
            'shadow-md rounded-full overflow-hidden dark:bg-gray-200'
          )}
        >
          <Image src={icon} alt="provider icon" className="object-cover" width={56} height={56} />
        </div>
        <div className={classNames(isActive ? 'sm:mr-4' : 'pl-4 sm:px-4', 'flex-1')}>
          <div>
            <p
              className={classNames(
                isActive ? 'text-gray-600 dark:text-gray-300' : 'text-gray-600 dark:text-gray-400',
                'inline-flex items-center text-base font-medium'
              )}
            >
              <span className="mr-2">{name}</span>
            </p>
            {accounts.length > 0 && isActive && (
              <div className="flex items-center text-sm text-gray-500">
                <AccountSelect
                  accounts={accounts}
                  activeAccount={activeAccount as WalletAccount}
                  handleSelectAccount={handleSelectAccount}
                  isNative={isScrollable}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {renderConnectButton()}
    </div>
  )
}
