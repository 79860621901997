import { type UseQueryOptions, useQuery } from '@tanstack/react-query'
import { getAccountInfo } from 'helpers/node'
import type { AccountInformation } from 'types/algosdk'

export default function useAccountInfo(
  address: string,
  excludeAll = false,
  options?: UseQueryOptions<AccountInformation, Error>
) {
  return useQuery<AccountInformation, Error>(
    ['account-info', address],
    () => getAccountInfo(address, excludeAll),
    {
      ...options
    }
  )
}
