/**
 * Returns the active image size based on the provided 'sizes' string and the current viewport width.
 * The function checks for 'max-width' and 'min-width' conditions in the 'sizes' string.
 * If no matching conditions are found, the function returns the last size value.
 *
 * @param {string} sizes - The 'sizes' string to parse, containing the size conditions.
 * @returns {number | undefined} The active image size, or 'undefined' if no matching conditions are found.
 */
export const getActiveImageSize = (sizes: string) => {
  if (typeof window === 'undefined') {
    return undefined
  }

  const screenWidth = window.innerWidth

  const sizeRules = sizes.split(',').map((rule) => rule.trim())

  for (const rule of sizeRules) {
    const [condition, size] = rule.split(' ')

    const maxWidthMatch = condition.match(/\(max-width:\s?(\d+(?:px|vw))\)/)
    const minWidthMatch = condition.match(/\(min-width:\s?(\d+(?:px|vw))\)/)

    if (maxWidthMatch) {
      const maxWidth = parseSize(maxWidthMatch[1], screenWidth) ?? 0
      if (screenWidth <= maxWidth) {
        return parseSize(size, screenWidth) ?? 0
      }
    } else if (minWidthMatch) {
      const minWidth = parseSize(minWidthMatch[1], screenWidth) ?? 0
      if (screenWidth >= minWidth) {
        return parseSize(size, screenWidth) ?? 0
      }
    } else {
      return parseSize(condition, screenWidth) ?? 0
    }
  }

  return undefined
}

function parseSize(size: string, screenWidth: number): number | undefined {
  const sizeMatch = size.match(/(\d+)(px|vw)/)

  if (sizeMatch) {
    const value = parseInt(sizeMatch[1], 10)
    const unit = sizeMatch[2]

    if (unit === 'px') {
      return value
    } else if (unit === 'vw') {
      return (value / 100) * screenWidth
    }
  }

  return undefined
}
