import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { captureException } from '@sentry/nextjs'
import { NfdRecordCollection, nfdSearchV2, NfdSearchV2Params } from 'src/api/api-client'

export const getByQuery = async (
  params: NfdSearchV2Params,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  const { data } = await nfdSearchV2(params, {
    ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
    ...options
  })

  return data.nfds
}

type UseSearchArgs = {
  params: NfdSearchV2Params
  options?: UseQueryOptions<NfdRecordCollection, AxiosError>
}

export default function useSearch({ params, options }: UseSearchArgs) {
  const result = useQuery<NfdRecordCollection, AxiosError>(
    ['search', { ...params }],
    () => getByQuery(params),
    {
      ...options
    }
  )

  if (result.error) {
    captureException(result.error)
  }

  return result
}
