/* eslint-disable @next/next/no-img-element */
import Nav from './Nav'
import NavLogo from './NavLogo'

type DesktopNavProps = {
  pathname: string
}

export default function DesktopNav({ pathname }: DesktopNavProps) {
  return (
    <div className="hidden lg:flex lg:w-64 lg:fixed lg:inset-y-0" data-cy="main-nav-desktop">
      <div className="flex-1 flex flex-col min-h-0">
        <div className="flex items-center h-16 flex-shrink-0 px-5 bg-gray-900 dark:bg-gray-975">
          <NavLogo />
        </div>
        <div className="flex-1 flex flex-col overflow-y-auto bg-gray-900 dark:bg-gray-975">
          <nav className="flex-1 px-3 py-4">
            <Nav pathname={pathname} />
          </nav>
        </div>
      </div>
    </div>
  )
}
