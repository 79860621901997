import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import { formatPrice } from 'helpers/utilities'
import { getAccountInfo } from 'helpers/node'

export default function useWalletBalance() {
  const [walletBalance, setWalletBalance] = useState<string | null>(null)
  const [walletAvailableBalance, setWalletAvailableBalance] = useState<string | null>(null)

  const { activeAccount } = useWallet()

  const { data: accountInfo } = useQuery(
    ['active-balance', activeAccount?.address],
    () => getAccountInfo(activeAccount?.address || '', true),
    {
      enabled: !!activeAccount?.address,
      refetchInterval: 30000
    }
  )

  useEffect(() => {
    if (
      accountInfo &&
      accountInfo.amount !== undefined &&
      accountInfo['min-balance'] !== undefined
    ) {
      const balance = formatPrice(accountInfo.amount, false, { minimumFractionDigits: 3 })
      const availableBalance = formatPrice(accountInfo.amount - accountInfo['min-balance'], false, {
        minimumFractionDigits: 3
      })

      if (balance !== walletBalance) {
        setWalletBalance(balance)
        return
      }

      if (parseFloat(availableBalance) < 0) {
        setWalletAvailableBalance('0.000')
        return
      }

      if (availableBalance !== walletAvailableBalance) {
        setWalletAvailableBalance(availableBalance)
        return
      }
    } else {
      setWalletBalance('0')
      setWalletAvailableBalance('0')
    }
  }, [accountInfo, walletBalance, walletAvailableBalance])

  return {
    walletBalance,
    walletAvailableBalance
  }
}
