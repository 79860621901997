import Head from 'next/head'
import { CLIENT_BASE_URL } from 'src/data/constants'

interface MetaProps {
  title?: string
  description?: string
  image?: string
  imageType?: string
  imageWidth?: number
  imageHeight?: number
}

export default function Meta(props: MetaProps) {
  const {
    title = 'nf.domains',
    description = 'NFDomains is the Algorand name service and marketplace for Non-Fungible Domains — unique, readable identities for your wallet.',
    image = `${CLIENT_BASE_URL}/img/og-image-orange.jpg`,
    imageType = 'image/jpg',
    imageWidth = 1200,
    imageHeight = 630
  } = props

  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

  return (
    <Head>
      <title>{`NFD | ${title}`}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="theme-color" content="#111827" />

      <meta property="og:url" content={`${CLIENT_BASE_URL}${pathname}`} />
      <meta property="og:title" content={`NFD | ${title}`} />
      <meta property="og:description" content={description} />

      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={`${imageWidth}`} />
      <meta property="og:image:height" content={`${imageHeight}`} />
      <meta property="og:image:type" content={imageType} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@NFDomains" />
      <meta name="twitter:site" content="@NFDomains" />
      <meta property="twitter:title" content={`NFD | ${title}`} />
      <meta property="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
    </Head>
  )
}
