import { HiOutlineMenuAlt2 } from 'react-icons/hi'

type MenuButtonProps = {
  setSidebarOpen: (open: boolean) => void
}

export default function MenuButton({ setSidebarOpen }: MenuButtonProps) {
  return (
    <button
      type="button"
      className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900 lg:hidden dark:border-gray-800 dark:focus:ring-gray-100"
      onClick={() => setSidebarOpen(true)}
      data-cy="main-nav-mobile-menu-btn"
    >
      <span className="sr-only">Open sidebar</span>
      <HiOutlineMenuAlt2 className="h-6 w-6" aria-hidden="true" />
    </button>
  )
}
