import { Popover, Transition } from '@headlessui/react'
import { useTheme } from 'next-themes'
import { Fragment } from 'react'
import { useHydrated } from 'react-hydration-provider'
import { HiCog } from 'react-icons/hi'
import { useExplorerStore } from '@/store'
import { classNames } from 'helpers/utilities'
import { AlgorandNetwork, Explorer, EXPLORER_DATA } from 'src/data/explorers'
import { ALGORAND_NETWORK } from 'src/data/constants'

export default function Settings() {
  const { theme, setTheme } = useTheme()

  const explorers = useExplorerStore((state) => state.explorers)
  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const setSelectedExplorer = useExplorerStore((state) => state.setSelectedExplorer)

  const validExplorers = explorers.filter((explorer) =>
    Object.keys(EXPLORER_DATA[explorer]?.baseUrls || {}).includes(
      ALGORAND_NETWORK as AlgorandNetwork
    )
  )
  const handleSelectExplorerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedExplorer(e.target.value as Explorer)
  }

  const hydrated = useHydrated()

  if (!hydrated) {
    return null
  }

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              'inline-flex items-center rounded-full border border-transparent p-2 hover:shadow-sm hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-brand-500 dark:hover:text-gray-200 dark:focus:ring-gray-100',
              open
                ? 'bg-gray-100 text-gray-500 dark:bg-gray-300/10 dark:text-gray-300'
                : 'bg-transparent hover:bg-gray-100 text-gray-400 dark:hover:bg-gray-300/10 dark:text-gray-400'
            )}
          >
            <span className="sr-only">Settings</span>
            <HiCog className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="fixed top-14 left-0 sm:absolute sm:left-auto sm:top-auto sm:right-0 z-60 mt-3 sm:mt-4 w-screen sm:max-w-sm transform px-4 sm:px-0">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="bg-white py-5 px-6 dark:bg-gray-850">
                  <div className="space-y-4 sm:space-y-4">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
                        Settings
                      </h3>
                    </div>

                    <div className="space-y-4 sm:space-y-4 sm:pt-4 sm:border-t sm:border-gray-200 dark:sm:border-gray-750">
                      <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4">
                        <label
                          htmlFor="theme"
                          className="block text-sm font-medium text-gray-700 sm:mt-px dark:text-gray-400"
                        >
                          Theme
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <select
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-500 focus:outline-none focus:ring-brand-500 sm:text-sm dark:bg-gray-750/60 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500"
                            value={theme}
                            onChange={(e) => setTheme(e.target.value)}
                          >
                            <option value="system">System</option>
                            <option value="dark">Dark</option>
                            <option value="light">Light</option>
                          </select>
                        </div>
                      </div>

                      {validExplorers.length > 1 && (
                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4">
                          <label
                            htmlFor="theme"
                            className="block text-sm font-medium text-gray-700 sm:mt-px dark:text-gray-400"
                          >
                            Explorer
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <select
                              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-500 focus:outline-none focus:ring-brand-500 sm:text-sm dark:bg-gray-750/60 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500"
                              value={selectedExplorer}
                              onChange={handleSelectExplorerChange}
                            >
                              {validExplorers.map((explorer) => (
                                <option key={explorer} value={explorer}>
                                  {explorer}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
