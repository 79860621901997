const SkeletonAvatar = () => {
  return <div className="h-14 w-14 bg-brand-500 rounded-full" />
}

const SkeletonText = () => {
  return (
    <div className="space-y-2">
      <div className="h-2 w-8 bg-gray-300 rounded" />
      <div className="h-2 w-12 bg-gray-300 rounded" />
    </div>
  )
}

export default function Skeleton() {
  return (
    <div className="animate-pulse flex items-center space-x-2">
      <SkeletonAvatar />
      <SkeletonText />
    </div>
  )
}
