import Link from 'next/link'
import React, { useEffect } from 'react'
import WalletThumbnail from '../WalletThumbnail'
import Avatar from './Avatar'
import useGetLookup from 'api/hooks/useGetLookup'
import { classNames } from 'helpers/utilities'
import { Sections } from 'components/DetailView/DetailView.types'
import type { NfdRecord } from 'api/api-client'

type Size = 'sm' | 'md'

interface UserThumbnailInterface {
  forwardedRef?: React.Ref<HTMLDivElement>
  className?: string
  fallbackClassName?: string
  link?: boolean
  linkView?: Sections
  padded?: boolean
  size?: Size
  textSize?: 'text-xs' | 'text-sm' | 'text-base'
  textColor?: string
  showAddressFallback?: boolean
  forceAddressFallback?: boolean
  bg?: 'dark' | 'light'
  wrapperClassName?: string
  onMount?: () => void
}

type WrapperProps = {
  className?: string
  children: React.ReactNode
}

const Wrapper = React.forwardRef<HTMLDivElement, WrapperProps>(
  ({ className = '', children }, ref) => {
    return (
      <div ref={ref} className={className}>
        {children}
      </div>
    )
  }
)

Wrapper.displayName = 'Wrapper'

const Skeleton = ({ size }: { size: Size }) => (
  <div className="animate-pulse flex items-center space-x-2 w-32">
    <div className="flex-shrink-0">
      <div
        className={classNames(
          size === 'md' ? 'h-8 w-8' : 'h-5 w-5',
          'bg-gray-100 rounded-full dark:bg-gray-800'
        )}
      />
    </div>
    <div className="flex-1">
      <div className="h-3 bg-gray-100 rounded-full dark:bg-gray-800" />
    </div>
  </div>
)

type UserThumbnailProps = (
  | {
      nfd: NfdRecord
      address?: never
      staleTime?: never
    }
  | {
      nfd?: never
      address: string
      staleTime?: number
    }
) &
  UserThumbnailInterface

const UserThumbnail = ({
  forwardedRef,
  nfd: _nfd,
  address = '',
  className = '',
  fallbackClassName = '',
  link = true,
  linkView,
  padded: isPadded = false,
  size = 'md',
  textSize = 'text-sm',
  textColor = 'text-gray-900 group-hover:text-brand-500 dark:text-gray-400',
  showAddressFallback = true,
  forceAddressFallback = false,
  staleTime = 0,
  bg,
  wrapperClassName,
  onMount
}: UserThumbnailProps) => {
  const { data, isInitialLoading, error, isSuccess } = useGetLookup({
    params: {
      address: [address],
      view: 'thumbnail'
    },
    options: {
      enabled: address !== '' && !forceAddressFallback,
      refetchOnWindowFocus: false,
      staleTime
    }
  })

  useEffect(() => {
    const queryEnabled = address !== '' && !forceAddressFallback
    if (queryEnabled && isSuccess) {
      onMount?.()
    } else if (!queryEnabled) {
      onMount?.()
    }
  }, [address, forceAddressFallback, isSuccess, onMount])

  if (isInitialLoading) {
    return (
      <Wrapper className={wrapperClassName}>
        <Skeleton size={size} />
      </Wrapper>
    )
  }

  const nfd = _nfd || data?.[0]

  if (!showAddressFallback && (error || !nfd)) {
    return null
  }

  if (forceAddressFallback || error || !nfd) {
    return (
      <Wrapper className={wrapperClassName}>
        <WalletThumbnail address={address} className={fallbackClassName} />
      </Wrapper>
    )
  }

  const renderThumbnail = () => (
    <div className="flex items-center space-x-2">
      <div className="flex-shrink-0">
        <Avatar nfd={nfd} size={size} bg={bg} isPadded={isPadded} />
      </div>
      <div className="flex-1 min-w-0">
        <p className={classNames(textSize, textColor, 'font-medium truncate')}>{nfd.name}</p>
      </div>
    </div>
  )

  if (!link) {
    return (
      <Wrapper ref={forwardedRef} className={wrapperClassName}>
        <div className={className}>{renderThumbnail()}</div>
      </Wrapper>
    )
  }

  const renderLink = () => {
    const href = linkView ? `/name/${nfd.name}?view=${linkView}` : `/name/${nfd.name}`

    return (
      <Wrapper ref={forwardedRef} className={wrapperClassName}>
        <Link href={href} className={classNames('outline-brand-500 rounded-md group', className)}>
          {renderThumbnail()}
        </Link>
      </Wrapper>
    )
  }

  return renderLink()
}

export default React.memo(UserThumbnail)
