import AdvancedSearch from 'components/AdvancedSearch'
import ConnectWallet from 'components/ConnectWallet'
import Settings from './Settings'

export default function Header() {
  return (
    <div className="flex-1 px-4 flex items-center justify-between">
      <div className="flex-1 relative" data-joyride-id="jr-global-search">
        <AdvancedSearch addressLookup suggestMint />
      </div>
      <div className="ml-2 sm:ml-4 lg:ml-6 px-3 flex items-center">
        <Settings />
      </div>
      <div className="flex items-center" data-joyride-id="jr-wallet">
        <ConnectWallet />
      </div>
    </div>
  )
}
