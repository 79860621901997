import Link from 'next/link'
import { Client } from 'react-hydration-provider'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { CLIENT_BASE_URL } from 'src/data/constants'
import { useStore } from 'store/state/store'

export default function TosBanner() {
  const hasAcceptedTerms = useStore((state) => state.hasAcceptedTerms)
  const setHasAcceptedTerms = useStore((state) => state.setHasAcceptedTerms)

  return (
    <Client>
      {!hasAcceptedTerms && (
        <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-40" data-cy="tos-banner">
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="p-3 rounded-lg bg-brand-600 shadow-lg">
              <div className="flex items-center justify-between flex-wrap">
                <div className="flex-1 flex items-center">
                  <span className="flex p-2 rounded-lg bg-brand-800">
                    <HiOutlineInformationCircle className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <p className="mx-3 text-white text-sm lg:text-base">
                    By continuing to use the platform you agree to the{' '}
                    <Link
                      className="font-medium underline whitespace-nowrap text-white/80 hover:text-white"
                      href={`${CLIENT_BASE_URL}/terms`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </Link>
                  </p>
                </div>
                <div className="mt-3 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-semibold text-brand-600 bg-white hover:bg-brand-50"
                    onClick={() => setHasAcceptedTerms(true)}
                    data-cy="tos-banner-btn"
                  >
                    I agree
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Client>
  )
}
