import algosdk from 'algosdk'

export const msgpackToBase64 = (transaction: Uint8Array) => {
  // return btoa(byteArrayToString(array))
  return Buffer.from(transaction).toString('base64')
}

export const base64ToMsgpack = (transaction: string) => {
  // return stringToByteArray(atob(transaction))
  return new Uint8Array(Buffer.from(transaction, 'base64'))
}

export const stringToByteArray = (transaction: string) => {
  // return new Uint8Array(str.split('').map((x) => x.charCodeAt(0)))
  return new Uint8Array(Buffer.from(transaction))
}

export const byteArrayToString = (transaction: Uint8Array) => {
  // return String.fromCharCode.apply(null, array)
  return Buffer.from(transaction).toString()
}

export const decodeTransaction = (txn: string, isSigned: boolean) => {
  return isSigned
    ? algosdk.decodeSignedTransaction(new Uint8Array(Buffer.from(txn, 'base64'))).txn
    : algosdk.decodeUnsignedTransaction(new Uint8Array(Buffer.from(txn, 'base64')))
}

export const isValidAddress = (address: string) => {
  return algosdk.isValidAddress(address)
}

export type NFDTransactionsArray = ['u' | 's', string][]

export function encodeNFDTransactionsArray(transactionsArray: NFDTransactionsArray) {
  return transactionsArray.map(([_type, txn]) => {
    return base64ToMsgpack(txn)
  })
}
