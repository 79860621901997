import Logo from 'components/Logo'

export default function NavLogo() {
  const environment = process.env.NEXT_PUBLIC_VERCEL_ENV
  const showEnvironment = environment && ['preview', 'betanet', 'testnet'].includes(environment)

  return (
    <>
      <Logo className="h-7 w-auto fill-white" />
      {showEnvironment && (
        <span className="ml-3 inline-flex items-center px-1.5 py-0.5 rounded-sm text-[10px] font-medium bg-brand-500/20 text-brand-100 uppercase">
          {environment}
        </span>
      )}
    </>
  )
}
