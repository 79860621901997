/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { MutationOptions, usePostTransaction } from './usePostTransaction'
import type { SendModalContext } from 'components/SendModal/SendModal.types'
import type { SendToAccountParams } from 'types/node'

export const nfdSendToAccount = async (
  data: SendToAccountParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<string, any>> => {
  const response = await axios({
    method: 'post',
    url: '/api/node/send-to-account',
    data,
    headers: { 'Content-Type': 'application/json' },
    ...options
  })

  const base64Transaction = response.data

  const nfdTransactionArray = [['u', base64Transaction]]
  const dataString = JSON.stringify(nfdTransactionArray)

  return {
    ...response,
    data: dataString
  }
}

type SendToAccountOptions = MutationOptions<SendToAccountParams, SendModalContext>

export function usePostSendToAccount(options: SendToAccountOptions = {}) {
  return usePostTransaction<SendToAccountParams, SendModalContext>({
    mutationFn: (params) => nfdSendToAccount(params),
    ...options
  })
}
