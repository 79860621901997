import Link from 'next/link'
import { BiWallet } from 'react-icons/bi'
import { classNames, truncateAddress } from 'helpers/utilities'

interface WalletThumbnailProps {
  address: string | undefined
  className?: string
  link?: boolean
}

export default function WalletThumbnail({
  address,
  className = '',
  link = true
}: WalletThumbnailProps) {
  if (!address) {
    return <span className="text-sm text-gray-900 dark:text-gray-400">N/A</span>
  }

  const baseClasses = 'inline-flex items-center text-sm text-gray-900 font-mono dark:text-gray-400'

  const renderAddress = () => {
    const addr = truncateAddress(address, { array: true })
    return (
      <>
        <BiWallet className="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
        {addr[0]}&hellip;{addr[1]}
      </>
    )
  }

  if (!link) {
    return <span className={classNames(baseClasses, className)}>{renderAddress()}</span>
  }

  return (
    <Link
      href={`/address/${address}`}
      className={classNames(
        baseClasses,
        'hover:text-brand-500 outline-brand-500 dark:hover:text-brand-500',
        className
      )}
    >
      {renderAddress()}
    </Link>
  )
}
