import { useHydrated } from 'react-hydration-provider'
import { immer } from 'zustand/middleware/immer'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export type AdvancedSearchStore = {
  recent: string[]
  addToRecent: (item: string) => void
}

const emptyState: AdvancedSearchStore = {
  recent: [],
  addToRecent: () => {
    return
  }
}

const usePersistedStore = create<AdvancedSearchStore>()(
  immer(
    persist(
      devtools((set) => ({
        recent: [],
        addToRecent: (item: string) => {
          set((state) => {
            const newRecent = state.recent.filter((i) => i !== item)

            if (newRecent.length > 6) {
              newRecent.pop()
            }

            newRecent.unshift(item)
            state.recent = newRecent
          })
        }
      })),
      {
        name: 'nfd-recent-searches',
        version: 1
      }
    )
  )
)

// @see https://github.com/pmndrs/zustand/issues/1145#issuecomment-1207233036
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAdvancedSearchStore = ((selector: any, compare: any) => {
  const store = usePersistedStore(selector, compare)
  const hydrated = useHydrated()

  return hydrated ? store : selector(emptyState)
}) as typeof usePersistedStore
