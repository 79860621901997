import BigNumber from 'bignumber.js'

export function convertToBaseUnitsString(amount: string, decimals: number): string {
  if (decimals === 0) return amount.replace(/\..*$/, '')
  if (!amount || amount === '0') return '0'

  // Remove commas and trim
  amount = amount.replace(/,/g, '').trim()

  const bn = new BigNumber(amount)
  const multiplier = new BigNumber(10).pow(decimals)

  return bn.times(multiplier).integerValue(BigNumber.ROUND_DOWN).toString()
}

export function convertToWholeUnitsString(amount: string, decimals: number): string {
  if (decimals === 0) return amount
  if (!amount || amount === '0') return '0'

  const bn = new BigNumber(amount)
  const divisor = new BigNumber(10).pow(decimals)

  return bn.dividedBy(divisor).toString()
}
