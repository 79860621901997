import { nfdSetPrimaryNFD, type SetPrimaryAddressRequestBody } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

type SetPrimaryNFDParams = {
  name: string
  body: SetPrimaryAddressRequestBody
}

export function usePostSetPrimaryNFD(options: MutationOptions<SetPrimaryNFDParams> = {}) {
  return usePostTransaction<SetPrimaryNFDParams>({
    mutationFn: ({ name, body }) => nfdSetPrimaryNFD(name, body),
    ...options
  })
}
