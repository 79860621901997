import type { NfdRecord } from 'api/api-client'

export const isAvatarVerified = (nfd: NfdRecord): boolean => {
  return !!nfd?.properties?.verified?.avatar && !nfd?.properties?.userDefined?.avatar
}

export const getAvatarURL = (nfd: NfdRecord): string => {
  const url = nfd?.properties?.userDefined?.avatar || nfd?.properties?.verified?.avatar

  const isAvailable = nfd.state === 'available'
  const isForSale = nfd.state === 'forSale'
  const isReserved = nfd.state === 'reserved'
  const isCurated = nfd.category === 'curated'

  if (!url && isCurated) {
    return `/img/nfd-image-placeholder_gold.jpg`
  }

  const showAvailablePlaceholder = isAvailable || isForSale || isReserved

  if (!url && showAvailablePlaceholder) {
    return `/img/nfd-image-placeholder_gray.jpg`
  }

  if (!url) {
    return `/img/nfd-image-placeholder.jpg`
  }

  return url
}

export const showTextAvatar = (nfd: NfdRecord): boolean => {
  return !nfd?.properties?.verified?.avatar && !nfd?.properties?.userDefined?.avatar
}

export const getTextAvatarBgURL = (nfd: NfdRecord): string => {
  const isAvailable = nfd.state === 'available'
  const isForSale = nfd.state === 'forSale'
  const isReserved = nfd.state === 'reserved'
  const isCurated = nfd.category === 'curated'

  if (isCurated) {
    return `/img/nfd-image-placeholder-bg_gold.jpg`
  }

  const showAvailablePlaceholder = isAvailable || isForSale || isReserved

  if (showAvailablePlaceholder) {
    return `/img/nfd-image-placeholder-bg_gray.jpg`
  }

  return `/img/nfd-image-placeholder-bg.jpg`
}
