import { useEffect, useState } from 'react'
import { immer } from 'zustand/middleware/immer'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { EditDashboardView } from 'components/manage/EditDashboard/EditDashboard.types'

export type EditViewStore = {
  view: {
    [key: string]: EditDashboardView
  }
  setView: (name: string, view: EditDashboardView) => void
}

const emptyState: EditViewStore = {
  view: {},
  setView: () => {
    return
  }
}

const usePersistedStore = create<EditViewStore>()(
  immer(
    persist(
      devtools((set) => ({
        view: {},
        setView: (name: string, view: EditDashboardView) => {
          set((state) => {
            state.view[name] = view
          })
        }
      })),
      {
        name: 'nfd-edit-view',
        version: 1
      }
    )
  )
)

// @see https://github.com/pmndrs/zustand/issues/1145#issuecomment-1207233036
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEditViewStore = ((selector: any, compare: any) => {
  const store = usePersistedStore(selector, compare)
  const [hydrated, setHydrated] = useState(false)
  useEffect(() => setHydrated(true), [])

  return hydrated ? store : selector(emptyState)
}) as typeof usePersistedStore
