import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { fetchMediaInfo } from './AssetMedia.utils'
import type { MediaInfo } from './AssetMedia.types'

export default function useAssetMedia(src: string) {
  const [isError, setIsError] = useState(false)

  const query = useQuery<MediaInfo, Error>(['media-info', src], async () => fetchMediaInfo(src), {
    enabled: !!src,
    staleTime: Infinity,
    retry: false
  })

  const handleError = () => {
    setIsError(true)
  }

  return {
    mediaInfo: query.data,
    isLoading: query.isInitialLoading,
    isError: query.isError || isError,
    handleError
  }
}
