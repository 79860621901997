import axios from 'axios'
import * as React from 'react'
import AssetMedia from 'components/AssetMedia'
import TokenImage from 'components/DetailView/Assets/AssetPreview/TokenImage'
import { resolveAssetUrlProtocol } from 'helpers/arc19'
import { hasResponseStatus } from 'helpers/errors'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { isAssetNft } from 'helpers/utilities'
import { AccountAsset, AlgoAccountAsset } from 'types/node'

const FALLBACK_SRC = '/img/coin-line.svg'

interface AssetThumbnailProps {
  asset: AccountAsset
  className?: string
}

function AssetThumbnail({ asset, className = '' }: AssetThumbnailProps) {
  const src = useAssetThumbnail(asset)

  const renderThumbnail = () => {
    if (!src) {
      return (
        <div className="h-12 w-12 bg-gray-200 rounded-full animate-strong-pulse dark:bg-gray-750" />
      )
    }

    const description = asset.name || asset.unitName || asset.id
    const alt = `${description} thumbnail`

    if (src === FALLBACK_SRC) {
      return <TokenImage alt={alt} />
    }

    return (
      <AssetMedia
        src={src}
        alt={alt}
        loader={galleryImageLoader}
        width={48}
        height={48}
        unoptimized={false}
        options={{ stateBgColor: 'transparent', showVideoIcon: false }}
        videoJsOptions={{
          preload: 'none',
          controls: false,
          autoplay: false,
          loop: true,
          muted: true,
          aspectRatio: '1:1'
        }}
      />
    )
  }

  return <div className={className}>{renderThumbnail()}</div>
}

export default React.memo(AssetThumbnail)

function useAssetThumbnail(asset: AccountAsset) {
  const [src, setSrc] = React.useState<string | null>(null)

  // Type guard for AlgoAccountAsset
  const isAlgoToken = (asset: AccountAsset): asset is AlgoAccountAsset => {
    return asset.id === 0
  }

  // Total created is < 10,000
  const isNft = !isAlgoToken(asset) && isAssetNft(asset.total, asset.decimals)

  const getSrc = React.useCallback(async () => {
    // Use local image for ALGO
    if (isAlgoToken(asset)) {
      return '/img/asset-algo.svg'
    }

    // Handle ARC-3/ARC-19 URLs
    else if (isNft) {
      const resolvedUrl = resolveAssetUrlProtocol(asset.url, asset.reserve)

      if (resolvedUrl) {
        return resolvedUrl
      }

      return FALLBACK_SRC
    }

    // Check AlgoExplorer for logo
    else {
      try {
        const response = await axios.get(`/api/asset-logo/${asset.id}`)

        if (response.status === 200 && response.config.url) {
          return response.config.url
        }
      } catch (error) {
        if (!hasResponseStatus(error, [403, 404])) {
          console.error(`Error fetching asset thumbnail [${asset.unitName}]:`, error)
        }
      }

      return FALLBACK_SRC
    }
  }, [asset, isNft])

  React.useEffect(() => {
    getSrc().then(setSrc)
  }, [getSrc])

  return src
}
