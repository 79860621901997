import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { HiCheckCircle, HiSelector, HiCash } from 'react-icons/hi'
import { classNames, truncateAddress } from 'src/helpers/utilities'
import type { WalletAccount } from '@txnlab/use-wallet-react'

type AccountSelectProps = {
  accounts: WalletAccount[]
  activeAccount: WalletAccount | undefined
  handleSelectAccount: (address: string) => void
  isNative: boolean
}

export default function AccountSelect({
  accounts,
  activeAccount,
  handleSelectAccount,
  isNative
}: AccountSelectProps) {
  const handleSelectActiveAccount = (account: WalletAccount) => {
    handleSelectAccount(account.address)
  }

  const handleNativeSelectActiveAccount = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    const account = accounts.find((account) => account.address === value)
    if (account) {
      handleSelectActiveAccount(account)
    }
  }

  if (isNative) {
    return (
      <div className="flex-1">
        <label htmlFor="select-account" className="sr-only">
          Select Account
        </label>
        <select
          id="select-account"
          name="select-account"
          className="mt-1 block w-full rounded-md border-0 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6 [&_*]:text-black dark:text-white dark:bg-white/5 dark:ring-white/10"
          value={activeAccount?.address || ''}
          onChange={handleNativeSelectActiveAccount}
        >
          {accounts.map((account) => (
            <option key={account.address} value={account.address}>
              {account.name}: {truncateAddress(account.address)}
            </option>
          ))}
        </select>
      </div>
    )
  }

  return (
    <Listbox value={activeAccount} onChange={handleSelectActiveAccount}>
      <div className="relative flex-1 w-32 min-w-0">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left border border-gray-300 shadow-sm bg-white rounded-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-2 sm:text-sm dark:bg-gray-800 dark:text-gray-100 dark:border-transparent">
          <span className="flex items-center text-sm text-gray-500">
            <HiCash
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 dark:text-gray-500"
              aria-hidden="true"
            />
            <span className="block truncate">
              <span
                className={
                  activeAccount ? 'font-medium text-gray-500 dark:text-gray-100' : 'font-normal'
                }
              >
                {activeAccount?.name}:{' '}
              </span>
              <span className="text-gray-400 font-mono dark:text-gray-400">
                {activeAccount?.address}
              </span>
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <HiSelector className="w-5 h-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
            </span>
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-800 dark:text-gray-400 dark:ring-gray-800/40">
            {accounts.map((account) => (
              <Listbox.Option
                key={account.address}
                className={({ active }) =>
                  classNames(
                    active ? 'bg-gray-100 dark:bg-gray-900/50' : 'bg-white dark:bg-gray-800',
                    'cursor-default select-none relative py-2 pl-10 pr-4'
                  )
                }
                value={account}
              >
                {({ selected }) => (
                  <span className="flex items-center text-sm">
                    {selected || account.address === activeAccount?.address ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <HiCheckCircle className="w-5 h-5 text-brand-500" aria-hidden="true" />
                      </span>
                    ) : (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <HiCash
                          className="h-5 w-5 text-gray-400 dark:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                    <span className="block truncate">
                      <span
                        className={
                          selected
                            ? 'font-medium text-gray-700 dark:text-gray-100'
                            : 'font-normal text-gray-500 dark:text-gray-300'
                        }
                      >
                        {account.name}:{' '}
                      </span>
                      <span className="font-mono text-gray-400 dark:text-gray-500">
                        {account.address}
                      </span>
                    </span>
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
