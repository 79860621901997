interface ImageLoaderParams {
  src: string
  width: number
  quality?: number
}

export default function galleryImageLoader({ src, width, quality }: ImageLoaderParams) {
  const params = [`${width}x`, `q${quality || 75}`, 'jpeg']
  return `https://imageproxy.nf.domains/${params.join(',')}/${src}`
}
