import type { NfdRecordCollection } from 'api/api-client'

export const sortResults = (results: NfdRecordCollection | undefined) => {
  if (!results) {
    return []
  }

  const numLevels = (str: string) => {
    return str.split('.').length
  }

  return results.sort((a, b) => {
    if (numLevels(a.name) === numLevels(b.name)) {
      return a.name.localeCompare(b.name)
    }

    return numLevels(a.name) - numLevels(b.name)
  })
}
