import BigNumber from 'bignumber.js'
import { Asset } from 'types/algosdk'

export type FormatAmountOptions = {
  compact?: boolean
  precision?: number
  maxLength?: number
  decimals?: number
  locale?: string
}

function getLocalizedFormatOptions(locale?: string) {
  const numberFormat = new Intl.NumberFormat(locale)
  const parts = numberFormat.formatToParts(1234567.89)
  let decimalSeparator = '.'
  let groupSeparator = ','
  let groupSize = 3

  parts.forEach((part) => {
    if (part.type === 'decimal') {
      decimalSeparator = part.value
    } else if (part.type === 'group') {
      groupSeparator = part.value
    }
  })

  // Determine group size
  const formattedInteger = numberFormat.format(1000000)
  const lastGroupIndex = formattedInteger.lastIndexOf(groupSeparator)
  if (lastGroupIndex !== -1) {
    groupSize = formattedInteger.length - lastGroupIndex - 1
  }

  return { decimalSeparator, groupSeparator, groupSize }
}

/**
 * Format an amount with options for base unit conversion, precision, compact notation, and max length
 * @param {number | bigint | string} amount - The number to format
 * @param {FormatAmountOptions} options - Options for formatting the number
 * @param {boolean} options.compact - Whether to format the number in compact notation
 * @param {number} options.precision - The number of decimal places
 * @param {number} options.maxLength - The maximum length of the formatted number
 * @param {number} options.decimals - The number of decimal places for base unit conversion
 * @returns {string} The formatted number
 * @example
 * // Note: These examples assume 'en-us' locale. Actual output may vary based on the user's locale.
 * formatAmount(1234567890) // '1,234,567,890'
 * formatAmount(12345.6789, { precision: 2 }) // '12,345.68'
 * formatAmount(1234567, { compact: true, precision: 2 }) // '1.23M'
 * formatAmount('987654321.1234', { precision: 3 }) // '987,654,321.123'
 * formatAmount(100.5, { precision: 3 }) // '100.500'
 * formatAmount(123456789, { decimals: 2 }) // '1,234,567.89'
 */
export function formatAmount(
  amount: number | bigint | string,
  options: FormatAmountOptions = {}
): string {
  // Handle edge cases
  if (Number.isNaN(amount) || amount === Infinity || amount === -Infinity) {
    return amount.toString()
  }

  const { compact = false, precision, maxLength, decimals = 0, locale } = options
  const { decimalSeparator, groupSeparator, groupSize } = getLocalizedFormatOptions(locale)

  const fmt = {
    decimalSeparator,
    groupSeparator,
    groupSize,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0
  }

  // Configure BigNumber
  BigNumber.config({ EXPONENTIAL_AT: 1e9, DECIMAL_PLACES: 20, FORMAT: fmt })

  // Convert to BigNumber and apply decimals
  const bigAmount = new BigNumber(amount.toString()).shiftedBy(-decimals)

  if (compact) {
    return formatWithPrecision(bigAmount.toNumber(), precision)
  }

  // Format with localized options
  const formatted = precision !== undefined ? bigAmount.toFormat(precision) : bigAmount.toFormat()

  // Apply maxLength if needed
  if (maxLength && formatted.length > maxLength) {
    return formatWithPrecision(bigAmount.toNumber(), precision)
  }

  return formatted
}

/**
 * Format a number with precision and suffixes
 * @param {number | bigint |string} amount - The number to format (can be a number or a string)
 * @param {number} precision - The number of decimal places
 * @returns {string} The formatted number with precision and suffixes
 * @example
 * formatWithPrecision(1e12, 3) // '1T'
 * formatWithPrecision(2.345e12, 1) // '2.3T'
 * formatWithPrecision(3.45678e9, 2) // '3.46B'
 * formatWithPrecision(4.56789e6, 3) // '4.568M'
 * formatWithPrecision(1234.567, 2) // '1.23K'
 */
export function formatWithPrecision(amount: number | bigint | string, precision = 1): string {
  // Handle edge cases
  if (Number.isNaN(amount) || amount === Infinity || amount === -Infinity) {
    return amount.toString()
  }

  const bigAmount = new BigNumber(amount.toString())
  const abbreviations = ['', 'K', 'M', 'B', 'T', 'Q', 'Qi', 'Sx', 'Sp', 'Oc', 'No', 'Dc']
  let value = bigAmount.abs().toNumber()
  let index = 0

  while (value >= 1000 && index < abbreviations.length - 1) {
    value /= 1000
    index++
  }

  if (bigAmount.isNegative()) {
    return '-' + new BigNumber(value).toFixed(precision) + abbreviations[index]
  }

  return new BigNumber(value).toFixed(precision) + abbreviations[index]
}

export type FormatAssetAmountOptions = Omit<FormatAmountOptions, 'decimals'> & {
  unitName?: boolean
}

/**
 * Format an asset base unit amount for display in whole units.
 * Expects the asset with AssetParams fetched from `/v2/assets/{asset-id}`.
 * Passes the amount to formatAmount with the appropriate options.
 * @param {Asset} asset - The asset to format the amount for
 * @param {number | bigint | string} amount - The asset amount to format
 * @param {FormatAssetAmountOptions} options - Options for formatting the amount
 * @param {boolean} options.unitName - Whether to append the asset unit name in the formatted amount
 * @returns {string} The formatted asset amount
 * @example
 * const asset: Asset = {
 *   index: 12345,
 *   params: {
 *     decimals: 6,
 *     'unit-name': 'TEST',
 *     // ...
 *   },
 * }
 * formatAssetAmount(asset, 1234567890) // '1,234.56789'
 * formatAssetAmount(asset, 1234567890, { precision: 2 }) // '1,234.57'
 * formatAssetAmount(asset, 1234560000, { precision: 6 }) // '1,234.56'
 * formatAssetAmount(asset, 1234567890, { compact: true, precision: 2 }) // '1.23K'
 * formatAssetAmount(asset, 1234567890n) // '1,234.56789'
 * formatAssetAmount(asset, '1234567890') // '1,234.56789'
 * formatAssetAmount(asset, 1234567890, { unitName: true }) // '1,234.56789 TEST'
 * @see {@link formatAmount}
 */
export function formatAssetAmount(
  asset: Asset,
  amount: number | bigint | string,
  options: FormatAssetAmountOptions = {}
): string {
  const { precision, maxLength, compact, unitName, locale } = options
  const decimals = Number(asset.params.decimals)
  const assetUnitName = unitName ? asset.params['unit-name'] : ''

  const formatOptions = { precision, maxLength, compact, decimals, locale }

  const result = formatAmount(amount, formatOptions)

  if (assetUnitName) {
    return `${result} ${assetUnitName}`
  }

  return result
}

export type FormatMicroAlgoAmountOptions = Omit<FormatAmountOptions, 'decimals'>

/**
 * Format a MicroAlgos amount for display in Algos.
 * Passes the amount to formatAmount with the appropriate options.
 * @param {number | bigint | string} amount - The MicroAlgos amount to format
 * @param {FormatMicroAlgoAmountOptions} options - Options for formatting the amount
 * @returns {string} The formatted Algo amount
 * @example
 * formatMicroAlgoAmount(1234567890) // '1,234.56789'
 * formatMicroAlgoAmount(1234567890, { precision: 2 }) // '1,234.57'
 * formatMicroAlgoAmount(1234567890, { compact: true, precision: 2 }) // '1.23K'
 * formatMicroAlgoAmount(1234567890n) // '1,234.56789'
 * formatMicroAlgoAmount('1234567890') // '1,234.56789'
 * @see {@link formatAmount}
 */
export function formatMicroAlgoAmount(
  amount: number | bigint | string,
  options: FormatMicroAlgoAmountOptions = {}
): string {
  const { precision, maxLength, compact, locale } = options

  const formatOptions = {
    precision,
    maxLength,
    compact,
    decimals: 6,
    locale
  }

  return formatAmount(amount, formatOptions)
}
