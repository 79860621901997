import { useEffect } from 'react'
import { useWallet } from '@txnlab/use-wallet-react'
import { useNocacheStore } from 'store/state/nocache'
import useSearch from 'api/hooks/v2/useSearch'

export default function useNoCacheNames() {
  const { activeAccount } = useWallet()

  const ownerQuery = useSearch({
    params: { owner: activeAccount?.address as string, view: 'tiny' },
    options: {
      enabled: !!activeAccount?.address
    }
  })

  const setNocache = useNocacheStore((state) => state.setNocache)

  useEffect(() => {
    if (ownerQuery.data && ownerQuery.data.length > 0) {
      const names = ownerQuery.data.map((n) => n.name)
      setNocache(names)
    }
  }, [ownerQuery.data, setNocache])
}
