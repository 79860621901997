import { decodeAddress } from 'algosdk'
import { CID } from 'multiformats/cid'
import * as digest from 'multiformats/hashes/digest'
import * as mfsha2 from 'multiformats/hashes/sha2'
import type { Version } from 'multiformats/dist/types/src/cid'

const ARC3_URL_SUFFIX = '#arc3'

export function resolveAssetUrlProtocol(
  url: string | undefined,
  reserveAddr: string | undefined
): string {
  if (!url) return ''

  // Remove ARC3 URL suffix if it exists
  if (url.endsWith(ARC3_URL_SUFFIX)) url = url.slice(0, url.length - ARC3_URL_SUFFIX.length)

  const chunks = url.split('://')

  // Handle 'template-ipfs' protocol
  if (chunks[0] === 'template-ipfs' && chunks[1].startsWith('{ipfscid:')) {
    chunks[0] = 'ipfs'

    // Parse CID components from the URL
    const cidComponents = chunks[1].split(':')
    if (cidComponents.length !== 5) {
      console.log('Unknown ipfscid format')
      return url
    }

    // Extract CID components
    const [, cidVersion, cidCodec, asaField, cidHash] = cidComponents

    // Validate hash, codec, and ASA field
    if (
      cidHash.split('}')[0] !== 'sha2-256' ||
      (cidCodec !== 'raw' && cidCodec !== 'dag-pb') ||
      asaField !== 'reserve'
    ) {
      console.log('Unsupported format:', cidHash, cidCodec, asaField)
      return url
    }

    // Determine the codec code
    const cidCodecCode = cidCodec === 'raw' ? 0x55 : 0x70

    // Verify reserve address
    if (!reserveAddr) {
      console.log('No reserve address')
      return url
    }

    // Compute the CID using the reserve address
    const addr = decodeAddress(reserveAddr)
    const mhdigest = digest.create(mfsha2.sha256.code, addr.publicKey)
    const cid = CID.create(parseInt(cidVersion) as Version, cidCodecCode, mhdigest)

    // Update the URL with the new CID
    chunks[1] = cid.toString() + '/' + chunks[1].split('/').slice(1).join('/')
  }

  // No protocol specified, give up
  if (chunks.length < 2) return url

  // Return the resolved URL based on the protocol
  switch (chunks[0]) {
    case 'ipfs':
      return `ipfs://${chunks[1]}`
    case 'https':
      return url
  }

  return url
}
