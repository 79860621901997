import { UseQueryOptions } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import { useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDebouncedCallback } from 'use-debounce'
import useAddresses from 'api/hooks/useAddresses'
import useNames from 'api/hooks/useNames'
import useSearch from 'api/hooks/v2/useSearch'
import useDebounce from 'hooks/useDebounce'
import { useJoyrideStore, useAdvancedSearchStore } from 'store/index'
import { isValidAlgoAddress, isValidName, trimExtension } from 'helpers/utilities'
import { sortResults } from './AdvancedSearch.utils'
import type { NfdGetNFDParams, NfdRecord } from 'api/api-client'
import type { ICustomError } from 'api/customError'

interface UseAdvancedSearch {
  addressLookup: boolean
}

export default function useAdvancedSearch({ addressLookup }: UseAdvancedSearch) {
  const [open, setOpen] = useState(false)

  useHotkeys('/', () => {
    setTimeout(() => setOpen(true), 0)
  })

  const runTour = useJoyrideStore((state) => state.runTour)
  const setRunTour = useJoyrideStore((state) => state.setRunTour)

  const handleSearchClick = () => {
    setOpen(true)
    runTour && setRunTour(false)
  }

  const handleLeave = () => {
    setTimeout(() => setQuery(''), 300)
  }

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  const enableSearchQuery = useMemo(() => {
    return debouncedQuery === query && isValidName(debouncedQuery.toLowerCase(), true)
  }, [debouncedQuery, query])

  const searchQuery = useSearch({
    params: {
      prefix: trimExtension(debouncedQuery).toLowerCase(),
      view: 'full',
      sort: 'nameAsc'
    },
    options: {
      enabled: enableSearchQuery
    }
  })

  const enableAddressQuery = useMemo(
    () => addressLookup && isValidAlgoAddress(debouncedQuery) && debouncedQuery === query,
    [addressLookup, debouncedQuery, query]
  )

  const addressQuery = useAddresses({
    params: {
      address: [debouncedQuery],
      view: 'full'
    },
    options: {
      enabled: enableAddressQuery
    }
  })

  const isFetching = useMemo(() => {
    if (enableAddressQuery) {
      return addressQuery.isFetching
    }
    return searchQuery.isFetching
  }, [addressQuery.isFetching, enableAddressQuery, searchQuery.isFetching])

  const suggestions = useMemo(() => {
    if (enableAddressQuery) {
      if (addressQuery.data?.[0]) {
        return [debouncedQuery, addressQuery.data[0]]
      }
      return [debouncedQuery]
    }

    return sortResults(searchQuery.data) || []
  }, [addressQuery.data, debouncedQuery, enableAddressQuery, searchQuery.data])

  const [selected, setSelected] = useState<NfdRecord | string | null>(null)
  const debouncedClearSelected = useDebouncedCallback(() => setSelected(null), 500)

  const handleSelection = (selected: NfdRecord | string) => {
    setSelected(selected)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = event.target.value.replace(/[^a-z0-9\.]/gi, '')
    setQuery(filtered)
    debouncedClearSelected()
  }

  const recent = useAdvancedSearchStore((state) => state.recent)
  const recentSearches = useRecentSearches({ recent })

  const { activeAccount } = useWallet()

  const [isSendModalOpen, setIsSendModalOpen] = useState(false)

  const handleSendClick = () => {
    setOpen(false)
    setTimeout(() => setIsSendModalOpen(true), 300)
  }

  return {
    open,
    setOpen,
    handleSearchClick,
    handleLeave,
    query,
    debouncedQuery,
    isFetching,
    suggestions,
    selected,
    setSelected,
    handleSelection,
    handleInputChange,
    recentSearches,
    activeAccount,
    isSendModalOpen,
    setIsSendModalOpen,
    handleSendClick
  }
}

type UseRecentSearchesArgs = {
  recent: string[]
  params?: NfdGetNFDParams
  options?: UseQueryOptions<NfdRecord | void, ICustomError>
}

function useRecentSearches({ recent, params, options }: UseRecentSearchesArgs) {
  // Filter NFDs in the `recent` array
  const names = recent.filter((item) => isValidName(item))

  // Fetch the NFD records
  const nameResults = useNames({
    names,
    params: {
      view: 'full',
      ...params
    },
    options: {
      enabled: names.length > 0,
      staleTime: 2 * 60 * 1000, // 2 min
      ...options
    }
  })

  // Create a map of `names` to their corresponding results
  const nameResultMap = useMemo(
    () => new Map(names.map((name, index) => [name, nameResults[index].data])),
    [names, nameResults]
  )

  // Replace the NFD names in the `recent` array with their corresponding records
  const recentWithRecords = useMemo(
    () =>
      recent.map((item) => {
        if (!isValidName(item)) {
          return item
        }

        const record = nameResultMap.get(item)
        return record || null
      }),
    [nameResultMap, recent]
  )

  return recentWithRecords
}
