import { classNames } from 'helpers/utilities'

export default function LoadingDots({ className = '', span = false }) {
  if (span) {
    return (
      <span className={classNames('inline-flex items-center justify-center space-x-2', className)}>
        <span className="w-3 h-3 bg-gray-300 rounded-full animate-loading-pulse dark:bg-gray-100/30" />
        <span className="w-3 h-3 bg-gray-300 rounded-full animate-loading-pulse [animation-delay:250ms] dark:bg-gray-100/30" />
        <span className="w-3 h-3 bg-gray-300 rounded-full animate-loading-pulse [animation-delay:500ms] dark:bg-gray-100/30" />
      </span>
    )
  }

  return (
    <div className={classNames('flex items-center justify-center space-x-2', className)}>
      <div className="w-3 h-3 bg-gray-300 rounded-full animate-loading-pulse dark:bg-gray-100/30" />
      <div className="w-3 h-3 bg-gray-300 rounded-full animate-loading-pulse [animation-delay:250ms] dark:bg-gray-100/30" />
      <div className="w-3 h-3 bg-gray-300 rounded-full animate-loading-pulse [animation-delay:500ms] dark:bg-gray-100/30" />
    </div>
  )
}
