import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  nfdGetNFDsForAddressesV2,
  NfdGetNFDsForAddressesV2200,
  NfdGetNFDsForAddressesV2Params,
  NfdRecordCollection
} from 'src/api/api-client'
import { captureException } from '@sentry/nextjs'
import { chunkArray } from 'helpers/utilities'

export const getByAddresses = async (
  params: NfdGetNFDsForAddressesV2Params,
  ssr?: boolean,
  options?: AxiosRequestConfig
) => {
  // split up the addresses into chunks of 20 max
  const chunked = chunkArray(params.address)

  // make the requests in parallel
  const responses = await Promise.all(
    chunked.map((chunk) => {
      return nfdGetNFDsForAddressesV2(
        {
          ...params,
          address: chunk,
          ...(!params.limit && params.address.length === 1 ? { limit: 1 } : {})
        },
        {
          ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
          ...options
        }
      )
    })
  )

  // merge the responses
  const mergedResponse = responses.reduce(
    (
      acc: NfdGetNFDsForAddressesV2200,
      response: AxiosResponse<NfdGetNFDsForAddressesV2200, AxiosError>
    ) => {
      return {
        ...acc,
        ...response.data
      }
    },
    {} as NfdGetNFDsForAddressesV2200
  )

  // find the first verified match, or the first unverified match (if no verified match)
  const results = Object.entries(mergedResponse).reduce(
    (acc: NfdRecordCollection, [address, nfds]) => {
      const verifiedMatch = nfds.find((nfd) => nfd.caAlgo?.includes(address))

      if (verifiedMatch) {
        // return first verified match for this address
        return [...acc, verifiedMatch]
      }

      const unverifiedMatch = nfds.find((nfd) => nfd.unverifiedCaAlgo?.includes(address))

      if (unverifiedMatch) {
        // return first unverified match for this address
        return [...acc, unverifiedMatch]
      }

      // no match for this address
      return acc
    },
    [] as NfdRecordCollection
  )

  return results
}

type UseAddressesArgs = {
  params: NfdGetNFDsForAddressesV2Params
  options?: UseQueryOptions<NfdRecordCollection, AxiosError>
}

export default function useAddresses({ params, options }: UseAddressesArgs) {
  const result = useQuery<NfdRecordCollection, AxiosError>(
    ['addresses', { ...params }],
    () =>
      getByAddresses(params, false, {
        validateStatus: (status: number) => (status >= 200 && status < 300) || status === 404
      }),
    {
      ...options
    }
  )

  if (result.error) {
    captureException(result.error)
  }

  return result
}
